import React from 'react';
import { useVariant } from '../../enrollment-process/module-management/ModuleVariants';
import { AllPreferencesEntry } from './variants/AllPreferencesEntry';
import { EmailPreferencesEntry } from './variants/EmailPreferencesEntry';
import { StepLayout } from '../../../external/shared/api/EnrollmentClient.generated';

export const PreferencesEntry = () => {
  const variant = useVariant();
  switch (variant) {
    case StepLayout.Accordion:
      return <AllPreferencesEntry />;
    default:
      return <EmailPreferencesEntry />;
  }
};
