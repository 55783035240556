import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { translateMonth } from '../../../../reference/MonthName';
import { Benefits } from '../../common-er/Benefits';
import { ProductKits } from '../ProductKits';
import { useRootStore } from '../../../root/RootStoreHooks';
import { FirstErAsTemplateQuestion } from '../../../social-link/creator-mode/FirstErAsTemplateQuestion';
import { useModuleStore } from '../../../enrollment-process/module-management/model/ModuleStoreHook';

export const KitSelection = observer(function KitSelection() {
  const { t } = useTranslation();
  const { moduleStores } = useRootStore();
  const store = moduleStores.erKitSelection ?? moduleStores.socialLinkErKitSelection!;
  const usePremiumKitAsErOrder = store.usePremiumKitAsErOrder;

  const ErBeginMonth = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);

    if (usePremiumKitAsErOrder) {
      date.setMonth(date.getMonth() + 1);
    }
    return <span>{translateMonth(date.getMonth())}</span>;
  };

  return (
    <div className="mt-3">
      <h2 className="card-title text-primary">
        {t('starterKitStep3')}: {t('SetUpMonthlyEssentialRewardsOrder')}
      </h2>
      <div className="card-text">
        {moduleStores.socialLinkCreatorEntry ? (
          <p>
            <span>{t('ERMonthlyShipmentsAndBillingThisMonth')}</span>
          </p>
        ) : (
          <p>
            <span>{t('ERMonthlyShipmentsAndBilling')}</span> <ErBeginMonth />
          </p>
        )}
      </div>
      {moduleStores.socialLinkCreatorEntry && <FirstErAsTemplateQuestion />}
      {(!moduleStores.socialLinkCreatorEntry || store.useFirsErOrderAsTemplate === false) && (
        <>
          <Benefits />
          <ProductKits />
        </>
      )}
    </div>
  );
});
