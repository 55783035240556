import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';


export const BusinessTaxRules = observer(function Benefits() {
  const { t, i18n } = useTranslation();
  const getRules = (): string[] => {
    const result: string[] = [];

    let index = 1;
    let hasNextValue = false;
    do {
      const key = `taxBusinessRule${index}`;
      if (i18n.exists(key) && t(key) !== null && t(key) !== '') {

        result.push(key);
        index++;
        hasNextValue = true;
      } else {
        hasNextValue = false;
      }
    } while (hasNextValue);

    return result;
  };

  const rules = getRules();

  return (
    <>
    <span>{t('taxBusinessRulesDescription')}</span>
      <ul className="ml-4">
        {rules.map(rule => (
          <li key={rule}>
            <span>{t(rule)}</span>
          </li>
        ))}
      </ul>
    </>
  );
});
