import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import styles from './QuestionButtons.module.scss';

interface EssentialRewardsButtonProps {
  type?: 'submit' | 'reset' | 'button';
  active?: boolean;
  onClick: () => void;
}

export const EssentialRewardsButton: FC<EssentialRewardsButtonProps> = observer(function EssentialRewardsButton({
  active,
  type,
  children,
  onClick
}) {
  const buttonType = type || 'button';

  return (
    <Button className={styles.erButton} variant="outline-primary" active={!!active} type={buttonType} onClick={onClick}>
      {children}
    </Button>
  );
});

interface ErYesNoQuestionProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
}
export function ErYesNoQuestion({ value, onChange }: ErYesNoQuestionProps) {
  const { t } = useTranslation();

  function triggerOnChange(changed: boolean) {
    if (changed !== value) {
      if (onChange) {
        onChange(changed);
      }
    }
  }

  return (
    <>
      <EssentialRewardsButton onClick={() => triggerOnChange(true)} active={value}>
        {t('ErYes')}
      </EssentialRewardsButton>
      <EssentialRewardsButton onClick={() => triggerOnChange(false)} active={value !== undefined && !value}>
        {t('ErNo')}
      </EssentialRewardsButton>
    </>
  );
}
