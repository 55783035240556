import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { AgreementDocumentsDisplay } from '../agreement/AgreementDocumentsDisplay';
import styles from './TaxForms.module.scss';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

export const TaxFormsAgreementEntry = () => {
  const taxFormsAgreementEntry = useModuleStore('taxFormsAgreementEntry');

  return useObserver(() => {
    return (
      <>
        {taxFormsAgreementEntry.text && (
          <div
            className={styles.infoTaxForms}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: taxFormsAgreementEntry.text }}
          />
        )}

        <div>
          {taxFormsAgreementEntry.documents && (
            <AgreementDocumentsDisplay documents={taxFormsAgreementEntry.documents} />
          )}
        </div>
      </>
    );
  });
};
