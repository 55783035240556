import React from 'react';
import { observer } from 'mobx-react-lite';
import { Router } from '@yl/react-router/dist';
import { StepsManager } from '../enrollment-process/steps-management/components/StepsManager';
import { EnrollmentRoutes } from './routes';

interface EnrollmentRoutePickerProps {
  router: Router<EnrollmentRoutes>;
}

const Root = () => <div>YOU ARE AT THE ROOT</div>;

function isRoute(router: Router<EnrollmentRoutes>, route: keyof EnrollmentRoutes) {
  return router.currentView === route;
}

export const EnrollmentRoutePicker = observer(function EnrollmentRoutePicker({ router }: EnrollmentRoutePickerProps) {
  return (
    <>
      {isRoute(router, 'enrollmentRoot') && <Root />}
      {isRoute(router, 'enrollmentStep') && <StepsManager />}
    </>
  );
});
