import { observer } from 'mobx-react-lite';
import React from 'react';
import { ProductInfoModal } from '../../../../product-info/util/ProductInfoModal';
import { KitGridItemCustom } from '../../../common-er/util-er-kit/kit-grid/KitGridItemCustom';
import { KitGridItemDesktop } from '../../../common-er/util-er-kit/kit-grid/KitGridItemDesktop';
import { KitGridItem } from '../../../common-er/util-er-kit/kit-grid/KitGridItem';
import { KitPriceDisplay } from '../../../common-er/util-er-kit/KitPriceDisplay';
import { KitGridItemCustomPsk } from '../../../common-er/util-er-kit/kit-grid/KitGridItemCustomPsk';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';

export const KitGridDesktop = observer(function KitGrid() {
  const store = useModuleStore('classicErKitSelection');
  return (
    <div className="container mb-4">
      <div className="row">
        {store.canCustomizeErKit && (
          <KitGridItemDesktop key="KitGridItemCustom">
            <KitGridItemCustom
              customKitSelected={store.customKitSelected}
              selectCustomizeKit={store.selectCustomizeKit}
            />
          </KitGridItemDesktop>
        )}
        {store.canCustomizeErWithPsk && (
          <KitGridItemDesktop key="KitGridItemCustomPsk">
            <KitGridItemCustomPsk
              customKitWithPskSelected={store.customKitWithPskSelected}
              selectCustomizeKitWithPsk={store.selectCustomizeKitWithPsk}
            />
          </KitGridItemDesktop>
        )}
        {store.erKits.map(kit => (
          <KitGridItemDesktop key={kit.itemId}>
            <KitGridItem
              key={kit.itemId}
              kit={kit}
              showProductInfo={p => store.showProductInfo(p)}
              choseKit={() => store.choseKit(kit)}
              selectedKit={store.selectedKit}
            />
          </KitGridItemDesktop>
        ))}

        {store.productInfo && (
          <ProductInfoModal
            isOpen={store.showProductModal}
            onClose={() => store.hideProductInfo()}
            onKitSelected={() => store.choseKit(store.productInfo!.kit)}
            productInfo={store.productInfo}
            priceDisplay={<KitPriceDisplay kit={store.productInfo.kit} />}
          />
        )}
      </div>
    </div>
  );
});
