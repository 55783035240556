import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useObserver } from 'mobx-react-lite';
import styles from './AddressValidatorModal.module.scss';
import { AddressValidator } from './AddressValidator';

interface Props {
  store: AddressValidator;
}

export const NoRecommendationModal: FC<Props> = ({ store }) => {
  const { t } = useTranslation();
  return useObserver(() => (
    <Modal className={styles.modal} show={store.showModal} onHide={() => store.hideModal()} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className="text-primary">{t('InvalidAddress')}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('EditAddressMessage')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => store.hideModal()}>
          {t('no')}
        </Button>
        <Button variant="primary" onClick={() => store.hideModal(store.recommendations!.originalAddress)}>
          {t('yes')}
        </Button>
      </Modal.Footer>
    </Modal>
  ));
};
