import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { TaxIdEntryStore } from '../TaxIdEntryStore';
import { CombinedTaxIdEntry } from '../tax-context-types/combined/CombinedTaxIdEntry';
import { useInitOnce } from '../../../../infrastructure/hooks/HookUtils';
import { useModuleDisplayMode } from '../../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';
import { RadioLessTaxIdEntryDisplay } from './RadioLessTaxIdEntryDisplay';

interface Props {
  store: TaxIdEntryStore;
}

export const RadioLessTaxIdEntry: FC<Props> = ({ store }) => {
  useInitOnce(() => store.setDefaultContextIfNecessary());
  const displayMode = useModuleDisplayMode();
  return useObserver(() => {
    if (displayMode) {
      return <RadioLessTaxIdEntryDisplay store={store} />;
    }

    return (
      <>
        <CombinedTaxIdEntry store={store} />
      </>
    );
  });
};
