import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col } from 'react-bootstrap';
import { EnrollmentOrderItem } from './EnrollmentOrderInfoStore';
import { HelpPopOver } from '../../infrastructure/forms/help-tip-icon/HelpTipIcon';
import { ImageWithMissingProductFallback } from '../order-review/order-summary/ImageWithMissingProductFallback';
import { MoneyDisplay } from '../order-selection/starter-kit-selection/util-starter-kit/MoneyDisplay';

interface KitGridItemProps {
  item: EnrollmentOrderItem;
  showDetailLink?: boolean;
  customImage?: string;
}

export const PromoItemCard = observer(function KitGridItem({
  item,
  showDetailLink = false,
  customImage
}: KitGridItemProps) {
  const { t } = useTranslation();
  console.log(item);
  return (
    <>
      <Col key={item.partNumber} md={3} style={{ marginBottom: '20px' }}>
        <Card style={{ minHeight: '340px' }}>
          <div className="text-left bg-primary">
            <p className="text-white header-card-text m-1 h-56">{item.name}</p>
          </div>

          <Card.Body className="text-center">
            <span>
              <Button variant="link" className="p-2">
                <ImageWithMissingProductFallback src={item.imageUrl!} isPromotional={item.isPromotional} />
              </Button>
            </span>
            {showDetailLink && (
              <Button variant="link">
                <u>{t('Details')}</u>
              </Button>
            )}
          </Card.Body>
        </Card>

        <strong>
          <span className="pr-1">Price: </span>
          {item.priceWithTax > 0 && (
            <MoneyDisplay
              price={item.priceWithTax}
              currency={{ code: item.currency.code, symbol: item.currency.symbol }}
            />
          )}
          {item.priceWithTax === 0 && (
            <span>
              {t('Free')} <HelpPopOver label="WhyFree" />
            </span>
          )}
        </strong>
      </Col>
    </>
  );
});
