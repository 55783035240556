import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaQuestionCircle } from 'react-icons/fa';
import styles from './HelpTipIcon.module.scss';

interface HelpPopOver {
  label: string | (() => string);
}
export const HelpPopOver: FC<HelpPopOver> = observer(function HelpPopOver({ label }) {
  const { t } = useTranslation();
  const labelString = typeof label === 'string' ? t(label) : ((label! as () => string)() as any);
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id="pin-help">{labelString}</Tooltip>}>
      <FaQuestionCircle className={styles.icon} />
    </OverlayTrigger>
  );
});

interface PopOverLinkProps {
  helpLabel: string;
  linkLabel: string;
  className: string;
  href: string;
  rel?: string;
  target?: string;
}
export const PopOverLink: FC<PopOverLinkProps> = ({ helpLabel, linkLabel, target, className, href, rel }) => {
  const { t } = useTranslation();
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id="pin-help">{t(helpLabel)}</Tooltip>}>
      <a {...{ href, target, rel, className }}>{t(linkLabel)}</a>
    </OverlayTrigger>
  );
};

interface PopOverListProps {
  children: ReactNode;
}
export const PopOverList: FC<PopOverListProps> = ({ children }) => {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id="pin-help">{children}</Tooltip>}>
      <FaQuestionCircle className={styles.icon} />
    </OverlayTrigger>
  );
};
