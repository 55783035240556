import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { CoApplicantEntryFormData } from './CoApplicantEntryStore';

interface Props {
  data: CoApplicantEntryFormData;
}

export const CoApplicantDisplay: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  return useObserver(() => (
    <div>
      <span>{t('AnotherPersonOnYourAccount')}:</span>
      <span className="text-primary">
        {data.firstName} {data.lastName}
      </span>
    </div>
  ));
};
