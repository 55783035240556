/* eslint-disable react/destructuring-assignment */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { YlInput } from '../../../../../infrastructure/forms/YlInput';
import { TaxIdContext } from '../TaxIdContext';

interface Props {
  context: TaxIdContext;
}

export const IndividualStandardTaxEntry: FC<Props> = ({ context }) => {
  const [t] = useTranslation();
  return useObserver(() => {
    const individual = context.entries[0].individual!;
    const form = individual.reactForm;
    return (
      <>
        <p>{t('taxHelpIndividualDescription')}</p>
        {individual.canCollect && (
          <YlInput
            field={form.$.taxId}
            required={individual.isRequired}
            label="taxPersonalTaxId"
            helpLabel="taxIdHelpText"
            onChange={() => individual.fixDashes()}
            id="individual"
          />
        )}
      </>
    );
  });
};
