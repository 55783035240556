import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { OrderSummaryItemList } from './OrderSummaryItemList';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useRootStore } from '../../root/RootStoreHooks';
 
export const OrderSummary = () => {
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const orderSummaryStore = useModuleStore('orderSummary');

  return useObserver(() => (
    <div>
    <>
      <h2 className="text-primary">{t('OrderSummary')}</h2>
      <OrderSummaryItemList label="EnrollmentKit" items={orderSummaryStore.enrollmentItems} />
      {!orderSummaryStore.usePremiumStarterKitAsErOrder && (
        <OrderSummaryItemList label="EssentialRewardsKit" items={orderSummaryStore.essentialRewardItems} />
      )}
      <div className="row col-md-12">
      {!orderSummaryStore.hideEditStepButton && (
        <Button className="col-sm-6" style={{textAlign: 'left'}} onClick={() => rootStore.stepsManager.goToStep(orderSummaryStore.editStepNumber)} variant="link">
          {t('EditKitSelection')}
        </Button>
      )}
      {rootStore.enrollmentStatus.countryIso == 'HK' && (
      <p className="col-sm-6" style={{textAlign: 'right'}}> {t('deliveryWeight')}{orderSummaryStore.orderWeight.toFixed(2)}{t('weightUnit')} </p>
      )}
      </div>
    </>
    <>
    
      </>
    </div>
  ));
};
