import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldState, FormState } from 'formstate';
import { YlDropdown } from '../../infrastructure/forms/YlDropdown';
import { Country } from '../Country';
import { useRootStore } from '../../app/root/RootStoreHooks';

export interface Props {
  size: number;
  required: boolean;
  disabled: boolean;
  field: FieldState<any>;
  form: FormState<any>;
  label: string;
  useIso3?: boolean;
  onChange?: (value: any) => void;
}

export const Countries = observer<Props>(function Countries({
  size,
  required,
  disabled,
  field,
  label,
  useIso3,
  onChange
}) {
  const rootStore = useRootStore();
  const { reference } = rootStore;
  const { locale } = rootStore.localeManager.language;

  useEffect(() => {
    reference.getCountries(locale);
  }, [reference, locale]);

  if (reference.countries?.length === 0) {
    return <></>;
  }
  return (
    <YlDropdown
      required={required}
      id="country"
      size={size}
      disabled={disabled}
      loading={!reference.countries}
      field={field}
      label={label}
      onChange={onChange}
      options={reference.countries ?? []}
      valueFn={(country: Country) => (useIso3 ? country.isoCode3 : country.isoCode2)}
      labelFn={(country: Country) => country.name}
      placeholder="ChooseCountry"
    />
  );
});
