import React from 'react';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { FaEdit } from 'react-icons/fa';
import { ComponentField } from '../../../infrastructure/dynamic-forms/ComponentField';
import { AddressRow } from './module-form/AddressRow';
import { YlInput } from '../../../infrastructure/forms/YlInput';
import { Countries } from '../../../reference/components/Countries';
import { States } from '../../../reference/components/States';
import { Suburbs } from '../../../reference/components/Suburbs';
import { CustomSuburb } from '../../../reference/components/CustomSuburb';
import { AddressModal } from '../validator/AddressModal';
import { MarketChangeModal } from '../../locale-management/warning-modal/MarketChangeModal';
import { AddressDisplay } from '../AddressDisplay';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useRootStore } from '../../root/RootStoreHooks';
import { useModuleDisplayMode } from '../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';
import { Cities } from '../../../reference/components/Cities';
import { PostalCodes } from '../../../reference/components/PostalCodes';

export const AddressEntry = () => {
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const addressEntry = useModuleStore('addressEntry');
  const displayMode = useModuleDisplayMode();
  function getCustomerAttributes(field: ComponentField) {
    const attr = {} as any;
    if (field.maxLength) {
      attr.maxLength = field.maxLength;
    }

    if (field.minLength) {
      attr.minLength = field.minLength;
    }

    return attr;
  }

  return useObserver(() => {
    const form = addressEntry.reactForm;
    const { countryIso } = rootStore.enrollmentStatus;
    if (displayMode) {
      return <AddressDisplay address={addressEntry.data} oneLiner />;
    }

    return (
      <Form>
        {addressEntry.shouldConfirmAddress && (
          <>
            {/* eslint-disable-next-line */}
            <label htmlFor="sameAsPrimary" onClick={() => addressEntry.setInEditMode()}>
              <span className="text-primary">
                {' '}
                <FaEdit id="sameAsPrimary" data-testid="edit-address" />
              </span>{' '}
              {t('MainAddressSameAsShipping')}
            </label>
            {!addressEntry.inEditMode && <AddressDisplay address={addressEntry.data!} hideTitle />}
          </>
        )}

        {addressEntry.inEditMode &&
          addressEntry.form.rows.map((row: AddressRow, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Form.Row key={index}>
              {row.formFields.map((field: ComponentField) => (
                <React.Fragment key={field.name}>
                    {field.name === 'postalCode' && !field.disabled && (
                      <>
                        <PostalCodes
                          required={field.required}
                          size={field.size}
                          field={form.$[field.name]}
                          customAttributes={getCustomerAttributes(field)}
                          label={field.labelKey}
                          additionalError={addressEntry.invalidPostalCodeMessage}
                          id={field.name}
                          disabled={field.disabled}
                          fieldType={field.fieldType}
                          countryIso={countryIso}
                          stateProv={addressEntry.targetStateProv}
                          city={addressEntry.targetCity}
                          onChange={() => {
                              addressEntry.applyFormat(field);
                              addressEntry.tryAutoFillMxData(field);
                              addressEntry.tryAutoFillJpData(field);
                          }}
                        />
                        {addressEntry.jpZipNotFound && <p>{t('ZipCodeNotFoundNotification')}</p>}
                      </>
                  )}

                  {field.fieldType === 'Input' &&
                    field.name !== 'postalCode' &&
                    field.name !== 'city' &&
                    field.name !=='phoneNumber' &&
                    (field.name !== 'street2' || countryIso !== 'CA') && (
                      <YlInput
                        required={field.required}
                        size={field.size}
                        field={form.$[field.name]}
                        customAttributes={getCustomerAttributes(field)}
                        label={field.labelKey}
                        id={field.name}
                        disabled={field.disabled}
                        onChange={() => addressEntry.applyFormat(field)}
                      />
                    )}
                  {field.name === 'city' && (
                    <Cities
                      id={field.name}
                      fieldType={field.fieldType}
                      countryIso={countryIso}
                      stateProv={addressEntry.targetStateProv}
                      required={field.required}
                      name={form.$.city.value}
                      field={form.$[field.name]}
                      size={field.size}
                      label={field.labelKey}
                      disabled={field.disabled}
                      onChange={city => addressEntry.setCity(city)}
                    />
                  )}
                  
                  {field.fieldType === 'Select' && field.name === 'country' && (
                    <Countries
                      size={field.size}
                      required={field.required}
                      field={form.$[field.name]}
                      form={form}
                      disabled={field.disabled}
                      label={field.labelKey}
                      onChange={country => addressEntry.warnAboutMarketChange(country)}
                    />
                  )}

                  {field.fieldType === 'Select' && field.name === 'stateProv' && (
                    <States
                      required={field.required}
                      countryIso={form.$.country.value}
                      field={form.$[field.name]}
                      size={field.size}
                      label={field.labelKey}
                      disabled={field.disabled}
                      onChange={stateProv => addressEntry.setStateProv(stateProv)}
                    />
                  )}

                  {field.fieldType === 'Select' && field.name === 'suburb' && (
                    <Suburbs
                      required={field.required}
                      stateAbbreviation={form.$.stateProv.value}
                      noSuburb={form.$.noSuburb.value}
                      field={form.$[field.name]}
                      size={field.size}
                      label={field.labelKey}
                      onChange={addressEntry.UpdateSuburb}
                    />
                  )}

                  {field.fieldType === 'Check' && field.name === 'noSuburb' && (
                    <CustomSuburb
                      field={form.$.noSuburb}
                      label={field.labelKey}
                      id={field.name}
                      onChange={addressEntry.UpdateNoSuburb}
                    />
                  )}
                </React.Fragment>
              ))}
            </Form.Row>
          ))}
        <MarketChangeModal
          showWarning={addressEntry.showMarketChangeWarning}
          hide={addressEntry.cancelMarketChange}
          agreeAndContinue={rootStore.cancelEnrollment}
          country={addressEntry.targetCountry}
          language={undefined}
        />
        <AddressModal store={addressEntry.addressValidator} />
      </Form>
    );
  });
};
