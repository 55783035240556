import React, { FC, Fragment } from 'react';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { TaxIdEntryStore } from '../TaxIdEntryStore';
import { TaxContext } from './TaxContext';
import { TaxIdContext } from './TaxIdContext';

interface Props {
  store: TaxIdEntryStore;
}

export const TaxIdContextSelector: FC<Props> = ({ store }) => {
  const { t } = useTranslation();

  function getOptionLabel(context: TaxIdContext) {
    switch (context.renderer) {
      case 'IndividualOptOut':
        return `${t('IndividualOut')} ${t('taxHelpIndividualNoTaxIdEntered')}`;
      case 'IndividualMultiple':
        return `${t('Individual')} ${t('taxHelpIndividualMultiple')}`;
      case 'IndividualStandard':
        return `${t('Individual')} ${t('taxHelpIndividual')}`;
      case 'IndividualMexico':
          return `${t('Individual')}`;
      case 'BusinessStandard':
      case 'BusinessEU':
        return `${t('Business')} ${t('taxHelpBusiness')}`;
      case 'BusinessMexico':
          return `${t('Business')}`;
      case 'BusinessMultiple':
        return t('Business');
      default:
        return '';
    }
  }

  return useObserver(() => (
    <>
      {store.taxIdContexts.map(context => (
        <Fragment key={context.id}>
          <Form.Check
            custom
            id={context.id}
            label={getOptionLabel(context)}
            type="radio"
            onChange={() => store.selectTaxContext(context)}
            checked={store.selectedContext === context}
          />
          {store.selectedContext === context && <TaxContext context={context} />}
        </Fragment>
      ))}
    </>
  ));
};
