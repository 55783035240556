import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const GlobalNfrBanner: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="text-white p-4" style={{ backgroundColor: '#ff9500', letterSpacing: '1px' }}>
      <h6>
        <strong className="text-uppercase">{t('nfrBarTitle')}</strong>
      </h6>
      <p>{t('nfrBarMessage')}</p>
    </div>
  );
};
