import { useEffect, FC } from 'react';

const initialize = async () => {
  // eslint-disable-next-line import/no-unresolved
  const { initializeCookieBanner } = await import('yl-onetrust-banner/CookieBanner');
  initializeCookieBanner();
};

export const OnetrustCookieBanner: FC = () => {
  useEffect(() => {
    if (!window.document?.querySelector('#onetrust-consent-sdk')) {
      initialize();
    }
  }, []);
  return null;
};
