import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import { ProductKit } from '../../../starter-kit-selection/model/ProductKit';

interface CardBodyProps {
  kit: ProductKit;
  showProductInfo: (kit: ProductKit) => void;
}

export const CardBody: FC<CardBodyProps> = observer(function CardBody({ kit, showProductInfo }: CardBodyProps) {
  const { t } = useTranslation();
  return (
    <div className="card-body text-center">
      <span>
        <Button variant="link">
          <Image fluid src={kit.smallImageUrl} />
        </Button>
      </span>

      <Button variant="link" onClick={() => showProductInfo(kit)} className="card-link starter-kit- product-link">
        {t('Details')}
      </Button>
    </div>
  );
});
