import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { FieldState } from 'formstate';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { ClassicKitSelection } from './util-er-kit/ClassicKitSelection';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { ErPurchasingReminder } from '../er-kit-selection/ErPurchasingReminder';
import { YlCheckBox } from '../../../infrastructure/forms/YlCheckBox';

interface Props {
  field: FieldState<any>;
  hasChosenKit: boolean;
}

export const ErConfirmation: FC<Props> = ({ field, hasChosenKit }) => {
  const { t } = useTranslation();
  return (
    <Card className="card-header" body>
      <h3>{t('ConfirmAutoship')}</h3>
      <p>{t('PleaseConfirmYouAreAddingEssentialRewards')}</p>

      <Form noValidate>
        <Form.Row>
          <YlCheckBox
            field={field}
            label="YesAddEssentialRewardsLabel"
            id="essentialRewardsConfirmId"
            disabled={!hasChosenKit}
          />
        </Form.Row>
      </Form>
    </Card>
  );
};
export const ClassicErKitSelection = observer(function ClassicErKit() {
  const classicErKitStore = useModuleStore('classicErKitSelection');
  const field = classicErKitStore.reactForm.$.essentialRewardsConfirm;
  return (
    <>
      <ClassicKitSelection />
      <ErConfirmation field={field} hasChosenKit={classicErKitStore.hasChosenKit} />
      {classicErKitStore.erUpsellEnabled && <ErPurchasingReminder store={classicErKitStore.erUpsell!} />}
    </>
  );
});
