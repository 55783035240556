import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormState } from 'formstate';
import { ComponentField } from '../../../infrastructure/dynamic-forms/ComponentField';
import { YlInput } from '../../../infrastructure/forms/YlInput';

export interface ComponentFieldProps {
  field: ComponentField;
  form: FormState<any>;
  size?: number;
  customAttributes? : { [key: string]: any };
  onChange?: (value: string | number) => void;
}

export const Field = observer(function Field({ field, form, size, customAttributes, onChange }: ComponentFieldProps) {
  switch (field.fieldType) {
    case 'Input':
      return (
        <YlInput
          id={field.id}
          required={field.required}
          field={form.$[field.name]}
          label={field.labelKey}
          size={size}
          customAttributes={customAttributes}
          onChange={onChange}
        />
      );
    default:
      return <div>Not supported </div>;
  }
});
