import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StarterKitCardHeader } from './StarterKitCardHeader';
import { ProductKit } from '../../model/ProductKit';
import { KitPriceDisplay } from '../../util-starter-kit/KitPriceDisplay';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';
import { StarterKitPriceCardHeader } from './StarterKitPriceCardHeader';

interface KitGridItemProps {
  kit: ProductKit;
  showDetailLink?: boolean;
  customHeader?: ReactNode;
  customImage?: string;
}

export const KitCard = observer(function KitGridItem({
  kit,
  showDetailLink = true,
  customHeader,
  customImage
}: KitGridItemProps) {
  const { t } = useTranslation();
  const store = useModuleStore('enrollmentKitSelection');
  const header = customHeader || <StarterKitPriceCardHeader kit={kit} store={store}/>;
  const image = customImage || kit.smallImageUrl;
  return (
    <>
      <Card>
        {header}
        <Card.Body className="text-center">
          <span>
              <Image src={image} fluid />
          </span>
          {showDetailLink && (
            <Button variant="link" onClick={() => store.showProductInfo(kit)}>
              <u>{t('Details')}</u>
            </Button>
          )}
        </Card.Body>
      </Card>
    </>
  );
});

export interface KitGridProps {
  kits: ProductKit[];
}

export const KitGrid = observer(function KitGrid({ kits }: KitGridProps) {
  return (
    <Row className='align-items-end'>
      {kits.map(kit => (
        <Col key={kit.itemId} md={3}>
          <p className="pb-2 m-0 text-center">
            {kit.title}
          </p>
          <KitCard kit={kit}/>
        </Col>
      ))}
    </Row>
  );
});
