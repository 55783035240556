import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '../../root/RootStoreHooks';

export const OrderReviewErrorModal = observer(() => {
  const store = useRootStore().moduleStores.orderReturnPolicyReview!;
  const { t } = useTranslation();

  return (
    <Modal show={store.isErrorModalOpen} onHide={() => store.showErrorModal(false)} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t('Error')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('orderReviewNotCheckedError')}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => store.showErrorModal(false)}>{t('OK')}</Button>
      </Modal.Footer>
    </Modal>
  );
});
