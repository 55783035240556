import { getDecimalPrice } from '../../../../reference/Currency';
import { ShippingMethod } from '../ShippingMethodSelectionStore';

function getEstimateLabel(estimate: string) {
  if (estimate === '1') {
    return 'ShipMethDeliveryEstimateDay';
  }
  return 'ShipMethDeliveryEstimateDays';
}

export function getShippingMethodLabel(shipping: ShippingMethod, t: any) {
  function getEstimate(estimate: string) {
    if (!estimate || estimate === 'None') {
      return '';
    }
    const label = getEstimateLabel(estimate);
    return `(${estimate} ${t(label)})`;
  }

  return `${shipping.name} ${shipping.currency.symbol}${getDecimalPrice(shipping.rate, shipping.currency.code)} ${getEstimate(
    shipping.estimatedDelivery
  )}`;
}
