import React from 'react';
import { observer } from 'mobx-react-lite';
import Button from 'react-bootstrap/Button';
import { StarterKitTab } from '../../model/StarterKitTab';
import { StarterKitPopularCardMobileHeader } from '../card/StarterKitPopularCardMobileHeader';
import { checkedBorderColor } from '../../tab-selection/util-tab/CheckBorderColor';

import '../tabbed-layout/PopularKitTab.scss';
import { MoneyDisplay } from '../../util-starter-kit/MoneyDisplay';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';

interface PopularKitTabProps {
  tab: StarterKitTab;
}

export const PopularKitStandardTab = observer(({ tab }: PopularKitTabProps) => {
  const store = useModuleStore('enrollmentKitSelection');

  return (
    <div className="mt-4">
      <div className="container">
        <div className="row mb-4">
          {tab.productKits.map(product => (
            <div key={product.itemId} className="col-sm mt-2">
              <div onClick={() => store.choseKit(product)} className={checkedBorderColor(store.selectedKit, product)}>
                <div className="card-body text-center">
                  <span>
                    <Button variant="link">
                      <img alt="kit" className="url-kit-premium-image" src={product.smallImageUrl} />
                    </Button>
                  </span>
                </div>
                <StarterKitPopularCardMobileHeader kit={product} />
              </div>
              <div className="text-center">
                <MoneyDisplay price={product.price} currency={product.currency} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
