import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { StarterKitTab } from '../../model/StarterKitTab';
import { ProductKit } from '../../model/ProductKit';
import { KitPriceDisplay } from '../../util-starter-kit/KitPriceDisplay';
import { checkedBorderColor } from '../../tab-selection/util-tab/CheckBorderColor';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';

interface KitGridItemProps {
  kit: ProductKit;
}

export const KitGridItem: FC<KitGridItemProps> = ({ kit }) => {
  const { t } = useTranslation();
  const store = useModuleStore('enrollmentKitSelection');

  // <div className={`"card text-center" ${store.selectedKit === kit ? "card text-center border-success" : "card text-center border-primary"}`}>
  return useObserver(() => (
    <div className="container">
      <div className="row">
        <div
          className="col-sm mt-2 mb-2"
          onClick={() => {
            store.choseKit(kit);
          }}
        >
          <div className={checkedBorderColor(store.selectedKit, kit)}>
            <div className="bg-primary d-flex flex-row">
              <div className="container-checkbox kit-grid-item mt-2 ml-2">
                <input type="checkbox" className="mt-2 mr-2" checked={store.selectedKit === kit} />
                <span className="checkmark" />
              </div>
              <p className="text-white header-card-text m-1">
                <KitPriceDisplay kit={kit} />
              </p>
            </div>
            <div className="card-body">
              <span>
                <Button variant="link">
                  <img alt="kit" className="url-kit-premium-image" src={kit.smallImageUrl} />
                </Button>
              </span>
              <Button variant="link" className="card-link starter-kit- product-link">
                <u>{t('Details')}</u>
              </Button>
            </div>
          </div>
        </div>
        <div className="col-sm ml-1">
          <h2 className="text-primary">{kit.title}</h2>
          {/* eslint-disable react/no-danger */}
          <p className="card-text" dangerouslySetInnerHTML={{ __html: kit.description ? kit.description : '' }} />
          <div className="mt-5 ml-4">
            <h4>{t('BasicKitIncludes')}</h4>
            <div className="ml-2 container">
              <div className="row">
                <ul className="ml-3 d-flex flex-wrap">
                  {kit.listItems.map(item => (
                    <li className="col pl-2" key={item}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
};

interface KitGridProps {
  kits: ProductKit[];
}

export const KitGrid: FC<KitGridProps> = ({ kits }) => {
  return useObserver(() => (
    <>
      {kits.map(kit => (
        <KitGridItem key={kit.itemId} kit={kit} />
      ))}
    </>
  ));
};

interface BasicKitTabProps {
  tab: StarterKitTab;
}

export const BasicKitStandardTab: FC<BasicKitTabProps> = ({ tab }) => {
  return useObserver(() => (
    <div className="mt-4  mb-4">
      <div className="container">
        <div className="row">
          <KitGrid kits={tab.productKits} />
        </div>
      </div>
    </div>
  ));
};
