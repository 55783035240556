import React, { useEffect } from 'react';
import { FieldState } from 'formstate';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { YlDropdown } from '../../../../infrastructure/forms/YlDropdown';
import { PaymentInstallment } from './PaymentInstallment';
import { useRootStore } from '../../../root/RootStoreHooks';
import { getDecimalPrice } from '../../../../reference/Currency';

interface Props {
  keyAsLabel?: boolean;
}

export const PaymentInstallmentDropdown = observer<Props>(({ keyAsLabel }) => {
  const { t } = useTranslation();
  const {
    moduleStores,
    reference: { currencies }
  } = useRootStore();
  const { billingEntry } = moduleStores!;

  const { installmentEntry, selectedPaymentType } = billingEntry!;
  const { availableInstallments, isEnabled, selectedInstallment } = installmentEntry;

  useEffect(() => {
    if (!availableInstallments && isEnabled) {
      installmentEntry.getAvailableInstallments();
    }
  }, [installmentEntry, availableInstallments, isEnabled, selectedPaymentType]);

  useEffect(() => {
    if (!selectedInstallment && availableInstallments) {
      const fullPaymentInstallment = availableInstallments.find(x => x.installmentNumber === 1);
      if (fullPaymentInstallment) {
        installmentEntry.changeSelectedInstallment(fullPaymentInstallment);
      }
    }
  }, [installmentEntry, availableInstallments, selectedInstallment]);

  return (
    <YlDropdown
      size={12}
      valueFn={(installment: PaymentInstallment) => installment}
      labelFn={(installment: PaymentInstallment) => {
        if (keyAsLabel) {
          return installment.installmentKey;
        }

        if (installment.installmentNumber === 1) {
          return t('InstallmentFullPayment');
        }
        return (
          t('InstallmentIndependent') +
          ` ${installment.installmentNumber}x` +
          ` - ${currencies[0].symbol}${getDecimalPrice(installment.installmentAmount)} ${currencies[0].code}` +
          ` (${currencies[0].symbol}${getDecimalPrice(installment.totalAmount)} ${currencies[0].code} ${t(
            'InstallmentInTotal'
          )})`
        );
      }}
      placeholder=""
      loading={installmentEntry.isInstallmentsLoading}
      options={availableInstallments ?? []}
      field={new FieldState(selectedInstallment)}
      required
      id="installment"
      label={t('PaymentInstallmentLabel')}
      onChange={(installment: any) => installmentEntry.changeSelectedInstallment(installment)}
    />
  );
});
