import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { BusinessMultipleTaxEntry } from './multiple-entries/BusinessMultipleTaxEntry';
import { IndividualMultipleTaxEntry } from './multiple-entries/IndividualMultipleTaxEntry';
import { IndividualOptOut } from './individual/IndividualOptOut';
import { BusinessStandardTaxEntry } from './business/BusinessStandardTaxEntry';
import { IndividualStandardTaxEntry } from './individual/IndividualStandardTaxEntry';
import { TaxIdContext } from './TaxIdContext';
import { IndividualMexicoTaxEntry } from './mexico/IndividualMexicoTaxEntry';
import { BusinessMexicoTaxEntry } from './mexico/BusinessMexicoTaxEntry';
import { BusinessEUTaxEntry } from './eu/BusinessEUTaxEntry';

interface Props {
  context: TaxIdContext;
}

export const TaxContext: FC<Props> = ({ context }) => {
  function getRenderer(theContext: TaxIdContext) {
    switch (theContext.renderer) {
      case 'IndividualOptOut':
        return <IndividualOptOut />;
      case 'IndividualStandard':
        return <IndividualStandardTaxEntry context={context} />;
      case 'IndividualMexico':
        return <IndividualMexicoTaxEntry context={context} />;
      case 'IndividualMultiple':
        return <IndividualMultipleTaxEntry context={context} />;
      case 'BusinessMexico':
        return <BusinessMexicoTaxEntry context={context} />;
      case 'BusinessEU':
        return <BusinessEUTaxEntry context={context} />;
      case 'BusinessStandard':
        return <BusinessStandardTaxEntry context={context} />;
      case 'BusinessMultiple':
        return <BusinessMultipleTaxEntry context={context} />;
      case 'Unknown':
        return <></>;
      default:
        return <></>;
    }
  }
  return useObserver(() => <div className="ml-4 mt-3">{getRenderer(context)}</div>);
};
