import 'detect-autofill';
import React, { FC } from 'react';
import { FieldState } from 'formstate';
import { observer } from 'mobx-react-lite';
import Select, { components, createFilter, InputProps, StylesConfig, MenuPlacement } from 'react-select-shadow-dom';

import { shouldSwitchToNativeSelect } from './SelectSwitchDetermination';
import { YlFormGroup } from './YlFormGroup';
import { YlSelect } from './YlSelect';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';

type FilterConfig = typeof createFilter extends (config: infer T | undefined) => any ? T : unknown;

export interface YlDropdownProps {
  field: FieldState<any>;
  options: unknown[];
  valueFn: (item: any) => any;
  labelFn: (item: any) => string;
  placeholder?: string;
  placeholderOption?: boolean;
  type?: string;
  label: string | (() => string);
  labelSize?: 'small' | 'normal';
  id?: string;
  required?: boolean;
  customAttributes?: { [key: string]: any };
  size?: number;
  disabled?: boolean;
  loading?: boolean;
  onChange?: (value: string | number) => void;
  additionalError?: string | null;
  onFocus?: () => void;
  filterConfig?: FilterConfig;
  menuPlacement?: MenuPlacement;
}

const customSelectStyles: StylesConfig = {
  menu: provided => ({
    ...provided,
    zIndex: 2
  })
};

const LoadingIndicator: FC = () => {
  return <Spinner animation="border" variant="secondary" size="sm" className="m-2" />;
};

const Input = (props: InputProps<any, true>) => {
  return (
    <components.Input
      {...props}
      {...(props.selectProps as any).customAttributes}
      onInputCapture={e => {
        props.onInputCapture?.(e);
        if (e.currentTarget.hasAttribute('autocompleted')) {
          // eslint-disable-next-line eqeqeq
          const option = props.options.find(x => props.selectProps.getOptionValue(x) == e.currentTarget.value);
          if (option) {
            props.selectOption(option);
          } else if (props.selectProps.isClearable) {
            props.clearValue();
          }
        }
      }}
    />
  );
};

export const YlDropdown = observer<YlDropdownProps>(function YlDropdown(props) {
  const {
    field,
    label,
    labelSize,
    options,
    valueFn,
    labelFn,
    id,
    required,
    size,
    disabled,
    loading,
    onChange,
    additionalError,
    placeholder = '',
    placeholderOption = !required,
    onFocus,
    filterConfig = { matchFrom: 'start' } as FilterConfig,
    customAttributes,
    menuPlacement
  } = props;

  const { t } = useTranslation();

  function triggerChange(value: any) {
    field.onChange(value);
    if (onChange) {
      onChange(value);
    }
  }

  function triggerFocus() {
    if (onFocus) {
      onFocus();
    }
  }

  const dropdownOptions = options.map(item => ({
    value: valueFn(item),
    label: labelFn(item)
  }));

  const shouldSwitchToNative = shouldSwitchToNativeSelect();

  if (placeholderOption || shouldSwitchToNative) {
    dropdownOptions.unshift({
      value: '',
      label: t(placeholder)
    });
  }

  if (shouldSwitchToNative) {
    return (
      <YlSelect
        field={field}
        label={label}
        labelSize={labelSize}
        id={id}
        required={required}
        size={size}
        customAttributes={customAttributes}
        disabled={disabled}
        loading={loading}
        onChange={onChange}
        additionalError={additionalError}
        onFocus={onFocus}
      >
        {dropdownOptions.map(({ value, label }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <option key={index} value={value} hidden={value === '' && !placeholderOption}>
            {label}
          </option>
        ))}
      </YlSelect>
    );
  }

  const selectedOption = (field.value && dropdownOptions.find(option => option.value === field.$)) || null;
  return (
    <YlFormGroup {...{ field, label, labelSize, id, required, size, additionalError }}>
      <Select
        isLoading={loading}
        components={{ LoadingIndicator, Input }}
        styles={customSelectStyles}
        options={dropdownOptions}
        inputId={id}
        onFocus={triggerFocus}
        isDisabled={disabled}
        placeholder={t(placeholder)}
        onChange={(option: any) => triggerChange((option && option.value) || undefined)}
        value={selectedOption}
        filterOption={createFilter(filterConfig)}
        onBlur={() => field.enableAutoValidationAndValidate()}
        className="btn-block text-left"
        menuPlacement={menuPlacement ?? 'auto'}
        {...{ customAttributes }}
      />
    </YlFormGroup>
  );
});
