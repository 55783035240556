import React from 'react';
import { observer } from 'mobx-react-lite';
import { FieldState } from 'formstate';
import { YlDropdown } from '../../infrastructure/forms/YlDropdown';
import { State } from '../State';
import { useRootStore } from '../../app/root/RootStoreHooks';

export interface StatesProps {
  size: number;
  required: boolean;
  field: FieldState<any>;
  countryIso: string;
  label: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

export const States = observer(function States({
  size,
  required,
  countryIso,
  field,
  label,
  disabled,
  onChange
}: StatesProps) {
  const { reference } = useRootStore();

  const states = reference.getStatesForCountry(countryIso);
  if (states?.length === 0) {
    return <></>;
  }
  return (
    <YlDropdown
      options={states ?? []}
      valueFn={(state: State) => state.abbreviation}
      labelFn={(state: State) => state.name}
      placeholder="stateDropDownPlaceholder"
      required={required}
      id="state"
      size={size}
      field={field}
      label={label}
      disabled={disabled}
      loading={!states}
      onChange={onChange}
    />
  );
});
