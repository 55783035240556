import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Navbar } from './NavBar/Navbar';
import { Footer } from './Footer/Footer';
import { EnrollmentRootStore } from '../root/EnrollmentRootStore';
import { LabelEditor } from '../../infrastructure/localization/LabelEditor';
import { ShowScreenSpinner } from '../../external/shared/spinner/ScreenSpinner';
import { KustomerChat } from '../external/KustomerChat';
import { Five9Chat } from '../external/Five9Chat';
import { OnetrustCookieBanner } from '../external/OnetrustCookieBanner';
import { PickupPointModal } from '../external/PickupPointModal';

interface Props {
  store: EnrollmentRootStore;
}

export const EnrollmentLayout = observer<Props>(({ store, children }) => {
  useEffect(() => {
    function onresize() {
      store.updateWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', onresize);
    return () => {
      window.removeEventListener('resize', onresize);
    };
  }, [store]);
  const { locale } = store.localeManager.language;
  return (
    <>
      <Navbar />
      <div>{children}</div>
      <Footer locale={locale} />
      {store.moduleStores?.shippingAddressEntry && (
        <PickupPointModal store={store.moduleStores.shippingAddressEntry.pickupPointModal} />
      )}
      {store.showSpinner && <ShowScreenSpinner />}
      <KustomerChat locale={locale} />
      <Five9Chat locale={locale} />
      <OnetrustCookieBanner />
      {process.env.NODE_ENV !== 'production' && <LabelEditor />}
    </>
  );
});
