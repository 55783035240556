import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldState } from 'formstate';
import { YlDropdown } from '../../infrastructure/forms/YlDropdown';
import { Suburb } from '../Suburb';
import { useRootStore } from '../../app/root/RootStoreHooks';

export interface SuburbProps {
  size: number;
  required: boolean;
  field: FieldState<any>;
  stateAbbreviation: string;
  noSuburb: boolean;
  label: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

export const Suburbs = observer(function Suburbs({
  size,
  required,
  stateAbbreviation,
  noSuburb,
  field,
  label,
  disabled,
  onChange
}: SuburbProps) {
  const { reference } = useRootStore();

  useEffect(() => {
    reference.getAllSuburbs();
  }, [reference]);

  const suburbs = reference.getSuburbs(stateAbbreviation);
  if (!noSuburb && !required) {
    return <></>;
  }
  if (!required && (noSuburb === undefined || !!noSuburb)) {
    return <></>;
  }
  if (!!noSuburb && suburbs?.length === 0) {
    return <></>;
  }
  return (
    <YlDropdown
      options={suburbs ?? []}
      valueFn={(suburb: Suburb) => suburb}
      labelFn={(suburb: Suburb) => suburb.name}
      required={required}
      id="suburb"
      size={size}
      field={field}
      label={label}
      disabled={disabled}
      loading={!suburbs}
      onChange={onChange}
    />
  );
});
