import React from 'react';
import Form from 'react-bootstrap/Form';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import './studentForm.scss';

export const StudentEntry = () => {
  const { t } = useTranslation();
  const studentEntryStore = useModuleStore('studentEntry');

  return useObserver(() => {
    return (
      <>
        <p>{t('studentQuestion')}</p>
        <Form className="studentForm">
          <Form.Check
            custom
            id="student-unselected"
            label={t('studentNegativeAnswer')}
            type="radio"
            onChange={() => studentEntryStore.setIsStudent(false)}
            checked={!studentEntryStore.isStudent}
          />
          <Form.Check
            custom
            id="student-selected"
            label={t('studentPositiveAnswer')}
            type="radio"
            onChange={() => studentEntryStore.setIsStudent(true)}
            checked={studentEntryStore.isStudent}
          />
        </Form>
        {studentEntryStore.showStudentError && (
          <>
            <br />
            <Alert variant="danger">
              <p>{t('studentValidationError')}</p>
            </Alert>
          </>
        )}
      </>
    );
  });
};
