import React, { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  show: boolean | undefined;
  title?: string
  content?: string;
  button?: string;
  minimumPv: number;
  currentPv: number;
  onClose: () => void;
}

const Paragraph: FC = ({ children }) => {
  return <p>{children}</p>;
};

const PV: FC = ({ children }) => {
  return <span className="text-primary">{children}</span>;
};

export const MinimumPvWarningModal: FC<Props> = ({ show, minimumPv, currentPv, onClose, title, content, button }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} keyboard={false} backdrop="static" role="dialog">
      <Modal.Header closeButton onClick={() => onClose()}>
        <Modal.Title>{t(title || 'MinimumPvWarningModal_Title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Trans
          i18nKey={content || 'MinimumPvWarningModal_Content'}
          values={{
            minimumPv: minimumPv / 100,
            currentPv: currentPv / 100
          }}
          components={[<Paragraph />, <PV />]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onClose()}>
          {t(button || 'MinimumPvWarningModal_Ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
