import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Card, Button, Image } from 'react-bootstrap';
import { EnrollmentOrderItem } from './EnrollmentOrderInfoStore';
import { DetailedMoneyDisplay } from './DetailedMoneyDisplay';

interface KitGridItemProps {
  item: EnrollmentOrderItem;
  showDetailLink?: boolean;
  customImage?: string;
}

export const AdditionalItemCard = observer(function KitGridItem({
  item,
  showDetailLink = false,
  customImage
}: KitGridItemProps) {
  const { t } = useTranslation();
  const image = customImage || item.imageUrl;
  console.log(item);
  return (
    <>
      <Col key={item.partNumber} md={3} style={{ marginBottom: '20px' }}>
        <Card style={{ minHeight: '340px' }}>
          <div className="text-left bg-primary">
            <p className="text-white header-card-text m-1 h-56">{item.name}</p>
          </div>

          <Card.Body className="text-center">
            <span>
              <Button
                variant="link"
                // onClick={() => store.showProductInfo(item)}
              >
                <Image src={image} fluid />
              </Button>
            </span>
            {showDetailLink && (
              <Button
                variant="link"
                // onClick={() => store.showProductInfo(kit)}
              >
                <u>{t('Details')}</u>
              </Button>
            )}
          </Card.Body>
        </Card>
        <strong>
          <span className="pr-1">Price: </span>
          <DetailedMoneyDisplay
            price={item.priceWithTax}
            quantity={item.quantity}
            totalPrice={item.priceWithTaxTotal}
            currency={{ code: item.currency.code, symbol: item.currency.symbol }}
          />
        </strong>
      </Col>
    </>
  );
});
