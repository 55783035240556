import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const TaxInformation: FC = () => {
  const { t } = useTranslation();
  return useObserver(() => (
    <div className="mt-4 mb-4">
      <span>{t('taxInformationPopupDescription2a')}</span>
      <a href={t('taxInformationPopupDescription2LinkUrl')} target="_blank" rel="noopener noreferrer">
        {' '}
        {t('taxInformationPopupDescription2LinkText')}{' '}
      </a>
      <span>{t('taxInformationPopupDescription2b')}</span>
    </div>
  ));
};
