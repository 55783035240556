/* eslint-disable */
import React, { FC, ReactNode } from 'react';
import { useObserver } from 'mobx-react-lite';
import { FieldState } from 'formstate';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

interface YlCheckBoxProps {
  field: FieldState<any>;
  label: string | ReactNode;
  id?: string;
  required?: boolean;
  size?: number;
  help?: ReactNode;
  onChange?: (v: any) => void;
  hideError?: boolean;
  testId?: string;
  disabled?: boolean;
}

export const YlCheckBox: FC<YlCheckBoxProps> = ({
  field,
  label,
  id,
  required,
  size = 6,
  help,
  onChange,
  hideError,
  testId,
  disabled
}) => {
  const { t } = useTranslation();
  function onChanging() {
    field.onChange(!field.value);
    if (onChange) {
      onChange(field.value);
    }
  }
  return useObserver(() => {
    const disabledStyle = disabled ? { cursor: 'not-allowed' } : {};
    return (
      <Form.Group as={Col} md={size} controlId={id}>
        <Form.Check custom type="checkbox" data-testid={testId}>
          <Form.Check.Input
            type="checkbox"
            checked={field.value}
            onChange={onChanging}
            isInvalid={field.hasError}
            onBlur={field.enableAutoValidationAndValidate}
            disabled={disabled}
          />

          <Form.Check.Label style={disabledStyle}>
            {typeof label === 'object' && label}
            {typeof label === 'string' && (
              <>
                {`${t(label)} ${required ? ' *' : ''}`} {help !== undefined && help}
              </>
            )}
          </Form.Check.Label>
          {!hideError && <Form.Control.Feedback type="invalid">{t(field.error!)}</Form.Control.Feedback>}
        </Form.Check>
      </Form.Group>
    );
  });
};
