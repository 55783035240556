import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldState } from 'formstate';
import { YlDropdown } from '../../infrastructure/forms/YlDropdown';
import { useRootStore } from '../../app/root/RootStoreHooks';
import { YlInput } from '../../infrastructure/forms/YlInput';
import { City } from '../City';

export interface CityProps {
  id: string;
  fieldType: string;
  countryIso: string;
  stateProv?: string;
  name: string;
  size: number;
  required: boolean;
  field: FieldState<any>;
  label: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

export const Cities = observer(function Cities({
  id,
  fieldType,
  countryIso,
  stateProv,
  size,
  required,
  field,
  label,
  disabled,
  onChange
}: CityProps) {
  const { reference } = useRootStore();

  if (fieldType === 'Input') {
    return <YlInput required={required} size={size} field={field} label={label} id={id} />;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (stateProv && countryIso == 'PH') {
        reference.getCitiesByCountryAndStateProv(countryIso, stateProv);
      }
      if (stateProv && countryIso == "HK") {
        reference.getCitiesByCountryAndStateProv(countryIso, stateProv);
      }
      else {
        reference.getAllCities(countryIso);
      }
    }, [countryIso, stateProv, reference]);
    const cities = reference.getCities();

    return (
      <YlDropdown
        options={cities ?? []}
        valueFn={(city: City) => city.name}
        labelFn={(city: City) => city.name}
        required={required}
        id={id}
        size={size}
        field={field}
        label={label}
        disabled={disabled}
        loading={!cities}
        onChange={onChange}
      />
    );
  }
});
