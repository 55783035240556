import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { AgreementDocument } from './model/AgreementDocument';
import styles from './AgreementDocumentsDisplay.module.scss';

interface Props {
  documents: AgreementDocument[];
}

export const AgreementDocumentsDisplay: FC<Props> = ({ documents }) => {
  return useObserver(() => (
    <ul className={styles.items}>
      {documents.map(document => (
        <li key={document.description}>
          <a href={document.url} target="_blank" rel="noopener noreferrer">
            {document.description}
          </a>
        </li>
      ))}
    </ul>
  ));
};
