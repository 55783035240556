import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { YlInput } from '../../../../../infrastructure/forms/YlInput';
import { IndividualTaxEntry } from '../individual/IndividualTaxEntry';
import { BusinessTaxEntry } from '../business/BusinessTaxEntry';
import { TaxIdEntryStore } from '../../TaxIdEntryStore';

interface Props {
  store: TaxIdEntryStore;
}

function isBusinessEntryStore(store: IndividualTaxEntry | BusinessTaxEntry): store is BusinessTaxEntry {
  return (store as any).showBusinessName !== undefined;
}

const BusinessCheckBox: FC<Props> = ({ store }) => {
  const [t] = useTranslation();
  return (
    <>
      {store.businessContextAvailable && (
        <Form.Check
          className="ml-5"
          custom
          id="isTaxBusiness"
          label={t('taxIdIsBusiness')}
          type="checkbox"
          onChange={() => store.toggleBusinessOrIndividual()}
          checked={store.isCurrentContext('Business')}
        />
      )}
    </>
  );
};

export const CombinedTaxIdEntry: FC<Props> = ({ store }) => {
  const [t] = useTranslation();
  return useObserver(() => {
    const entry = store.selectedContext!.entries[0];
    const entryStore = entry.entryForCollectingCombinedTaxId;
    const field = entryStore.taxIdField;

    return (
      <>
        <p>{t('taxHelpIndividualDescription')}</p>
        <YlInput
          field={field}
          required={entryStore.requireTaxId}
          label="taxPersonalTaxId"
          size={6}
          helpLabel="taxIdHelpText"
          onChange={() => entryStore.fixDashes()}
          id="taxId"
          additionalLabel={<BusinessCheckBox store={store} />}
        />

        {isBusinessEntryStore(entryStore) && (
          <YlInput
            field={entryStore.reactForm.$.businessName}
            id="businessName"
            required={entryStore.requireBusinessName}
            label="taxBusinessName"
          />
        )}
      </>
    );
  });
};
