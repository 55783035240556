import React from 'react';
import { translateMonth } from './MonthName';

export const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export function MonthOptions() {
  return (
    <>
      {months.map(month => (
        <option key={month} label={`${month} - ${translateMonth(month - 1)}`} value={month} />
      ))}
    </>
  );
}
