import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { MoneyDisplay } from '../../order-selection/starter-kit-selection/util-starter-kit/MoneyDisplay';
import { Currency } from '../../../reference/Currency';

interface Props {
  priceList: any;
  currency: Currency;
  label: string;
}

export const ListPrice: FC<Props> = ({ priceList, currency, label }) => {
  const { t } = useTranslation();

  return useObserver(() => (
    <div className="d-flex">
      <span className="mr-auto">{t(label)}</span>
      <span className="">
        <MoneyDisplay price={priceList} currency={currency} />
      </span>
    </div>
  ));
};
