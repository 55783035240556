import React from 'react';
import { observer } from 'mobx-react-lite';
import { StarterKitStandardContent } from './StarterKitStandardContent';
import { StarterKitTabContent } from './StarterKitTabContent';
import { useRootStore } from '../../../root/RootStoreHooks';

export const KitSelector = observer(function TabSelector() {
  const store = useRootStore();
  return store.isMobileSized ? <StarterKitStandardContent /> : <StarterKitTabContent />;
});
