import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../../root/RootStoreHooks';

export const ExceliaAgreementInfoModal: FC = () => {
  const rootStore = useRootStore();
  const completeEnrollmentStore = rootStore.moduleStores.completeEnrollmentProcessor!;
  const [t] = useTranslation();

  return useObserver(() => (
    <Modal show={completeEnrollmentStore.exceliaAgreementInfoModal} backdrop="static">
      <Modal.Header>
        <Modal.Title>{t('ExceliaAgreementInfoTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('ExceliaAgreementInfoMessage')}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => completeEnrollmentStore.hideExceliaInfoModal()}>
          {t('OK')}
        </Button>
      </Modal.Footer>
    </Modal>
  ));
};
