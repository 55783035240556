import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { StarterKitTab } from '../../model/StarterKitTab';
import { ProductKit } from '../../model/ProductKit';
import { KitPriceDisplay } from '../../util-starter-kit/KitPriceDisplay';
import { KitCard } from '../card/KitGrid';
import { CardHeader } from '../card/StarterKitCardHeader';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';

interface BasicKitDisplayProps {
  kit: ProductKit;
}

export const BasicKitDisplay: FC<BasicKitDisplayProps> = observer(({ kit }) => {
  const { t } = useTranslation();
  const store = useModuleStore('enrollmentKitSelection');
  return (
    <Row>
      <Col lg={4} className="text-white text-center">
        <KitCard
          kit={kit}
          showDetailLink={false}
          customHeader={
            <CardHeader isSelected={kit === store.selectedKit} alwaysPrimary onClick={() => store.choseKit(kit)}>
              <KitPriceDisplay kit={kit} />
            </CardHeader>
          }
        />
      </Col>
      <Col lg={7}>
        <h2 className="text-primary">{kit.title}</h2>
        {/* eslint-disable react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: kit.description ? kit.description : '' }} />
        <div className="mt-5 ml-4">
          <h4>{t('BasicKitIncludes')}</h4>
          <div className="ml-2">
            <Row>
              <ul className="ml-3 d-flex flex-wrap">
                {kit.listItems.map(item => (
                  <Col lg={4} as="li" className="pl-2" key={item}>
                    {item}
                  </Col>
                ))}
              </ul>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
});

interface BasicKitTabProps {
  tab: StarterKitTab;
}

export const BasicKitTab = observer(({ tab }: BasicKitTabProps) => {
  return (
    <>
      {tab.productKits.map(kit => (
        <BasicKitDisplay key={kit.itemId} kit={kit} />
      ))}
    </>
  );
});
