import { useTranslation } from 'react-i18next';
import React from 'react';

export function getGenderOptions(t: any) {
  return [
    { value: 'Male', label: t('male') },
    { value: 'Female', label: t('female') },
    { value: 'NonBinary', label: t('nonbinary') },
    { value: 'Unspecified', label: t('rathernotsay') }
  ];
}

export function GenderOptions() {
  const { t } = useTranslation();
  return (
    <>
      {getGenderOptions(t).map(gender => (
        <option key={gender.value} value={gender.value} label={t(gender.label)} />
      ))}
    </>
  );
}
