import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { SnapshotOut } from 'mobx-state-tree';
import { Currency, getDecimalPrice } from '../../reference/Currency';

interface Props {
  price: number;
  quantity: number;
  totalPrice: number;
  currency: SnapshotOut<typeof Currency>;
}

export const DetailedMoneyDisplay: FC<Props> = ({ price, quantity, totalPrice, currency }) => {
  const formattedPrice = getDecimalPrice(price, currency.code);
  const formattedTotalPrice = getDecimalPrice(totalPrice, currency.code);
  return useObserver(() => (
    <>
      {currency.symbol}
      {formattedPrice}
      <span> x </span>
      {quantity}
      <span> = </span>
      {currency.symbol}
      {formattedTotalPrice}
      <small> {currency.code}</small>
    </>
  ));
};
