/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useTranslation } from 'react-i18next';
import { ProductInfoStore } from '../model/ProductInfoStore';
import { ProductInfoErKitStore } from '../model/ProductInfoErKitStore';

interface Props {
  product: ProductInfoStore | ProductInfoErKitStore;
}

export const TabsInformationDesktop: FC<Props> = observer(function TabsInformationDesktop({ product }) {
  const { t } = useTranslation();

  const getHtml = (text: any) => (text ? text.split('"\r\n\r\n"').toString() : undefined);

  const { shortDescription, howToUse, ingredients } = product;

  return (
    <Tabs id="tab-modal" defaultActiveKey="description">
      {shortDescription && (
        <Tab eventKey="description" title={t('Description')}>
          <div className="mt-4 bg-white" dangerouslySetInnerHTML={{ __html: getHtml(shortDescription) }} />
        </Tab>
      )}
      {howToUse && (
        <Tab eventKey="usage" title={t('Usage')}>
          <div className="mt-4 bg-white pb-2">
            <div dangerouslySetInnerHTML={{ __html: getHtml(howToUse) }} />
          </div>
        </Tab>
      )}
      {ingredients && (
        <Tab eventKey="ingredients" title={t('Ingredients')}>
          <div className="mt-4 bg-white">
            <div dangerouslySetInnerHTML={{ __html: getHtml(ingredients) }} />
          </div>
        </Tab>
      )}
    </Tabs>
  );
});
