import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldState } from 'formstate';
import { YlDropdown } from '../../infrastructure/forms/YlDropdown';
import { useRootStore } from '../../app/root/RootStoreHooks';
import { YlInput } from '../../infrastructure/forms/YlInput';
import { PostalCode } from '../PostalCode';

export interface PostalCodeProps {
  id: string;
  fieldType: string;
  countryIso: string;
  stateProv?: string;
  city?: string;
  //name: string;
  size: number;
  required: boolean;
  field: FieldState<any>;
  label: string;
  disabled?: boolean;
  additionalError: string;
  customAttributes: any;
  onChange?: (value: any) => void;
}

export const PostalCodes = observer(function PostalCodes({
  id,
  fieldType,
  countryIso,
  stateProv,
  city,
  size,
  required,
  field,
  label,
  disabled,
  additionalError,
  customAttributes,
  onChange
}: PostalCodeProps) {
  const { reference } = useRootStore();

  if (fieldType === 'Input') {
    return (
      <YlInput
        required={required}
        size={size}
        field={field}
        customAttributes={customAttributes}
        label={label}
        additionalError={additionalError}
        id={id}
        disabled={disabled}
        onChange={onChange}
      />
    );
  }
  else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (stateProv && city) {
        reference.getPostalCodeByCityAndStateProv(countryIso, stateProv, city);
      }
    }, [countryIso, stateProv, city, reference]);
    const postalCodes = reference.getPostalCodes();

    return (
      <YlDropdown
        options={postalCodes ?? []}
        valueFn={(postalCode: PostalCode) => postalCode.zipCode}
        labelFn={(postalCode: PostalCode) => postalCode.zipCode}
        required={required}
        id={id}
        size={size}
        field={field}
        customAttributes={customAttributes}
        label={label}
        additionalError={additionalError}
        disabled={disabled}
        loading={!postalCodes}
        onChange={onChange}
      />
    );
  }
});

