import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { ProductKit } from '../../../starter-kit-selection/model/ProductKit';
import { CardHeader } from '../card/CardHeader';
import { CardBody } from '../card/CardBody';
import { EssentialRewardKit } from '../../../er-kit-selection/model/EssentialRewardKit';

interface Props {
  kit: ProductKit | EssentialRewardKit;
  showProductInfo: (kit: ProductKit | EssentialRewardKit) => void;
  selectedKit?: ProductKit | EssentialRewardKit;
  choseKit: (kit?: ProductKit | EssentialRewardKit) => void;
}

export const KitGridItem: FC<Props> = observer(function KitGridItem({
  kit,
  showProductInfo,
  selectedKit,
  choseKit
}: Props) {
  return (
    <>
      <p className="text-center">{kit.title}</p>
      <div className="card">
        <CardHeader kit={kit} choseKit={choseKit} selectedKit={selectedKit} />
        <CardBody kit={kit} showProductInfo={showProductInfo} />
      </div>
    </>
  );
});
