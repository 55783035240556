import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SponsorEnrollerEntryStore } from './SponsorEnrollerEntryStore';
import { YlCheckBox } from '../../../infrastructure/forms/YlCheckBox';

interface Props {
  store: SponsorEnrollerEntryStore;
}

export const PlacementProgramConsent: FC<Props> = ({ store }) => {
  const { t } = useTranslation();
  return useObserver(() => {
    const form = store.placementForm;
    return (
      <div className="mt-2">
        <YlCheckBox
          field={form.$.consent}
          label={
            <>
              <p>
                {t('sponsorReferralProgram')}
                <span>
                  {' '}
                  <a target="_blank" rel="noopener noreferrer" className="text-primary" href={t('PolicyLink')}>
                    {t('sponsorReferralProgramLink')}
                  </a>
                </span>
              </p>
            </>
          }
          onChange={store.toggleConsentedToPlacement}
          id="placementConsentId"
          hideError
        />
      </div>
    );
  });
};
