import { useObserver } from 'mobx-react-lite';
import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
interface Props {
  show: boolean;
  onHide: () => void;
}

export const ThreeDSecureWarningModal: FC<Props> = ({ show, onHide }) => {
  const { t } = useTranslation();
  return useObserver(() => {
    return (
      <Modal size="lg" show={show} onHide={onHide} backdrop="static">
        <Modal.Header>
          <Modal.Title>
            <h2 className="text-primary">{t('3DSWarningTitle')}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('3DSWarningText')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onHide}>
            {t('ErrorDialog_DismissButtonLabel')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  });
};
