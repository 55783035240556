import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import Alert from 'react-bootstrap/Alert';

interface Props {
  storeChangeWarning: number;
  hasNoShippingMethods: boolean;
  showDangerousGoodsWarning: boolean;
  showCbdWarning: boolean;
  showRestrictedsGoodsWarning: boolean;
  itemNamesRestrictedByLocation: string[];
}

export const ShippingAlert: FC<Props> = ({
  storeChangeWarning,
  hasNoShippingMethods,
  showDangerousGoodsWarning,
  showCbdWarning,
  showRestrictedsGoodsWarning,
  itemNamesRestrictedByLocation
}) => {
  const { t } = useTranslation();
  function getAlertLabel() {
    if (storeChangeWarning === 1) return 'AlertMessageUsCatNonUsAddress';
    if (storeChangeWarning === 2) return 'AlertMessageNfrCatNonLocalAddress';
    if (showRestrictedsGoodsWarning) return 'AlertMessageRestrictedGoods';
    if (showDangerousGoodsWarning) return 'AlertMessageDangerousGoods';
    if (showCbdWarning) return 'AlertMessageCbdGoods';
    if (hasNoShippingMethods) return 'AlertMessageNoAddressNoShipping';
    return undefined;
  }

  return useObserver(() => {
    if (getAlertLabel() === undefined) {
      return <></>;
    }
    return (
      <Alert variant="danger" data-testid="alert-test">
        <Alert.Heading>{t('AlertTitle')}</Alert.Heading>
        <p>{getAlertLabel() && t(getAlertLabel()!)}</p>
        {showRestrictedsGoodsWarning &&
          itemNamesRestrictedByLocation.map(x => {
            return <div>{x}</div>;
          })}
      </Alert>
    );
  });
};
