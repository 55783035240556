import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { CurpCheckLink } from './support/CurpCheckLink';
import { YlInput } from '../../../../../infrastructure/forms/YlInput';
import { TaxIdContext } from '../TaxIdContext';
import { useTranslation } from 'react-i18next';

interface Props {
  context: TaxIdContext;
}

export const MexicoCurpEntry: FC<Props> = ({ context }) => {
  
  const { t } = useTranslation();

  return useObserver(() => {
    const curp = context.entries[0].mexicoCurp!;
    const taxForm = curp && curp.reactForm;
    if (!context.collectMexicoCurp) {
      return <></>;
    }
    return (
      <YlInput
        size={4}
        field={taxForm.$.curpId}
        required={curp.isRequired}
        label={t('curpLabel')}
        helpLabel={CurpCheckLink}
      />
    );
  });
};
