import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { ProductKit } from '../../model/ProductKit';

interface Props {
  productKit?: ProductKit;
}

export const ProductKitIncludes: FC<Props> = ({ productKit }) => {
  const { t } = useTranslation();

  return useObserver(() => (
    <div className="mt-5 ml-4">
      <h4>{t('KitIncludes')}</h4>
      <div className="ml-2">
        <Row>
          <ul className="ml-3 d-flex flex-wrap">
            {productKit &&
              productKit.listItems.map(item => (
                <Col lg={4} as="li" className="pl-2" key={item}>
                  {item}
                </Col>
              ))}
          </ul>
        </Row>
      </div>
    </div>
  ));
};
