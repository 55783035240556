import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { StepsManagerStore } from '../model/StepsManagerStore';
import { StandardPageSteps } from './step-style/StandardPageSteps';
import { useRootStore } from '../../../root/RootStoreHooks';

interface StepsManagerApi {
  stepsManager: StepsManagerStore;
}

export const StepsManagerContext = React.createContext<StepsManagerApi | undefined>(undefined);

export const StepsManager = () => {
  const rootStore = useRootStore();
  const stepsStore = rootStore.stepsManager;

  return useObserver(() => {
    if (!stepsStore.stepLoaded) {
      return <></>;
    }

    return (
      <StepsManagerContext.Provider value={{ stepsManager: stepsStore }}>
        <StandardPageSteps />
      </StepsManagerContext.Provider>
    );
  });
};
