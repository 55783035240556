import React, { ReactNode } from 'react';
import { FieldState } from 'formstate';
import { observer } from 'mobx-react-lite';
import { Form, InputGroup } from 'react-bootstrap';

import { YlFormGroup } from './YlFormGroup';

export interface YlInputProps {
  field: FieldState<any>;
  type?: string;
  label: string;
  id?: string;
  required?: boolean;
  customAttributes?: { [key: string]: any };
  size?: number;
  disabled?: boolean;
  helpLabel?: string | (() => string) | ReactNode;
  additionalError?: string | null;
  onChange?: (value: string | number) => void;
  additionalLabel?: ReactNode;
  onBlur?: () => void;
  prepend?: ReactNode;
  append?: ReactNode;
}

export const YlInput = observer<YlInputProps>(function YlInput({
  field,
  label,
  id,
  type,
  required,
  size,
  helpLabel,
  customAttributes = {},
  disabled = false,
  additionalError,
  onChange,
  additionalLabel,
  onBlur,
  prepend,
  append
}) {
  function triggerChange(value: any) {
    field.onChange(value);
    if (onChange) {
      onChange(value);
    }
  }

  function triggerOnBlur() {
    if (onBlur) {
      onBlur();
    }
    return field.enableAutoValidationAndValidate();
  }

  const control = (
    <Form.Control
      as="input"
      required={required}
      type={type || 'text'}
      value={field.value}
      onChange={(e: any) => triggerChange(e.target.value)}
      isInvalid={field.hasError}
      onBlur={triggerOnBlur}
      disabled={disabled}
      {...customAttributes}
    />
  );
  return (
    <YlFormGroup {...{ field, additionalLabel, label, id, required, size, helpLabel, additionalError, onBlur }}>
      {prepend || append ? (
        <InputGroup>
          {prepend}
          {control}
          {append}
        </InputGroup>
      ) : (
        control
      )}
    </YlFormGroup>
  );
});
