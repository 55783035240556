import React, { FC } from 'react';
import { FieldState } from 'formstate';
import { YlCheckBox } from '../../infrastructure/forms/YlCheckBox';

export interface CutomSuburbProps {
  field: FieldState<any>;
  id: string;
  label: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

export const CustomSuburb: FC<CutomSuburbProps> = ({ field, id, label, onChange }) => {
  return <YlCheckBox field={field} label={label} id={id} onChange={onChange} />;
};
