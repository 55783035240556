import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useModuleStore } from '../enrollment-process/module-management/model/ModuleStoreHook';
import { EnrollmentOrderInfo } from './EnrollmentOrderInfo';
import { SocialLinkExpiredModal } from './SocialLinkExpiredModal';
import { useRootStore } from '../root/RootStoreHooks';
import { MinimumPvWarningModal } from './creator-mode/MinimumPvWarningModal';
import { AddProducts } from '../order-selection/add-products/AddProducts';

export const SocialLinkEntry = () => {
  const store = useModuleStore('socialLinkEntry');
  const rootStore = useRootStore();
  const { t } = useTranslation();
  return useObserver(() => {
    const { enrollerId, enrollerFullName } = store.referrerReview!;
    return (
      <div className="container">
        <h2 className="text-primary">{t('SocialLinkEntry_FriendshipMatters')}</h2>
        <h5>
          <strong>{t('SocialLinkEntry_Welcome')}</strong>
        </h5>
        <div>
          {enrollerFullName} (member #{enrollerId}) {t('SocialLinkEntry_Content')}
        </div>
        <EnrollmentOrderInfo orderInfo={store.enrollmentOrderInfo!} />
        <SocialLinkExpiredModal show={rootStore.enrollmentStatus.socialLinkHasExpired} />
        {store.validated && (
          <div>
            <div>{t('SocialLinkEntry_ContinueEnrollment')}</div>
            {!store.isMinimumErPvReached && (
              <Row>
                <Col md="auto" className="my-3">
                  <AddProducts />
                </Col>
                <Col md="auto">
                  <Button block onClick={() => store.checkMinimumErPv()} className="mt-3" variant="primary">
                    {t('ContinueEnrollment')}
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        )}
        <MinimumPvWarningModal
          show={store.showMinimumPvWarning}
          content="MinimumPvEnroleeWarningModal_Content"
          minimumPv={store.enrollmentOrderInfo!.minimumErPv}
          currentPv={store.enrollmentOrderInfo!.currentPv}
          onClose={() => store.updateMinimumPvWarning(false)}
        />
      </div>
    );
  });
};
