import { StepLayout } from '../../../external/shared/api/EnrollmentClient.generated';
import { useRootStore } from '../../root/RootStoreHooks';

export const Variants = [StepLayout.Accordion, StepLayout.Standard] as const;
export type Variant = typeof Variants[number];

export function useVariant(): Variant {
  const root = useRootStore();
  switch (root.stepsManager.currentStep.layout) {
    case StepLayout.Accordion:
      return StepLayout.Accordion;
    default:
      return StepLayout.Standard;
  }
}
