import React from 'react';
import { observer } from 'mobx-react-lite';
import { StarterKitTab } from '../../model/StarterKitTab';
import { KitGrid } from '../card/KitGrid';
import { useTranslation } from 'react-i18next';

interface SpecialtyKitTabProps {
  tab: StarterKitTab;
}

export const SpecialtyKitTab = observer(({ tab }: SpecialtyKitTabProps) => {
  const { t } = useTranslation();
  return(
    <div className='specialty-kit-tab'>
      <div>
        <p>{t('SelectSpecialtyKitHelpPart1')}</p>
        <p>{t('SelectSpecialtyKitHelpPart2')}</p>
      </div>

      <KitGrid kits={tab.productKits} />
    </div>
  );
});
