import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PopOverList } from '../../../../infrastructure/forms/help-tip-icon/HelpTipIcon';

export const EmailPopOver: FC = () => {
  const { t } = useTranslation();
  return (
    <PopOverList>
      <div>
        <ul>
          <li>{t('promotions')}</li>
          <li>{t('newsAndAnnouncements')}</li>
        </ul>
      </div>
    </PopOverList>
  );
};
