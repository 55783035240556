import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ProductKit } from '../../model/ProductKit';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';

interface DynamicKitPriceDisplayProps {
  selectedKit?: ProductKit;
  kit: ProductKit;
}

const DynamicKitPriceDisplay: FC<DynamicKitPriceDisplayProps> = observer(function DynamicKitPriceDisplay({
  kit,
  selectedKit
}) {
  return <p className="text-left text-white pr-2 pt-2 ml-3">{kit.title}</p>;
});

interface CardHeaderProps {
  kit: ProductKit;
}

export const StarterKitPopularCardMobileHeader = observer(function StarterKitPopularCardHeader({
  kit
}: CardHeaderProps) {
  const store = useModuleStore('enrollmentKitSelection');
  const checked = store.selectedKit === kit;

  return (
    <div onClick={() => store.choseKit(kit)} className={`text-left ${checked ? 'bg-primary' : 'bg-secondary'}`}>
      <div className="container-checkbox-popular">
        <input type="checkbox" readOnly checked={checked} />
        <span className="checkmark-basic" />
        <DynamicKitPriceDisplay selectedKit={store.selectedKit} kit={kit} />
      </div>
    </div>
  );
});
