import React from 'react';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { useModuleStore } from '../../../enrollment-process/module-management/model/ModuleStoreHook';
import { YlCheckBox } from '../../../../infrastructure/forms/YlCheckBox';

export const EmailPreferencesEntry = () => {
  const preferencesEntry = useModuleStore('preferencesEntry');

  return useObserver(() => {
    const form = preferencesEntry.reactForm;
    return (
      <Form noValidate>
        <Form.Row>
          <YlCheckBox field={form.$.receiveEmail} label="receiveEmail" id="receiveEmail" />
        </Form.Row>
      </Form>
    );
  });
};
