import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { MeoCartSummary } from '../../order-selection/minimum-enrollment-order/MeoCartSummary';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { MoneyDisplay } from '../../order-selection/starter-kit-selection/util-starter-kit/MoneyDisplay';

export const MeoKitSelection = observer(() => {
  const { t } = useTranslation();
  const enrollmentKitSelection = useModuleStore('enrollmentKitSelection');
  const isMeoEnabled = enrollmentKitSelection.isMeoEnabled;
  const minimumEnrollmentOrderPv = enrollmentKitSelection.minimumEnrollmentOrderPv;

  return isMeoEnabled ? (
    <div className="mt-3">
      <h3 className="text-primary">{t('BuildMinimumEnrollmentOrder')}</h3>
      <p className="card-text">
        {t('BuildMinimumEnrollmentOrderHelp1')}{' '}
        <MoneyDisplay currency={{ symbol: '', code: '' }} price={minimumEnrollmentOrderPv} /> PV
        {t('BuildMinimumEnrollmentOrderHelp2')}
      </p>
      <MeoCartSummary />
    </div>
  ) : (
    <></>
  );
});
