import React from 'react';
import { useVariant } from '../../enrollment-process/module-management/ModuleVariants';
import { RadioLessTaxIdEntry } from './variants/RadioLessTaxIdEntry';
import { StandardTaxIdEntry } from './variants/StandardTaxIdEntry';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { StepLayout } from '../../../external/shared/api/EnrollmentClient.generated';

export const TaxIdEntry = () => {
  const variant = useVariant();
  const store = useModuleStore('taxIdEntry');
  if(store.defaultToIndividual){
    return <></>;
  }
  switch (variant) {
    case StepLayout.Accordion:
      return store.hasMultipleEntries ? <StandardTaxIdEntry /> : <RadioLessTaxIdEntry store={store} />;
    default:
      return <StandardTaxIdEntry />;
  }
};
