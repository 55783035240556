import React, { FC } from 'react';
import Form from 'react-bootstrap/Form';
import { useObserver } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { YlInput } from '../../../infrastructure/forms/YlInput';
import { SponsorEnrollerRadioButtons } from './SponsorEnrollerRadioButtons';
import { InformationSectionHeading } from '../account-credentials/AccountCredentialEntry';
import { SponsorEnrollerEntryStore } from './SponsorEnrollerEntryStore';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useModuleDisplayMode } from '../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';
import { PlacementProgramConsent } from './PlacementProgramConsent';
import { useRootStore } from '../../root/RootStoreHooks';
import { EnrollmentConfigTag } from '../../customer-enrollment-status/EnrollmentStatus';

interface Props {
  store: SponsorEnrollerEntryStore;
}

interface DisplayProps {
  sameAsEnrollerLabel: string;
  defaultLabel: string;
  onlyEnrollerLabel: string;
}

const SponsorEnrollerName: FC = ({ children }) => {
  return <span className="text-primary">{children}</span>;
};

const SponsorEnrollerId: FC = ({ children }) => {
  return <span className="text-primary">(#{children})</span>;
};

export const SponsorEnrollerDisplay: FC<DisplayProps> = ({ sameAsEnrollerLabel, defaultLabel, onlyEnrollerLabel }) => {
  const { t } = useTranslation();
  const entry = useModuleStore('sponsorEnrollerEntry');

  return useObserver(() => {
    if (!entry.wasReferred) {
      return <div>{t('noReferral')}</div>;
    }
    if (entry.excludeSponsor) {
      return (
        <Trans
          i18nKey={onlyEnrollerLabel}
          values={{
            enrollerName: entry.enrollerFullName,
            enrollerId: entry.enrollerId
          }}
          components={[<SponsorEnrollerName />, <SponsorEnrollerId />]}
        />
      );
    }
    if (entry.sponsorSameAsEnroller) {
      return (
        <Trans
          i18nKey={sameAsEnrollerLabel}
          values={{
            sponsorName: entry.sponsorFullName,
            sponsorId: entry.sponsorId
          }}
          components={[<SponsorEnrollerName />, <SponsorEnrollerId />]}
        />
      );
    }
    return (
      <Trans
        i18nKey={defaultLabel}
        values={{
          sponsorName: entry.sponsorFullName,
          enrollerName: entry.enrollerFullName,
          sponsorId: entry.sponsorId,
          enrollerId: entry.enrollerId
        }}
        components={[<SponsorEnrollerName />, <SponsorEnrollerId />]}
      />
    );
  });
};

export const SponsorEnrollerFields: FC<Props> = ({ store }) => {
  const { t } = useTranslation();
  return useObserver(() => {
    const form = store.reactForm;
    const gaiyoshomenForm = store.includeGaiyoshomen ? store.gaiyoshomenReactForm : undefined;
    const useSingleInput = store?.excludeSponsor;

    if (useSingleInput) {
      return (
        <>
          <Form.Row>
            <YlInput
              required
              size={4}
              field={form.$.enroller!}
              customAttributes={{ maxLength: 50 }}
              label="enrollerId"
              helpLabel={() => `${t('enrollerIdTip')} ${t('sponsorIdNoteTip')}`}
              additionalError={form.formError}
              id="enroller-id"
            />
          </Form.Row>
        </>
      );
    }
    return (
      <>
        <Form.Row>
          <YlInput
            required
            size={4}
            field={form.$.sponsor!}
            customAttributes={{ maxLength: 50 }}
            label="sponsorId"
            helpLabel="sponsorIdTip"
            additionalError={form.formError}
            id="sponsor-id"
          />
        </Form.Row>
        <Form.Row>
          <YlInput
            required
            size={4}
            field={form.$.enroller}
            customAttributes={{ maxLength: 50 }}
            label="enrollerId"
            helpLabel={() => `${t('enrollerIdTip')} ${t('sponsorIdNoteTip')}`}
            additionalError={form.formError}
            id="enroller-id"
          />
        </Form.Row>
        {gaiyoshomenForm && (
          <Form.Row>
            <YlInput
              required
              size={4}
              field={gaiyoshomenForm.$.gaiyoshomenCode}
              customAttributes={{ maxLength: 50 }}
              label="gaiyoshomenNumber"
              additionalError={gaiyoshomenForm.formError}
              id="gaiyoshomen-id"
            />
          </Form.Row>
        )}
      </>
    );
  });
};

export const SponsorEnrollerEntry = () => {
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const store = useModuleStore('sponsorEnrollerEntry');
  const displayMode = useModuleDisplayMode();
  return useObserver(() => {
    const form = store.reactForm;
    if (displayMode) {
      return (
        <>
          <div>
            <SponsorEnrollerDisplay
              sameAsEnrollerLabel="sponsorOnlyWelcome"
              defaultLabel="sponsorEnrollerWelcome"
              onlyEnrollerLabel="onlyEnrollerWelcome"
            />
          </div>
        </>
      );
    }
    if (rootStore.enrollmentStatus.tag === EnrollmentConfigTag.SocialLink) {
      return (
        <>
          <div>
            <div>
              <SponsorEnrollerDisplay
                sameAsEnrollerLabel="socialLinkSponsorOnlyChanging"
                defaultLabel="socialLinkSponsorChanging"
                onlyEnrollerLabel="onlyEnrollerWelcome"
              />
            </div>
            <Form noValidate>
              <Form.Row>
                <YlInput
                  required
                  size={4}
                  field={form.$.sponsor!}
                  customAttributes={{ maxLength: 50 }}
                  label="sponsorId"
                  helpLabel="SocialLink_SponsorIdTip"
                  additionalError={form.formError}
                  id="sponsor-id"
                />
              </Form.Row>
            </Form>
          </div>
        </>
      );
    }
    return (
      <>
        <InformationSectionHeading>{t('enrollMembershipDetailsHeading')}</InformationSectionHeading>
        <Form noValidate>
          <SponsorEnrollerRadioButtons>
            {store.wasReferred && <SponsorEnrollerFields store={store} />}
          </SponsorEnrollerRadioButtons>

          {store.showPlacementConsent && <PlacementProgramConsent store={store} />}

          {store.isPreventedFromSigningUp && (
            // eslint-disable-next-line react/no-danger
            <p className="mt-3" dangerouslySetInnerHTML={{ __html: t('sponsorRequired') }} />
          )}
        </Form>
      </>
    );
  });
};
