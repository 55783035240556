import React, { FC } from 'react';

import { EnrollmentPaymentType } from '../../model/EnrollmentPaymentTypes';
import { CreditCardEditor } from '../credit-card/CreditCardEditor';
import { SepaEditor } from '../Sepa/SepaEditor';
import { PlaceToPayOption } from '../place-to-pay/PlacetopayOption';
import { PayPalEditor } from '../paypal/PayPalEditor';
import { CashOnDeliveryOption } from '../cash-on-delivery/CashOnDeliveryOption';

interface PaymentMethodEditorProps {
  method: EnrollmentPaymentType;
}

export const PaymentMethodEditor: FC<PaymentMethodEditorProps> = ({ method }) => {
  switch (method) {
    case EnrollmentPaymentType.CreditCard:
      return <CreditCardEditor />;
    case EnrollmentPaymentType.Sepa:
      return <SepaEditor />;
    case EnrollmentPaymentType.PayPal:
      return <PayPalEditor />;
    case EnrollmentPaymentType.Placetopay:
      return <PlaceToPayOption />;
    case EnrollmentPaymentType.Cod:
      return <CashOnDeliveryOption />
    default:
      return null;
  }
};
