import i18n from 'i18next';

const monthNameKeys = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export function translateMonth(zeroBasedMonth: number) {
  // eslint-disable-next-line import/no-named-as-default-member
  return i18n.t(`${monthNameKeys[zeroBasedMonth]}`);
}
