import React from 'react';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { YlCheckBox } from '../../../infrastructure/forms/YlCheckBox';

export const GstAgreementEntry = () => {
  const gstAgreementEntry = useModuleStore('gstAgreementEntry');

  return useObserver(() => {
    const form = gstAgreementEntry.reactForm;
    return (
      <div className="mt-5 mb-3">
        <Form noValidate>
          <Form.Row>
            <YlCheckBox field={form.$.registered} label="GstRegisteredLabel" id="GstRegisteredLabel" />
          </Form.Row>
        </Form>

        {gstAgreementEntry.isRegistered() && (
          <>
            {gstAgreementEntry.text && (
              <div
                className="mt-4 mb-4"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: gstAgreementEntry.text.split('"\r\n\r\n"').toString() }}
              />
            )}
            <Form noValidate>
              <Form.Row>
                <YlCheckBox
                  field={form.$.agreementAccepted}
                  label="GstAgreementCheckboxLabel"
                  id="GstAgreementCheckboxLabel"
                />
              </Form.Row>
            </Form>
          </>
        )}
      </div>
    );
  });
};
