import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'react-bootstrap';

import { useRootStore } from '../../root/RootStoreHooks';
import { YlCheckBox } from '../../../infrastructure/forms/YlCheckBox';

export const OrderReturnPolicyReview = observer(() => {
  const { orderReturnPolicyReview, threeDSecureEntry } = useRootStore().moduleStores;
  const { t } = useTranslation();
  return (
    <Row>
      {threeDSecureEntry?.config.cybersource3dsEnabled && (
        <Form noValidate>
          <Form.Row>
            <Col md={12}>
              <YlCheckBox
                size={12}
                field={orderReturnPolicyReview!.reactForm.$.acceptReviewFormField}
                label={
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a href="#" onClick={() => orderReturnPolicyReview!.showModal()}>
                    {t('ReviewCheck')}
                  </a>
                }
                id="OrderReviewCheckboxLabelHeader"
              />
            </Col>
          </Form.Row>
        </Form>
      )}
    </Row>
  );
});
