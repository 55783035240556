import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { YlSelect } from '../../../../../../infrastructure/forms/YlSelect';
import { TaxIdContext } from '../../TaxIdContext';

interface CurpProps {
  context: TaxIdContext;
}

export const MexicoTaxSelector: FC<CurpProps> = ({ context }) => {
  const [t] = useTranslation();
  return useObserver(() => (
    <YlSelect
      size={4}
      field={context.contextField}
      label={t('taxIdType')}
      onChange={taxId => context.selectEntry(Number(taxId))}
    >
      <option value={0} className="text-gray-light">
        {t('selectTaxIdType')}
      </option>
      {context.entries.map(entry => (
        <option key={entry.taxIdTypeId} value={entry.taxIdTypeId}>
          {entry.individual!.name}
        </option>
      ))}
    </YlSelect>
  ));
};
