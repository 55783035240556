/* eslint-disable */
import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import {  FormState } from 'formstate';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { PostalCodes } from '../../reference/components/PostalCodes';
import { YlInput } from './YlInput';
import { Cities } from '../../reference/components/Cities';
import { ComponentField } from '../dynamic-forms/ComponentField';
import { AddressRow } from '../../app/address/main-address/module-form/AddressRow';
import { Countries } from '../../reference/components/Countries';
import { States } from '../../reference/components/States';
import { Suburbs } from '../../reference/components/Suburbs';
import { CustomSuburb } from '../../reference/components/CustomSuburb';

export enum AddressEntryType {
    MainAddress = "MainAddress",
    ShippingEntry = "ShippingEntry",
    BillingEntry = "BillingEntry"
}
interface YlAddressProps {
  row: AddressRow;
  index: number;
  form: FormState<any>;
  entryComponent: any;
  entryType: AddressEntryType;
  countryIso: string;
}

export const YlAddress: FC<YlAddressProps> = ({
    row,
    index,
    form,
    entryComponent,
    entryType,
    countryIso
}) => {
  function getCustomerAttributes(field: ComponentField) {
    const attr = {} as any;
    if (field.maxLength) {
      attr.maxLength = field.maxLength;
    }

    if (field.minLength) {
      attr.minLength = field.minLength;
    }

    return attr;
  }
  return useObserver(() => {
    return (
        
        <Form.Row key={index}>
        {row.formFields.map((field: ComponentField) => (
          <React.Fragment key={field.name}>
              {field.name === 'postalCode' && !field.disabled && (
                <PostalCodes
                  required={field.required}
                  size={field.size}
                  field={form.$[field.name]}
                  customAttributes={getCustomerAttributes(field)}
                  label={field.labelKey}
                  additionalError={entryComponent.invalidPostalCodeMessage}
                  id={field.name}
                  disabled={field.disabled}
                  fieldType={field.fieldType}
                  countryIso={countryIso}
                  stateProv={entryComponent.targetStateProv}
                  city={entryComponent.targetCity}
                  onChange={() => {
                    if(entryType == AddressEntryType.MainAddress){
                        entryComponent.tryAutoFillMXData(field);
                    }
                    entryComponent.applyFormat(field);
                }}
              />
            )}

            {field.fieldType === 'Input' &&
              field.name !== 'postalCode' &&
              field.name !== 'city' &&
              (field.name !== 'street2' || countryIso !== 'CA') && (
                <YlInput
                  required={field.required}
                  size={field.size}
                  field={form.$[field.name]}
                  customAttributes={getCustomerAttributes(field)}
                  label={field.labelKey}
                  id={field.name}
                  disabled={field.disabled}
                  onChange={() => entryComponent.applyFormat(field)}
                />
              )}
            {field.name === 'city' && (
              <Cities
                id={field.name}
                fieldType={field.fieldType}
                countryIso={countryIso}
                stateProv={entryComponent.targetStateProv}
                required={field.required}
                name={form.$.city.value}
                field={form.$[field.name]}
                size={field.size}
                label={field.labelKey}
                disabled={field.disabled}
                onChange={city => {
                    if(entryType == AddressEntryType.MainAddress || entryType == AddressEntryType.BillingEntry){
                        entryComponent.setCity(city);
                    }
                }}
              />
            )}
            
            {field.fieldType === 'Select' && field.name === 'country' && (
              <Countries
                size={field.size}
                required={field.required}
                field={form.$[field.name]}
                form={form}
                disabled={field.disabled}
                label={field.labelKey}
                onChange={country => {
                    if(entryType == AddressEntryType.MainAddress){
                        entryComponent.warnAboutMarketChange(country);
                    }
                    else {
                        entryComponent.onCountryChange(country);
                    }
                }}
              />
            )}

            {field.fieldType === 'Select' && field.name === 'stateProv' && (
              <States
                required={field.required}
                countryIso={form.$.country.value}
                field={form.$[field.name]}
                size={field.size}
                label={field.labelKey}
                disabled={field.disabled}
                onChange={stateProv => {
                    switch(entryType){
                        case AddressEntryType.MainAddress: 
                        case AddressEntryType.BillingEntry:
                            entryComponent.setStateProv(stateProv);
                        case AddressEntryType.ShippingEntry:
                            entryComponent.onStateChange();
                        default:
                            return;
                    }
                }}
              />
            )}

            {field.fieldType === 'Select' && field.name === 'suburb' && (
              <Suburbs
                required={field.required}
                stateAbbreviation={form.$.stateProv.value}
                noSuburb={form.$.noSuburb.value}
                field={form.$[field.name]}
                size={field.size}
                label={field.labelKey}
                onChange={entryComponent.UpdateSuburb}
              />
            )}

            {field.fieldType === 'Check' && field.name === 'noSuburb' && (
              <CustomSuburb
                field={form.$.noSuburb}
                label={field.labelKey}
                id={field.name}
                onChange={entryComponent.UpdateNoSuburb}
              />
            )}
          </React.Fragment>
        ))}
      </Form.Row>
    )
  })
}