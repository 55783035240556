import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { YesNoQuestion } from './yes-no-question/YesNoQuestion'; 
import { UsePremiumStarterKitQuestion } from './UsePremiumStarterKitQuestion';
import { Alert } from 'react-bootstrap';
import { useRootStore } from '../../root/RootStoreHooks';
import { MoneyDisplay } from '../starter-kit-selection/util-starter-kit/MoneyDisplay';

const ErYesMessage = () => {
  const { t } = useTranslation();
  return (
    <>
      {t('EssentialRewardsIfYes')}{' '}
      <a
        href={
          t('EssentialRewardsTermsAndConditionsUrl') ||
          'https://static.youngliving.com/en-US/PDFS/ER_Agreement_Terms_and_Conditions_en_US.pdf'
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        {' '}
        {t('EssentialRewardsTermsAndConditions')}{' '}
      </a>
      {t('EssentialRewardsYouMayCancelAnyTime')}
    </>
  );
};

export const EnrollEssentialRewardsQuestion: FC = observer(() => {
  const { t } = useTranslation();
  const root = useRootStore();
  const erKitStore = root.moduleStores.erKitSelection ?? root.moduleStores.socialLinkErKitSelection!;
  const meoStore = root.moduleStores.meoKitSelection!;
  const enrollmentrKitSelectionStore = root.moduleStores.enrollmentKitSelection ?? root.moduleStores.enrollmentKitSelection!;

  const handleERPress = (value: boolean) => {
    if (!!meoStore && !meoStore?.canProceed()) {
      meoStore?.setRequiredModal(true);
      enrollmentrKitSelectionStore.setMinimumPVRequiredModal(false);
      erKitStore.updateEssentialRewards(undefined);

      return;
    }
    // Functionality for Yes/No Button Warning Message
    if (!!enrollmentrKitSelectionStore && !enrollmentrKitSelectionStore?.canProceed()) {
      
      if(value==true){
          enrollmentrKitSelectionStore.setMinimumPVERTextModal(true); 
          enrollmentrKitSelectionStore.setMinimumPVRequiredModal(true);
          meoStore?.setRequiredModal(false);
          erKitStore.updateEssentialRewards(undefined);
      }
      else{
          enrollmentrKitSelectionStore.setMinimumPVERTextModal(false);
          enrollmentrKitSelectionStore.setMinimumPVRequiredModal(true);
          meoStore?.setRequiredModal(false);
          erKitStore.updateEssentialRewards(undefined);
      }
      return;
    }
        enrollmentrKitSelectionStore?.setMinimumPVRequiredModal(false);
        meoStore?.setRequiredModal(false);
        erKitStore.updateEssentialRewards(value);
          
        if (erKitStore.useErSingleQuestion) {
          erKitStore.updateUsePremiumStarterKit(value);
        }
    
  };
  return (
    <>
      <YesNoQuestion
        onChange={value => handleERPress(value)}
        value={erKitStore.enrollEssentialRewards}
        question={t('AskIfEnrollInEssentialRewards')}
        whenYes={<ErYesMessage />}
        whenNo={t('EssentialRewardsIfNo')}
        err={!(meoStore?.canProceed() ?? true)}
      />
      {!erKitStore.useErSingleQuestion && erKitStore.showUsePremiumStarterKitQuestion && (
        <UsePremiumStarterKitQuestion />
      )}
      {meoStore?.orderRequiredModal && (
        <Alert variant="warning">
          <Alert.Heading>{t('selectStarterKitWarningHeader')}</Alert.Heading>
          <p>{t('selectStarterKitWarningMessage')}</p>
        </Alert>
      )}
       {enrollmentrKitSelectionStore?.minimumPVRequiredModal && (
        <Alert variant="warning">
          <Alert.Heading>{t('selectMinimumPVWarningHeader')}</Alert.Heading>
          <p>{t('selectMinimumPVWarningMessage1')}{' '}
          <MoneyDisplay currency={{ symbol: '', code: '' }} price={enrollmentrKitSelectionStore.minimumErPv} /> PV {' '}
           {t('selectMinimumPVWarningMessage2')}{' '}
           {enrollmentrKitSelectionStore?.setERTextModal && (<span>{t('selectMinimumPVWarningMessage3')}</span>) } {' '}
           {t('selectMinimumPVWarningMessage4')}</p>
        </Alert>
      )}
    </>
  );
});
