import { observer } from 'mobx-react-lite';
import React from 'react';
import { KitGridMobile } from './KitGridMobile';
import { KitGridDesktop } from './KitGridDesktop';

interface KitGridSelectorProps {
  isMobile: boolean;
}

export const KitGridSelector = observer(function KitGridSelector({ isMobile }: KitGridSelectorProps) {
  return isMobile ? <KitGridMobile /> : <KitGridDesktop />;
});
