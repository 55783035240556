import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import './CustomerType.scss';
import { useTranslation } from 'react-i18next';

interface CustomerTypeBenefitsProps {
  type: string;
}
interface SingleBenefit {
  key: string;
  text: string;
}

export const CustomerTypeBenefits: FC<CustomerTypeBenefitsProps> = observer(function CustomerTypeBenefits({ type }) {
  const { t, i18n } = useTranslation();

  const keyBase = type === 'Distributor' ? 'distributorBenefit' : 'preferredBenefit';

  const getBenefits = (): SingleBenefit[] => {
    const result: SingleBenefit[] = [];

    let index = 1;
    let hasNextValue = false;
    do {
      const key = `${keyBase}${index}`;
      if (i18n.exists(key) && t(key) !== null && t(key) !== '' && t(key) !== ' ') {
        result.push({
          key: key,
          text: t(key)
        });

        index++;
        hasNextValue = true;
      } else {
        hasNextValue = false;
      }
    } while (hasNextValue);

    return result;
  };

  const benefits = getBenefits();

  return (
    <ul>
      {benefits.map(benefit => {
        return <li key={benefit.key}>{benefit.text}</li>;
      })}
    </ul>
  );
});
