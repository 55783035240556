import React from 'react';
import { useObserver } from 'mobx-react-lite';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import styles from '../order-review.module.scss';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

function getLabelForCustomerType(customerType: string) {
  switch (customerType) {
    case 'Distributor':
      return 'IndependentBusinessBuilder';
    case 'WholeSale':
      return 'PreferredCustomer';
    default:
      return 'Unknown';
  }
}

export const MembershipReview = () => {
  const { t } = useTranslation();
  const membershipReviewStore = useModuleStore('membershipReview');
  const customerLabel = getLabelForCustomerType(membershipReviewStore.customerType);

  return useObserver(() => (
    <Card className={styles.card}>
      <Card.Header className={styles.cardHeader}>{t('Membership')}</Card.Header>
      <Card.Body>
        <Card.Title className="text-center">{t(customerLabel)}</Card.Title>
      </Card.Body>
    </Card>
  ));
};
