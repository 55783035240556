import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { KitSelection } from '../../order-selection/er-kit-selection/util-er-kit/KitSelection';
import { EnrollEssentialRewardsQuestion } from '../../order-selection/er-kit-selection/EnrollEssentialRewardsQuestion';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { ErPurchasingReminder } from '../../order-selection/er-kit-selection/ErPurchasingReminder';
import { MinimumPvWarningModal } from './MinimumPvWarningModal';

export const SocialLinkErKitSelection: FC = observer(() => {
  const { t } = useTranslation();
  const erKitStore = useModuleStore('socialLinkErKitSelection');
  const enrollmentKitStore = useModuleStore('enrollmentKitSelection');

  erKitStore.updateUsePremiumStarterKit(true);
  erKitStore.updateCanCustomizeErKit(true);

  return (
    <>
      {enrollmentKitStore.selectedKitOrProducts() && (
        <div className="mt-3">
          <h2 className="card-title text-primary">
            {t('starterKitsStep2')}: {t('EssentialRewardsEnrollment')}
          </h2>
          <p className="card-text">
            {t('EssentialRewardsSubHeading')}
            <br />
            <a
              href={
                t('EssentialRewards_LearnMoreHereUrl') ||
                'https://www.youngliving.com/en_US/opportunity/essential-rewards'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('EssentialRewardsSubHeadingLearnMoreHere')}
            </a>
          </p>
          <EnrollEssentialRewardsQuestion />
        </div>
      )}
      {erKitStore.enrollEssentialRewards && enrollmentKitStore.isMinimumErPvReached && <KitSelection />}
      {erKitStore.erUpsellEnabled && enrollmentKitStore.isMinimumErPvReached && (
        <ErPurchasingReminder store={erKitStore.erUpsell!} />
      )}
      <MinimumPvWarningModal
        show={erKitStore.enrollEssentialRewards && !enrollmentKitStore.isMinimumErPvReached}
        minimumPv={enrollmentKitStore.minimumErPv}
        currentPv={enrollmentKitStore.currentPv}
        onClose={() => erKitStore.updateEssentialRewards(undefined)}
      />
    </>
  );
});
