import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkedinShareButton, PinterestShareButton, TwitterShareButton, FacebookShareButton } from 'react-share';
import { faFacebook, faLinkedin, faPinterest, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './SocialLinkCreatedModal.scss';
import { useRootStore } from '../../root/RootStoreHooks';
interface Props {
  show: boolean;
  template?: any;
}

export const SocialLinkCreatedModal: FC<Props> = ({ show }) => {
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const store = rootStore.moduleStores.socialLinkCreatorEntry!;

  return useObserver(() => (
    <Modal className="social-link-created-modal" show={show} keyboard={false} backdrop="static" role="dialog">
      <Modal.Header closeButton>
        <Modal.Title>{t('SocialLinkCreatedModal_SocialLinkCreated')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{t('SocialLinkCreatedModal_Congratulations')}</div>
        <div>{t('SocialLinkCreatedModal_SuccessSocialLinkCreated')}</div>
        <div className="link">
          <span>{store.socialLink}</span>
        </div>
        <div>{t('SocialLinkCreatedModal_CopyLinkMessage')}</div>
        <div className="social-link-share-buttons">
          <FacebookShareButton
            url={store.socialLink}
            translate="yes"
            beforeOnClick={() => store.addShareStatus('facebook')}
          >
            <FontAwesomeIcon size="2x" icon={faFacebook} />
          </FacebookShareButton>
          <TwitterShareButton
            url={store.socialLink}
            translate="yes"
            beforeOnClick={() => store.addShareStatus('twitter')}
          >
            <FontAwesomeIcon size="2x" icon={faTwitter} />
          </TwitterShareButton>
          <PinterestShareButton
            url={store.socialLink}
            media={'https://static.youngliving.com/EU-DOCS/IMGS/product_guides/Cover-ProductGuide-2020-DE.jpg'}
            translate="yes"
            beforeOnClick={() => store.addShareStatus('pinterest')}
          >
            <FontAwesomeIcon size="2x" icon={faPinterest} />
          </PinterestShareButton>
          <LinkedinShareButton
            url={store.socialLink}
            translate="yes"
            beforeOnClick={() => store.addShareStatus('linkedin')}
          >
            <FontAwesomeIcon size="2x" icon={faLinkedin} />
          </LinkedinShareButton>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={'outline-secondary'} onClick={() => store.redirectToVO()}>
          {t('SocialLinkCreatedModal_Close')}
        </Button>
        <Button
          variant={store.socialLinkCopied ? 'outline-warning' : 'warning'}
          disabled={store.socialLinkCopied}
          onClick={() => store.copySocialLink()}
        >
          {store.socialLinkCopied
            ? t('SocialLinkCreatedModal_CopiedToClipboard')
            : t('SocialLinkCreatedModal_CopyToClipboard')}
        </Button>
      </Modal.Footer>
    </Modal>
  ));
};
