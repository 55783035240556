import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { ComponentField } from '../../../infrastructure/dynamic-forms/ComponentField';
import { Field } from '../shared/Field';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useModuleDisplayMode } from '../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';
import { useOptionalSectionTitles } from '../../enrollment-process/steps-management/model/SectionTitles';
import { useRootStore } from '../../root/RootStoreHooks';

export const NameEntryDisplay: FC = () => {
  const nameEntry = useModuleStore('nameEntry');
  const rootStore = useRootStore();
  const { t } = useTranslation();
  return useObserver(() => (
    <div>
      <span>{t('Name')}: </span>
      {rootStore.enrollmentStatus.storeId == 3 && (<span className="text-primary">{`${nameEntry.data.lastName} ${nameEntry.data.firstName} `}</span>)}
      {rootStore.enrollmentStatus.storeId != 3 && (<span className="text-primary">{`${nameEntry.data.firstName} ${nameEntry.data.lastName}`}</span>)}
    </div>
  ));
};

export const StepTitleHead: FC = () => {
  const { titleInParent } = useOptionalSectionTitles();
  const { t } = useTranslation();
  return titleInParent ? (
    <div>
    <h3 className="text-primary">{t('applicantInformation')}</h3>
     <p>{t('applicantInformationDescription')}</p>
    </div>
  ) : <></>;
};

export const NameEntry: FC = () => {
  const nameEntry = useModuleStore('nameEntry');
  const moduleDisplayMode = useModuleDisplayMode();
  function getCustomerAttributes(field: ComponentField) {
    const attr = {} as any;
    if (field.maxLength) {
      attr.maxLength = field.maxLength;
    }

    if (field.minLength) {
      attr.minLength = field.minLength;
    }

    return attr;
  }

  return useObserver(() => {
    if (moduleDisplayMode) {
      return <NameEntryDisplay />;
    }
    return (
      <div>
        <StepTitleHead />
        <Form>
          <Form.Row>
            {nameEntry.form.formFields.map((field: ComponentField) => (
              <Field
                size={field.size == 0 ? 4 : field.size}
                field={field}
                key={field.id}
                form={nameEntry.reactForm}
                customAttributes={getCustomerAttributes(field)}
                onChange={() =>
                {
                  nameEntry.applyFormat(field);
                  nameEntry.tryTransalate(field);
                }}
              />
            ))}
          </Form.Row>
        </Form>
      </div>
    );
  });
};
