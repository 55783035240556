import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import { saveStepAndGoToCustomizeEr } from '../../custom-kit/CustomizeRedirector';
import { useRootStore } from '../../../../root/RootStoreHooks';

interface Props {
  isSelected: boolean;
  selectCustomizeKit: () => void;
}

const HeaderCustom: FC<Props> = ({ isSelected, selectCustomizeKit }) => {
  const { t } = useTranslation();
  const store = useRootStore();

  function handleClick() {
    selectCustomizeKit();
    saveStepAndGoToCustomizeEr(store);
  }

  return (
    <div className={`text-right ${isSelected ? 'bg-primary' : 'bg-secondary'}`} onClick={() => handleClick()}>
      <div className="container-checkbox-popular">
        <input type="checkbox" readOnly checked={isSelected} />
        <span className="checkmark-basic" />
        <p className="text-white pr-2 pt-2">{t('CustomizeYourKit')}</p>
      </div>
    </div>
  );
};

interface ItemProps {
  customKitSelected: boolean;
  selectCustomizeKit: () => void;
}

export const KitGridItemCustom: FC<ItemProps> = ({ customKitSelected, selectCustomizeKit }) => {
  const { t } = useTranslation();
  const store = useRootStore();

  function handleClick() {
    selectCustomizeKit();
    saveStepAndGoToCustomizeEr(store);
  }

  return useObserver(() => (
    <>
      <p className="text-center">{t('CustomizeAutoshipTitleLabel')}</p>
      <div className="card">
        <HeaderCustom isSelected={customKitSelected} selectCustomizeKit={selectCustomizeKit} />
        <div className="card-body text-center card-text">
          <span>
            <Button variant="link">
              <Image src="https://static.youngliving.com/productimages/large/CustomERImage.jpg" alt="" fluid />
            </Button>
          </span>
          <div className="d-flex flex-row flex-column">
            <Button variant="primary" onClick={() => handleClick()}>
              {t('CustomizeAutoship')}
            </Button>
          </div>
        </div>
      </div>
    </>
  ));
};
