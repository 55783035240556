import React, { FC, useCallback, useContext, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { StepsManagerContext } from '../../enrollment-process/steps-management/components/StepsManager';
import { useRootStore } from '../../root/RootStoreHooks';

export const ContinueEnrollment: FC = observer(() => {
  const [ref] = useHookWithRefCallback();
  const { t } = useTranslation();
  const { stepsManager } = useContext(StepsManagerContext)!;
  const rootStore = useRootStore();

  if (stepsManager.currentStep.navigation.hideNavigation === true && !rootStore.moduleStores.canProceed) {
    return <></>;
  }

  return (
    <div ref={ref} className="mt-3">
      <h2 id="ContinueEnrollment" className="card-title text-primary">
        {t('ContinueEnrollment')}
      </h2>
      <p className="card-text">{t('PleaseContinueEnrollment')}</p>
    </div>
  );
});
function useHookWithRefCallback() {
  const ref = useRef<HTMLDivElement | null>(null);
  const setRef = useCallback(node => {
    if (node) {
      node.scrollIntoView({ behavior: 'smooth' });
    }

    ref.current = node;
  }, []);

  return [setRef];
}
