import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { CreditCardDisplay } from '../credit-card/CreditCardDisplay';
import { SepaDisplay } from '../Sepa/SepaDisplay';
import { BillingEntryStore } from '../../model/BillingEntryStore';
import { EnrollmentPaymentType, getPaymentTypeLabelName } from '../../model/EnrollmentPaymentTypes';

interface BillingInformationDisplayProps {
  store: BillingEntryStore;
}

export const BillingInformationDisplay = observer<BillingInformationDisplayProps>(({ store }) => {
  const { t } = useTranslation();

  switch (store.selectedPaymentType) {
    case EnrollmentPaymentType.CreditCard:
      return <CreditCardDisplay creditCard={store.creditCardData} />;
    case EnrollmentPaymentType.Sepa:
      return <SepaDisplay sepa={store.sepaData} />;
    default:
      return <span>{t(getPaymentTypeLabelName(store.selectedPaymentType))}</span>;
  }
});
