import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { YlInput } from '../../../infrastructure/forms/YlInput';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useModuleDisplayMode } from '../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';
import { useRootStore } from '../../root/RootStoreHooks';

export const PinEntryDisplay: FC = () => {
  const { t } = useTranslation();
  return useObserver(() => (
    <div>
      <span>{t('pin')}: </span>
      <span className="text-primary">****</span>
    </div>
  ));
};

export const PinEntry: FC = () => {
  const pinEntry = useModuleStore('pinEntry');
  const moduleDisplayMode = useModuleDisplayMode();
  const { t } = useTranslation();
  const rootStore= useRootStore();

  return useObserver(() => {
    const form = pinEntry.reactForm;
    if (moduleDisplayMode) {
      return <PinEntryDisplay />;
    }

    return (
      <>
      <Form>
        <Form.Row className="row">
          <YlInput
            type="password"
            required
            customAttributes={{ maxLength: 4 }}
            size={4}
            field={form.$.pin}
            label="pin"
            id="pin"
            helpLabel="pinHelp"
          />
          <YlInput
            type="password"
            required
            customAttributes={{ maxLength: 4 }}
            size={4}
            field={form.$.confirmPin}
            label="pinVerification"
            id="confirm-pin"
          />
        </Form.Row>
      </Form>
      { rootStore.moduleStores.showDataProtectionSection && (
       <div className="mt-3">
          <h2>Personal Data Protection Act</h2>
          <p className="card-text">
            {t('PersonalDataProtectionActDetail')}                           
          </p>
       </div>
      )
  }
   </>
    );
  });
};
// default:PinError enrollment:pinHelp;
