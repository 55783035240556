import React from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import './Placetopay.scss';
import CardLogos from './images/card-logos.png';

export const PlaceToPayOption = () => {
  const { t } = useTranslation();
  const placetopayFAQUrl = 'https://static.youngliving.com/es-EC/PDFS/FAQ%20PAGOS%20ELECTRONICOS-PLACETOPAY3.pdf';
  const placetopayLogoUrl = 'https://www.placetopay.com/web/';
  const placetopayLogoLocation = 'https://static.placetopay.com/placetopay-logo.svg';

  return useObserver(() => (
    <>
      <a href={placetopayLogoUrl} target="_blank" rel="noopener noreferrer">
        <img src={placetopayLogoLocation} className="placetopay-logo" alt="Placetopay logo" />
      </a>
      <img src={CardLogos} alt="Cards logo" />
      <a href={placetopayFAQUrl} className="placetopay-faq" target="_blank" rel="noopener noreferrer">
        {t('PlacetopayFAQ')}
      </a>
    </>
  ));
};
