import type { PayPalCheckout, PayPalCheckoutCreatePaymentOptions } from 'braintree-web';
import type {
  FlowType,
  Intent,
  ButtonColorOption,
  ButtonShapeOption,
  ButtonStyle,
  ButtonLabelOption
} from 'paypal-checkout-components';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { apiClient, createClient } from './VirtualWalletStore';
import { EnrollmentPaymentType, VirtualWalletType } from '../../../../external/shared/api/EnrollmentClient.generated';
import { PaypalButtonPlaceholder } from '../paypal/PayPalButtonPlaceholder';
import { useRootStore } from '../../../root/RootStoreHooks';

export const VirtualWalletPaypalButton = observer(() => {
  const { billingEntry } = useRootStore().moduleStores;

  useEffect(() => {
    let paypalCheckoutInstance: PayPalCheckout | undefined = undefined;

    async function initialize() {
      const clientInstance = await createClient(VirtualWalletType.Paypal);
      const { paypalCheckout } = await import('braintree-web');
      paypalCheckoutInstance = await paypalCheckout.create({ client: clientInstance });

      await paypalCheckoutInstance.loadPayPalSDK({
        intent: 'tokenize',
        vault: true
      });

      const shippingAddress = billingEntry!.userShippingAddress!;

      window.paypal
        .Buttons({
          fundingSource: window.paypal.FUNDING.PAYPAL as any,
          style: {
            layout: 'horizontal',
            color: 'gold' as ButtonColorOption.Gold,
            shape: 'rect' as ButtonShapeOption.Rect,
            label: 'paypal' as ButtonLabelOption.PayPal
          } as ButtonStyle,
          createBillingAgreement: () => {
            const paymentInfo: PayPalCheckoutCreatePaymentOptions = {
              flow: 'vault' as FlowType.Vault,
              intent: 'tokenize' as Intent,
              billingAgreementDescription: 'Description of the billing agreement to display to the customer',
              enableShippingAddress: true,
              shippingAddressEditable: false,
              shippingAddressOverride: {
                recipientName: shippingAddress.recipient,
                line1: shippingAddress.street1!,
                line2: shippingAddress.street2!,
                city: shippingAddress.city!,
                countryCode: shippingAddress.country!,
                postalCode: shippingAddress.postalCode!,
                state: shippingAddress.stateProv!,
                phone: shippingAddress.phoneNumber
              }
            };
            return paypalCheckoutInstance!.createPayment(paymentInfo);
          },
          onApprove: async data => {
            const payload = await paypalCheckoutInstance!.tokenizePayment(data);

            await apiClient.addVirtualWallet(VirtualWalletType.Paypal, payload.nonce);

            billingEntry!.switchSelectedPaymentType(EnrollmentPaymentType.VirtualWalletPaypal);

            return payload;
          }
        })
        .render('#paypal-button-container');
    }
    const initPromise = initialize();

    async function dispose() {
      try {
        await initPromise;
      } finally {
        paypalCheckoutInstance?.teardown();
      }
    }

    return () => {
      dispose();
    };
  }, [billingEntry]);

  return (
    <div className="mt-2" id="paypal-button-container">
      <PaypalButtonPlaceholder />
    </div>
  );
});
