import React, { FC, useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

// eslint-disable-next-line @typescript-eslint/ban-types
export const SponsorEnrollerRadioButtons: FC<{}> = ({ children }) => {
  const { t } = useTranslation();
  const store = useModuleStore('sponsorEnrollerEntry');

  useEffect(() => {
    window.location.href = "#sponsor-enroller-selected";
    window.scrollBy({
      top: -200,
      left: 0,
      behavior: "smooth"
    });
  });

  return useObserver(() => (
    <>
      <Form.Check
        custom
        id="sponsor-enroller-selected"
        label={t('referredByMemberWithIds')}
        type="radio"
        onChange={() => store.setWasReferred(true)}
        checked={store.wasReferred === true}
      />
      {store.wasReferred && children}
      <Form.Check
        custom
        id="sponsor-enroller-unselected"
        label={t('noReferral')}
        type="radio"
        onChange={() => store.setWasReferred(false)}
        checked={store.wasReferred === false}
      />

      {store.showRequiredError && (
        <div data-testid="error-sponsor-enroller-radios" className="invalid-feedback" style={{ display: 'block' }}>
          {t('RequiredError')}
        </div>
      )}
    </>
  ));
};
