import React from 'react';
import { observer } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { YlDropdown } from '../../../infrastructure/forms/YlDropdown';
import { getGenderOptions } from './GenderOptions';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

export const GenderEntry = observer(function GenderEntry() {
  const { t } = useTranslation();
  const genderEntry = useModuleStore('genderEntry');
  const form = genderEntry.reactForm;

  return (
    <Form>
      <Form.Row>
        <YlDropdown
          required={genderEntry.required}
          field={form.$.gender}
          onChange={value => genderEntry.setGender(value)}
          label="genderDropDownPlaceholder"
          id="gender"
          placeholder="genderDropDownPlaceholder"
          options={getGenderOptions(t)}
          labelFn={option => option.label}
          valueFn={option => option.value}
        />
      </Form.Row>
    </Form>
  );
});
