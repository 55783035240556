import { useEffect, FC } from 'react';
import { removeNode } from './utils';

const initialize = async (locale: string) => {
  // eslint-disable-next-line import/no-unresolved
  const { initializeChat } = await import('ylChat/YlChat');
  initializeChat(locale);
};

export interface Props {
  locale: string;
}

export const KustomerChat: FC<Props> = ({ locale }) => {
  useEffect(() => {
    initialize(locale);
    return () => {
      removeNode('#kustomer-ui-sdk-iframe');
      removeNode('script[data-kustomer-api-key]');
    };
  }, [locale]);
  return null;
};
