import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card, Col, Image } from 'react-bootstrap';
import { EnrollmentOrderItem } from './EnrollmentOrderInfoStore';

interface KitGridItemProps {
  item: EnrollmentOrderItem;
  showDetailLink?: boolean;
  customImage?: string;
}

export const UnavailableItemCard = observer(function KitGridItem({
  item,
  showDetailLink = false,
  customImage
}: KitGridItemProps) {
  const image = customImage || item.imageUrl;
  console.log(item);
  return (
    <>
      <Col key={item.partNumber} md={3} style={{ opacity: 0.33, marginBottom: '20px' }}>
        <Card style={{ minHeight: '340px' }}>
          <div className="text-left bg-secondary">
            <p className="text-white header-card-text m-1 h-56">{item.name}</p>
          </div>

          <Card.Body className="text-center">
            <span>
              <Image src={image} fluid />
            </span>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
});
