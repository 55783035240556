import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { HelpPopOver } from '../../../infrastructure/forms/help-tip-icon/HelpTipIcon';

interface Benefit {
  text: string;
  helpLabel?: string;
}

export const Benefits = observer(function Benefits() {
  const { t, i18n } = useTranslation();
  const getBenefits = (): Benefit[] => {
    const result: Benefit[] = [];

    let index = 1;
    let hasNextValue = false;
    do {
      const key = `erBenefit${index}`;
      if (i18n.exists(key) && t(key) !== null && t(key) !== '') {
        const helpKey = `erBenefitHelp${index}`;
        const helpLabel = i18n.exists(helpKey) && t(helpKey) !== '' ? helpKey : undefined;

        result.push({
          text: t(key),
          helpLabel
        });

        index++;
        hasNextValue = true;
      } else {
        hasNextValue = false;
      }
    } while (hasNextValue);

    return result;
  };

  const benefits = getBenefits();

  return (
    <>
      <h3>{t('EasyMonthlyAutoShipping')}</h3>
      <p>{t('EssentialRewardsDescription')}</p>
      <p>{t('EssentialRewardsIsFlexible')}</p>
      <ol className="ml-4">
        {benefits.map(benefit => (
          <li key={benefit.text}>
            <span>{benefit.text}</span>
            {benefit.helpLabel && (
              <span className="text-primary">
                <HelpPopOver label={benefit.helpLabel} />
              </span>
            )}
          </li>
        ))}
      </ol>
    </>
  );
});
