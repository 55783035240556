import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { ShareYoungLivingStore } from './ShareYoungLivingStore';
import { ModuleRenderer } from '../../enrollment-process/module-management/ModuleRenderer';

interface Props {
  store: ShareYoungLivingStore;
}

export const ShareYoungLivingDisplay: FC<Props> = ({ store }) => {
  const { t } = useTranslation();

  return useObserver(() => (
    <>
      {!store.isSharingYoungLiving! && <p>{t('NotRightNow')}</p>}
      {store.isSharingYoungLiving! && store.modules.map(module => <ModuleRenderer key={module} module={module} />)}
    </>
  ));
};
