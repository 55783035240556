import React, { FC } from 'react';
import Card from 'react-bootstrap/Card';
import { EnrollmentModule } from '../../enrollment-process/steps-management/model/LayoutModuleGroup';
import { ModuleRenderer } from '../../enrollment-process/module-management/ModuleRenderer';

export interface Props {
  modules: EnrollmentModule[];
}

export const ShareYoungLivingModules: FC<Props> = function LayoutModuleGroup({ modules }) {
  return (
    <>
      {modules.map(module => (
        <Card key={module} className="m-3" bg="light" border="light" body>
          <ModuleRenderer key={module} module={module} />
        </Card>
      ))}
    </>
  );
};
