import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Image } from 'react-bootstrap';
import { ProductKit } from '../order-selection/starter-kit-selection/model/ProductKit';
import { useModuleStore } from '../enrollment-process/module-management/model/ModuleStoreHook';
import { MoneyDisplay } from '../order-selection/starter-kit-selection/util-starter-kit/MoneyDisplay';

interface KitGridItemProps {
  kit: ProductKit;
  showDetailLink?: boolean;
  customImage?: string;
}

export const SocialLinkProductCard = observer<KitGridItemProps>(function KitGridItem({
  kit,
  showDetailLink = true,
  customImage
}) {
  const { t } = useTranslation();
  const store = useModuleStore('socialLinkEntry').enrollmentOrderInfo!;
  const image = customImage || kit.smallImageUrl;
  return (
    <>
      <Col key={kit.itemId} md={3} style={{ marginBottom: '20px' }}>
        <Card>
          <div className={`text-left bg-primary`}>
            <p className="text-white header-card-text m-1">{kit.title}</p>
          </div>

          <Card.Body className="text-center">
            <span>
              <Button variant="link" onClick={() => store.showProductInfo(kit)}>
                <Image src={image} fluid />
              </Button>
            </span>
            {showDetailLink && (
              <Button variant="link" onClick={() => store.showProductInfo(kit)}>
                <u>{t('SocialLinkProductCard_Details')}</u>
              </Button>
            )}
          </Card.Body>
        </Card>
        <strong>
          <span className="pr-1">Price: </span>
          <MoneyDisplay price={kit.price} currency={{ code: kit.currency.code, symbol: kit.currency.symbol }} />
        </strong>
      </Col>
    </>
  );
});
