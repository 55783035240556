import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { YlInput } from '../../../../../infrastructure/forms/YlInput';
import { TaxIdContext } from '../TaxIdContext';
import { MultipleBusinessTaxSelector } from './support/MultipleBusinessTaxSelector';

interface Props {
  context: TaxIdContext;
}

export const BusinessMultipleTaxEntry: FC<Props> = ({ context }) => {
  const [t] = useTranslation();
  return useObserver(() => {
    const business = (context.selectedEntry && context.selectedEntry.business) || context.entries[0].business!;
    const form = business.reactForm;
    const showTaxSelector = context.entries && context.entries.length > 1;
    return (
      <>
        <p>{t('taxHelpBusinessDescription')}</p>
        {showTaxSelector && <MultipleBusinessTaxSelector context={context} />}
        <YlInput
          field={form.$.taxId}
          required={business.requireBusinessLicense}
          label="taxBusinessTaxId"
          helpLabel="taxIdHelpText"
          onChange={() => business.fixDashes()}
          size={5}
        />
        {business.showBusinessName && (
          <YlInput
            field={form.$.businessName}
            required={business.requireBusinessName}
            label="taxBusinessNameOnly"
            size={5}
          />
        )}
      </>
    );
  });
};
