import React from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { InformationSectionHeading } from '../account-credentials/AccountCredentialEntry';
import { ShareYoungLivingOptionSelector } from './ShareYoungLivingOptionSelector';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

export const ShareYoungLiving = () => {
  const { t } = useTranslation();
  const shareStore = useModuleStore('shareYoungLivingEntry');

  return useObserver(() => {
    return (
      <>
        <InformationSectionHeading>{t('taxInformation')}</InformationSectionHeading>
        <ShareYoungLivingOptionSelector store={shareStore} />

        {shareStore.showRequiredError && (
          <div data-testid="error-share" className="invalid-feedback" style={{ display: 'block' }}>
            {t('RequiredError')}
          </div>
        )}
      </>
    );
  });
};
