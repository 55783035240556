import React from 'react';
import { useObserver } from 'mobx-react-lite';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import styles from '../order-review.module.scss';
import { Button } from 'react-bootstrap';
import { FaPencilAlt } from 'react-icons/fa';
import { useRootStore } from '../../root/RootStoreHooks';

export const ReferrerReview = () => {
  const { t } = useTranslation();
  const referrerStore = useModuleStore('referrerReview');
  const rootStore = useRootStore();

  return useObserver(() => {
    if (!referrerStore.hasReferrer) {
      return <></>;
    }

    return (
      <Card className={`${styles.card} mt-3`}>
        <Card.Header className={styles.cardHeader}>{t('Leadership')}</Card.Header>
        <Card.Body className="p-4">
          <Card.Title className="text-justify">{t('Sponsor')}</Card.Title>
          <Card.Text>
            {referrerStore.sponsorFullName} <span> (#{referrerStore.sponsorId})</span>
          </Card.Text>

          <Card.Title className="text-justify">{t('Enroller')}</Card.Title>
          <Card.Text>
            {referrerStore.enrollerFullName} <span> (#{referrerStore.enrollerId})</span>
          </Card.Text>
          {referrerStore.gaiyoshomenCode && (
            <>
              <Card.Title className="text-justify">{t('GaiyoshomenCode')}</Card.Title>
              <Card.Text>
                {referrerStore.gaiyoshomenCode}
              </Card.Text>
            </>
          )}
          <Button variant="link" onClick={() => rootStore.stepsManager.goToStep(referrerStore.editStepNumber)}>
            <FaPencilAlt size={12} />
            {t('EditInformation')}
          </Button>
        </Card.Body>
      </Card>
    );
  });
};
