import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';

import styles from './StepProgress.module.scss';
import { StepsManagerContext } from '../../StepsManager';

export const StepProgress = () => {
  const { stepsManager } = useContext(StepsManagerContext)!;
  const { t } = useTranslation();

  return useObserver(() => (
    <ul className={styles.multiSteps}>
      {stepsManager.steps
        .filter(step => step.label !== 'ReEnterPaymentStep')
        .map((step, index) => (
          <li key={step.id} className={stepsManager.currentStep.id === index + 1 ? styles.isActive : ''}>
            <span
              className={
                stepsManager.currentStep.id === index + 1
                  ? `${styles.textActive} ${styles.textSteps}`
                  : `${styles.textSteps}`
              }
            >
              {t(step.label)}
            </span>
          </li>
        ))}
    </ul>
  ));
};
