import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { useRootStore } from '../../root/RootStoreHooks';
import { SocialLinkCreatedModal } from '../../social-link/creator-mode/SocialLinkCreatedModal';

export const CreateSocialLinkButton = () => {
  const rootStore = useRootStore();
  const store = rootStore.moduleStores.socialLinkCreatorEntry!;
  const { t } = useTranslation();
  return useObserver(() => {
    return (
      <>
        <Button
          block
          className="btn btn-warning"
          onClick={() => store.createSocialLink()}
          //  variant="outline-primary"
        >
          {t('CreateSocialLinkButton_CreateSocialLink')}
        </Button>
        <SocialLinkCreatedModal show={store.isSocialLinkCreated} />
      </>
    );
  });
};
