import React from 'react';
import { observer } from 'mobx-react-lite';
import { KitSelector } from '../tab-content/StarterKitSelection';
import { EnrollmentKitSelectionStore } from '../model/EnrollmentKitStore';

interface StarterKitTabLayoutProps {
  store: EnrollmentKitSelectionStore;
}

export const StarterKitStandardLayout = observer(function StarterKitTabLayout({ store }: StarterKitTabLayoutProps) {
  return (
    <div>
      <KitSelector />
    </div>
  );
});
