import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../root/RootStoreHooks';

interface Props {
  show: boolean;
}

export const SocialLinkExpiredModal: FC<Props> = ({ show }) => {
  const { t } = useTranslation();
  const rootStore = useRootStore();

  return useObserver(() => (
    <Modal className="social-link-expired-modal" show={show} keyboard={false} backdrop="static" role="dialog">
      <Modal.Header closeButton>
        <Modal.Title>{t('SocialLinkExpiredModal_SocialLinkHasExpired')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{t('SocialLinkExpiredModal_SocialLinkHasExpiredContent')}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'outline-secondary'}
          onClick={() =>
            rootStore.cancelEnrollment(
              false,
              rootStore.enrollmentStatus.countryIso,
              rootStore.localeManager.language.locale
            )
          }
        >
          {t('SocialLinkExpiredModal_Ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  ));
};
