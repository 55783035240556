import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { TabsInformationMobile } from './TabsInformationMobile';
import { TabsInformationDesktop } from './TabsInformationDesktop';
import { ProductInfoStore } from '../model/ProductInfoStore';
import './TabsInformation.scss';
import { ProductInfoErKitStore } from '../model/ProductInfoErKitStore';

interface Props {
  isMobile: boolean;
  product: ProductInfoStore | ProductInfoErKitStore;
}

export const TabsInformationSelector: FC<Props> = observer(function TabsInformationSelector({ isMobile, product }) {
  return isMobile ? <TabsInformationMobile product={product} /> : <TabsInformationDesktop product={product} />;
});
