import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ProductKit } from '../../model/ProductKit';
import { Currency } from '../../../../../reference/Currency';
import { CardHeader } from './StarterKitCardHeader';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';

interface DynamicKitPriceDisplayProps {
  selectedKit?: ProductKit;
  dynamicKitPrice: { price: number; currency: Currency };
  kit: ProductKit;
}

const DynamicKitPriceDisplay: FC<DynamicKitPriceDisplayProps> = observer(function DynamicKitPriceDisplay({
  kit,
  selectedKit,
  dynamicKitPrice
}) {
  const { t } = useTranslation();
  const isCurrentKit = kit === selectedKit;
  const priceDifference = Math.abs(kit.price - dynamicKitPrice.price) / 100;
  const sign = kit.price >= dynamicKitPrice.price ? '+' : '-';
  const formattedPrice = `${sign}${kit.currency.symbol}${priceDifference.toFixed(2)}${kit.currency.code}`;
  const priceToUse = isCurrentKit ? t('Included') : formattedPrice;
  return <p className="text-right text-white pr-2">{priceToUse}</p>;
});

interface CardHeaderProps {
  kit: ProductKit;
}

export const StarterKitPopularCardHeader = observer(function StarterKitPopularCardHeader({ kit }: CardHeaderProps) {
  const store = useModuleStore('enrollmentKitSelection');
  const checked = store.selectedKit === kit;

  return (
    <CardHeader onClick={() => store.choseKit(kit)} isSelected={checked}>
      <DynamicKitPriceDisplay
        selectedKit={store.selectedKit}
        dynamicKitPrice={store.currentTab.dynamicKitPrice}
        kit={kit}
      />
    </CardHeader>
  );
});
