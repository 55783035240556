import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import reduce from 'lodash/reduce';
import './MeoCartSummary.scss';

import { saveStepAndGoToCustomize } from '../add-products/ProductRedirector';
import { useRootStore } from '../../root/RootStoreHooks';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { MoneyDisplay } from '../../order-selection/starter-kit-selection/util-starter-kit/MoneyDisplay';

export const MeoCartSummary: FC = observer(() => {
  const { t } = useTranslation();
  const store = useRootStore();
  const root = useRootStore();
  const MeoCartItemSummaryStore = root.moduleStores.meoKitSelection!;

  function handleClick() {
    saveStepAndGoToCustomize(store);
  }

  function getCartPvSubtotal() {
    return reduce(MeoCartItemSummaryStore.meoCartItems, (sum, item) => sum + item.quantity * item.itemPV, 0);
  }

  function getCartPriceSubtotal() {
    return reduce(MeoCartItemSummaryStore.meoCartItems, (sum, item) => sum + item.quantity * item.price, 0);
  }

  function getCartLength() {
    return MeoCartItemSummaryStore.meoCartItems.length;
  }

  return (
    <>
      {getCartLength() > 0 ? (
        <div className="cartSummary">
          <Container fluid>
            <Row as="section" className="cartHeader">
              <Col md={4}>{t('BuildMeoHeaderProduct')}</Col>
              <Col md={1}>{t('BuildMeoHeaderQuantity')}</Col>
              <Col md={1}>{t('BuildMeoHeaderItemPV')}</Col>
              <Col md={2}>{t('BuildMeoHeaderPrice')}</Col>
              <Col md={2}>{t('BuildMeoHeaderPVSubtotal')}</Col>
              <Col md={2}>{t('Subtotal')}</Col>
            </Row>
          </Container>
          <hr />
          <Container>
            {MeoCartItemSummaryStore.meoCartItems.map(item => (
              <Row as="section" className="cartItem" key={item.partNumber}>
                <Col md={4}>{item.name}</Col>
                <Col md={1}>{item.quantity}</Col>
                <Col md={1}>
                  <MoneyDisplay currency={{ symbol: '', code: '' }} price={item.itemPV} />
                </Col>
                <Col md={2}>
                  <MoneyDisplay currency={item.currency} price={item.price} />
                </Col>
                <Col md={2}>
                  <MoneyDisplay currency={{ symbol: '', code: '' }} price={item.itemPV * item.quantity} />
                </Col>
                <Col md={2}>
                  <MoneyDisplay currency={item.currency} price={item.price * item.quantity} />
                </Col>
              </Row>
            ))}
          </Container>
          <Container>
            <Row as="section" className="cartFooter">
              <Col md={8} className="text-right">
                {t('BuildMeoHeaderSubtotals')}
              </Col>
              <Col md={2}>
                <MoneyDisplay currency={{ symbol: '', code: '' }} price={getCartPvSubtotal()} />
              </Col>
              <Col md={2}>
                <MoneyDisplay
                  currency={MeoCartItemSummaryStore.meoCartItems[0].currency}
                  price={getCartPriceSubtotal()}
                />
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <></>
      )}
      <div className="d-flex align-items-end flex-column mt-4 mb-4">
        <Button variant="primary" onClick={() => handleClick()}>
          {getCartLength() > 0 ? t('BuildMeoEditButton') : t('BuildMeoButton')}
        </Button>
      </div>
    </>
  );
});
