import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Card, Form } from 'react-bootstrap';

import { AddressEntryFormData } from '../../../../address/main-address/AddressEntryFormData';
import { ComponentField } from '../../../../../infrastructure/dynamic-forms/ComponentField';
import { AddressRow } from '../../../../address/main-address/module-form/AddressRow';
import { useRootStore } from '../../../../root/RootStoreHooks';
import { AddressEntryType, YlAddress } from '../../../../../infrastructure/forms/YlAddress';

export interface AddressDisplayProps {
  address: AddressEntryFormData;
}

export const BillingAddressDisplay = observer<AddressDisplayProps>(({ address }) => {
  const { t } = useTranslation();
  return (
    <Card bg="light" border="light" style={{ width: '21rem' }}>
      <Card.Body>
        <Card.Title>{t('BillingAddress')}</Card.Title>
        <div>
          <div>{address.street1}</div>
          {address.street2 && <div>{address.street2}</div>}
          {address.street3 && <div>{address.street3}</div>}
          {address.city && <span> {address.city}</span>}
          {address.stateProv && <span> {address.stateProv}</span>}
          {address.postalCode && <span>, {address.postalCode}</span>}
          <div> {address.country}</div>
        </div>
      </Card.Body>
    </Card>
  );
});

export interface BillingAddressProps {
  disabled: boolean;
}

export const BillingAddressEntry = observer<BillingAddressProps>(({ disabled }) => {
  const { t } = useTranslation();
  const { billingEntry } = useRootStore().moduleStores;
  const store = billingEntry!.billingAddressEntry!;

  function getCustomerAttributes(field: ComponentField) {
    const attr = {} as any;
    if (field.maxLength) {
      attr.maxLength = field.maxLength;
    }

    if (field.minLength) {
      attr.minLength = field.minLength;
    }

    return attr;
  }

  return (
    <>
      <Form.Group>
        <Form.Check
          id="sameAsPrimary"
          custom
          label={t('BillingAddressSameAsShipping')}
          type="checkbox"
          checked={store.sameAsShippingAddress}
          disabled={disabled}
          onChange={() => store.toggleSameAsShippingAddress()}
        />
      </Form.Group>
      {store.sameAsShippingAddress && billingEntry!.userShippingAddress && (
        <Form.Group>
          <BillingAddressDisplay address={billingEntry!.userShippingAddress} />
        </Form.Group>
      )}
      {!store.sameAsShippingAddress &&
        store.form.rows.map((row: AddressRow, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <YlAddress row={row}
                      index={index}
                      form={store.reactForm}
                      entryComponent={store}
                      entryType={AddressEntryType.BillingEntry}
                      countryIso={store.reactForm.$.country.value}
                      />
        ))}
    </>
  );
});
