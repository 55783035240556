import React, { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../../../../../root/RootStoreHooks';


interface Props {
  isOpen: boolean | undefined;
  onClose: () => void;
}

export const NeedToHaveFirstOrderModal: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const rootStore = useRootStore();

  return (
    <Modal show={isOpen}>
      <Modal.Header closeButton onClick={() => onClose()}>
        <Modal.Title>{t('NeedToHaveFirstOrderModal_Title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('NeedToHaveFirstOrderModal_Body')}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onClose()}>
          {t('NeedToHaveFirstOrderModal_OK')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
