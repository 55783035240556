import { useTranslation } from 'react-i18next';
import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { FaCaretRight, FaCaretDown } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import styles from '../order-review.module.scss';
import { HelpPopOver } from '../../../infrastructure/forms/help-tip-icon/HelpTipIcon';
import { MoneyDisplay } from '../../order-selection/starter-kit-selection/util-starter-kit/MoneyDisplay';
import { ListPrice } from '../util/ListPrice';
import { OrderTotalTaxCard } from '../util/OrderTotalTaxCard';
import { useRootStore } from '../../root/RootStoreHooks';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

export const OrderTotalReview = () => {
  const { t } = useTranslation();

  const rootStore = useRootStore();
  const orderReviewStore = useModuleStore('orderTotalReview');

  return useObserver(() => (
    <div className="p-3 mt-3 bg-light">
      <ListPrice priceList={orderReviewStore.subtotal} currency={rootStore.reference.currencies[0]} label="Subtotal" />
      <ListPrice priceList={orderReviewStore.shipping} currency={rootStore.reference.currencies[0]} label="Shipping" />
      <div className="d-flex">
        <Button
          variant="link"
          className="mr-auto p-0"
          onClick={() => orderReviewStore.switchTabTaxDefinition(!orderReviewStore.activeTaxTab)}
        >
          {t('TaxTotal')}{' '}
          {orderReviewStore.activeTaxTab === true ? (
            <FaCaretDown className={styles.mainColor} />
          ) : (
            <FaCaretRight className={styles.mainColor} />
          )}
        </Button>

        <span>
          <MoneyDisplay price={orderReviewStore.taxTotal} currency={rootStore.reference.currencies[0]} />
        </span>
      </div>
      {orderReviewStore.activeTaxTab === true ? <OrderTotalTaxCard styles={styles} store={orderReviewStore} /> : ''}
      <hr />

      <div>
        <h5>
          <ListPrice priceList={orderReviewStore.total} currency={rootStore.reference.currencies[0]} label="Total" />
        </h5>
      </div>

      <div className="d-flex">
        <span className="mr-auto">
          {t('PVTotal')} <HelpPopOver label="PvDescription" />
        </span>

        <span>
          <MoneyDisplay price={orderReviewStore.pvTotal} currency={{ symbol: '', code: '' }} />
        </span>
      </div>
    </div>
  ));
};
