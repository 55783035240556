import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { YlInput } from '../../../../../infrastructure/forms/YlInput';
import { RfcCheckLink } from './support/RfcCheckLink';
import { TaxIdContext } from '../TaxIdContext';

interface Props {
  context: TaxIdContext;
}

export const BusinessMexicoTaxEntry: FC<Props> = ({ context }) => {
  const [t] = useTranslation();
  return useObserver(() => {
    const business = context.selectedEntry!.business!;
    const form = business.reactForm;
    return (
      <>
        <p>{t('taxHelpIndividualDescription')}</p>
        <YlInput
          field={form.$.taxId}
          required={business.requireBusinessLicense}
          label="rfcLabel"
          helpLabel={RfcCheckLink}
          onChange={() => business.fixDashes()}
        />
        {business.showBusinessName && (
          <YlInput field={form.$.businessName} required={business.requireBusinessName} label="taxBusinessNameOnly" />
        )}
      </>
    );
  });
};
