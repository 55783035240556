import React from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';

export const CashOnDeliveryOption = () => {
  const { t } = useTranslation();
  return useObserver(() => (
    <>
        {t('CashOnDeliveryInfo')}
    </>
  ));
};
