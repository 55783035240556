import React from 'react';
import { observer } from 'mobx-react-lite';
import { MoneyDisplay } from './MoneyDisplay';
import { ProductKit } from '../../starter-kit-selection/model/ProductKit';

interface Props {
  kit: ProductKit;
}

export const KitPriceDisplay = observer(({ kit }: Props) => {
  return (
    <p className="text-white pr-2 pt-2">
      <MoneyDisplay price={kit.price} code={kit.currency.code} />
    </p>
  );
});
