import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { BillingEntryStore } from '../../model/BillingEntryStore';
import { PaymentInstallmentDropdown } from './PaymentInstallmentDropdown';

interface Props {
  billingStore: BillingEntryStore;
}

export const PaymentInstallmentModal = observer<Props>(({ billingStore }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={billingStore.installmentEntry.showInstallmentsModal}
      onHide={() => billingStore.installmentEntry.setShowInstallmentsModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('InstallmentsWarningDialogTittle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{t('InstallmentsWarningDialogBody')}</span>
        <div className="mt-4">
          <PaymentInstallmentDropdown keyAsLabel />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => billingStore.continueNextStep()}>
          {t('Continue')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
