import React from 'react';
import { PopOverLink } from '../../../../../../infrastructure/forms/help-tip-icon/HelpTipIcon';

export const RfcCheckLink = (
  <PopOverLink
    helpLabel="taxIdIndividualFormatHelpText"
    linkLabel="validateRfcLabel"
    className="ml-3"
    href="https://agsc.siat.sat.gob.mx/PTSC/ValidaRFC/"
    rel="noopener noreferrer"
    target="_blank"
  />
);
