import React from 'react';

export function generateDays(size: number, startAt: number) {
  const days: number[] = [];
  for (let i = startAt; i <= size; i++) {
    days.push(i);
  }
  return days;
}

interface DaysOptionsProps {
  size?: number;
  startAt?: number;
}

export function DayOptions({ size = 31, startAt = 1 }: DaysOptionsProps) {
  return (
    <>
      {generateDays(size, startAt).map(val => {
        return (
          <option key={val} value={val}>
            {val}
          </option>
        );
      })}
    </>
  );
}
