import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';

import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import { ShareYoungLivingStore } from './ShareYoungLivingStore';
import { ShareYoungLivingModules } from './ShareYoungLivingModules';
import { useModuleDisplayMode } from '../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';
import { ShareYoungLivingDisplay } from './ShareYoungLivingDisplay';

interface Props {
  store: ShareYoungLivingStore;
}

export const ShareYoungLivingOptionSelector: FC<Props> = ({ store }) => {
  const { t } = useTranslation();
  const displayMode = useModuleDisplayMode();
  return useObserver(() => {
    if (displayMode) {
      return <ShareYoungLivingDisplay store={store} />;
    }

    return (
      <>
        <div>{t('sharingYoungLivingDescription')}</div>
        <Card body border="light">
          <Form.Check
            custom
            id="yesShare"
            label={t('yesShareYoungLivingDescription')}
            type="checkbox"
            onChange={() => store.selectOption(true)}
            checked={store.isSharingYoungLiving !== undefined && store.isSharingYoungLiving}
          />

          {store.isSharingYoungLiving && <ShareYoungLivingModules modules={store.modules} />}
        </Card>
        <Card className="mt-4" border="light" body>
          <Form.Check
            custom
            id="noShare"
            label={t('noShareYounglivingDescription')}
            type="checkbox"
            onChange={() => store.selectOption(false)}
            checked={store.isSharingYoungLiving !== undefined && !store.isSharingYoungLiving}
          />
        </Card>
      </>
    );
  });
};
