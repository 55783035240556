import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { ProductKit } from '../../model/ProductKit';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';

interface CardHeaderProps {
  isSelected: boolean;
  onClick: () => void;
  alwaysPrimary?: boolean;
  children: ReactNode;
  showCheckbox?: boolean;
}

export const CardHeader: FC<CardHeaderProps> = observer(function CardHeader({
  isSelected,
  onClick,
  alwaysPrimary = false,
  children,
  showCheckbox = true
}) {
  return (
    <div onClick={onClick} className={`text-left ${isSelected || alwaysPrimary ? 'bg-primary' : 'bg-secondary'}`}>
      <div className="container-checkbox kit-grid-item mt-2 ml-2 mb-3">
        <input type="checkbox" className="mt-2 mr-2" readOnly checked={isSelected} />
        <span className="checkmark" />
        {children}
      </div>
    </div>
  );
});

interface StarterKitCardHeaderProps {
  kit: ProductKit;
}

export const StarterKitCardHeader: FC<StarterKitCardHeaderProps> = observer(function StarterKitCardHeader({ kit }) {
  const store = useModuleStore('enrollmentKitSelection');
  const checked = store.selectedKit === kit;

  return (
    <CardHeader onClick={() => store.choseKit(kit)} isSelected={checked}>
      <p className="text-white header-card-text m-1">{kit.title}</p>
    </CardHeader>
  );
});
