import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { AddressValidator } from './AddressValidator';
import { NoRecommendationModal } from './NoRecommendationModal';
import { AddressRecommendationModal } from './AddressRecommendationModal';

interface Props {
  store: AddressValidator;
}

export const AddressModal: FC<Props> = ({ store }) => {
  return useObserver(() => {
    if (!store.recommendations) {
      return <></>;
    }
    const recommendation = store.recommendations;
    if (recommendation.recommendations.length > 0) {
      return <AddressRecommendationModal store={store} />;
    }
    return <NoRecommendationModal store={store} />;
  });
};
