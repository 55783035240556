import React, { FC } from 'react';
import { observer, useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import { StarterKitTab } from '../../model/StarterKitTab';
import './PopularKitTab.scss';
import { PopularKitMobileTab } from '../standard-layout/PopularKitMobileTab';
import { ProductKitIncludes } from './ProductKitIncludes';
import { useRootStore } from '../../../../root/RootStoreHooks';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BaseKitCard } from './dynamic-view/BaseKitCard';
import { DynamicKitChoices } from './dynamic-view/DynamicKitChoices';

interface PopularKitTabProps {
  tab: StarterKitTab;
}

export const PopularKitDesktopTab: FC<PopularKitTabProps> = ({ tab }) => {
  const store = useModuleStore('enrollmentKitSelection');
  const { t } = useTranslation();

  return useObserver(() => (
    <>
      <h4 className="text-secondary">{t('ChooseYourDiffuser')}</h4>
      <p>{t('Customize')}</p>
      <Row>
        <BaseKitCard store={store} tab={tab} />
        <DynamicKitChoices kits={tab.productKits} />
      </Row>
      <Row>
        <ProductKitIncludes productKit={store.selectedKit} />
      </Row>
    </>
  ));
};

export const PopularKitTab: FC<PopularKitTabProps> = observer(({ tab }) => {
  const store = useRootStore();
  return store.isMobileSized ? <PopularKitMobileTab tab={tab} /> : <PopularKitDesktopTab tab={tab} />;
});
