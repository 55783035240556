import React, { FC, useContext } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { StepsManagerContext } from '../../StepsManager';
import { ModuleRenderer } from '../../../../module-management/ModuleRenderer';
import { useRootStore } from '../../../../../root/RootStoreHooks';
import { NeedToHaveFirstOrderModal } from './NeedToHaveFirstOrderModal';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const StandardStepNavigator: FC<Props> = () => {
  const { stepsManager } = useContext(StepsManagerContext)!;
  const rootStore = useRootStore();

  const { t } = useTranslation();

  return useObserver(() => {
    const step = stepsManager.currentStep;
    const nav = step.navigation;

    if (nav.hideNavigation === true && !rootStore.moduleStores.canProceed) {
      return <></>;
    }
    return (
      <Row className={`mt-1 mb-4 justify-content-${nav.justify}`}>
        <Col md="auto" className="mt-3">
          {!nav.previousStepModule && !stepsManager.isFirstStep && (
            <Button block variant="outline-primary" onClick={() => stepsManager.previousStep()}>
              {t(nav.previousLabel)}
            </Button>
          )}
          {nav.previousStepModule && <ModuleRenderer module={nav.previousStepModule} />}
        </Col>
        
        <Col md="auto" className="mt-3">
          <NeedToHaveFirstOrderModal isOpen={!rootStore.stepsManager.canContinue} onClose={() => rootStore.stepsManager.resetCanProceed()} />
          {!nav.nextStepModule && !stepsManager.isAccordionLayout && (
            <Button
              block
              variant="primary"
              onClick={() =>
                stepsManager.isPaymentFailureLayout
                  ? stepsManager.goToStep(stepsManager.currentStepNumber)
                  : stepsManager.nextStep()
              }
            >
              {t(nav.nextLabel)}
            </Button>
          )}
          {nav.nextStepModule && <ModuleRenderer module={nav.nextStepModule} />}
        </Col>
      </Row>
    );
  });
};
