import React, { useLayoutEffect } from 'react';
import scriptLoader from 'react-async-script-loader';
import { enrollmentResource } from '../../../../infrastructure/http/EnrollmentResource';
import './PayPal.scss';
import { PaypalButtonPlaceholder } from './PayPalButtonPlaceholder';

declare const paypal: any;
async function retrievePaypalTokenFromServer(): Promise<string> {
  const response = await enrollmentResource.get<any>('paypal/express-checkout-token', {
    params: {
      returnUrl: 'https://127.0.0.1',
      cancelUrl: 'https://127.0.0.1'
    }
  });

  return response.data;
}
export function createPaypalButtonOptions(environment: string, onSelected: (token: string) => void, memberCountry?: string): any { 
  function getAllowedFunding(): string[] {
    if (memberCountry !== 'SG' && memberCountry !== 'HK') {
      return [];
    }
    return ["credit"]
  }
  function getDisAllowedFunding(): string[] {
    if (memberCountry !== 'SG' && memberCountry !== 'HK') {
      return [];
    }
    return ["card"];
  }
  return {
    env: environment,
    payment: async (resolve: any, reject: any) => {
      try {
        const token = await retrievePaypalTokenFromServer();
        resolve(token);
      } catch {
        reject();
      }
    },
    funding: {
      allowed: getAllowedFunding(),
      disallowed: getDisAllowedFunding()
    },
    onAuthorize: async (data: any) => {
      await enrollmentResource.post('paypal/add-payment', { token: data.billingToken });
      onSelected(data.token);
    }
  };
}

interface PayPalButtonProps {
  isScriptLoaded: boolean;
  isScriptLoadSucceed: boolean;
  onSelected: (token: string) => void;
  productionMode: boolean;
  memberCountry: string;
}

export const PaypalButton = scriptLoader('https://www.paypalobjects.com/api/checkout.js')(function PaypalButton({
  isScriptLoaded,
  isScriptLoadSucceed,
  onSelected,
  productionMode,
  memberCountry
}: PayPalButtonProps) {
  const paypalReady = isScriptLoaded && isScriptLoadSucceed;

  useLayoutEffect(() => {
    if (paypalReady) {
      const env = productionMode ? 'production' : 'sandbox';
      paypal.Button.render(createPaypalButtonOptions(env, onSelected, memberCountry), '#paypalButton');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paypalReady]);

  return (
    <div className="mt-2" id="paypalButton" data-testid="paypalButton">
      <PaypalButtonPlaceholder />
    </div>
  );
});


