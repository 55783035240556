import { useEffect, FC } from 'react';
import { removeNode } from './utils';

const initialize = async (locale: string) => {
  // eslint-disable-next-line import/no-unresolved
  const { initializeChat } = await import('yl-five9-chat/YlFive9Chat');
  initializeChat({ locale });
};

export interface Props {
  locale: string;
}

export const Five9Chat: FC<Props> = ({ locale }) => {
  useEffect(() => {
    initialize(locale);
    return () => {
      removeNode('.five9-frame');
    };
  }, [locale]);
  return null;
};
