import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { InformationSectionHeading } from '../account-credentials/AccountCredentialEntry';
import { YlCheckBox } from '../../../infrastructure/forms/YlCheckBox';
import { useModuleDisplayMode } from '../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

export const DirectMarketingAgreementEntry: FC = () => {
  const { t } = useTranslation();
  const store = useModuleStore('directMarketingAgreementEntry');
  const displayMode = useModuleDisplayMode();

  return useObserver(() => {
    const form = store.reactForm;

    if (displayMode) {
      return <p>{t('directMarketingConsentAgreementText')}</p>;
    }

    return (
      <div className="mt-4">
        <InformationSectionHeading>{t('consentForDirectMarketingTitle')}</InformationSectionHeading>
        <ul className="ml-4">
          <li key="directMarketingConsentAgreementUrl">
            <a href={t('directMarketingConsentAgreementUrl')} target="_blank" rel="noopener noreferrer">
              {t('directMarketingConsentAgreementLink')}
            </a>
          </li>
        </ul>
        <Form noValidate>
          <Form.Row>
            <YlCheckBox
              size={12}
              field={form.$.agreementAccepted}
              onChange={() => store.setAgreementAccepted(form.$.agreementAccepted)}
              label="directMarketingConsentAgreementText"
              id="directMarketingConsentAgreement"
            />
          </Form.Row>
        </Form>
      </div>
    );
  });
};
