import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { saveStepAndGoToCustomize } from './ProductRedirector';
import { useRootStore } from '../../root/RootStoreHooks';

export const AddProducts = () => {
  const rootStore = useRootStore();
  const { t } = useTranslation();

  const enrollmentKitSelection = rootStore.moduleStores.enrollmentKitSelection!;
  const isMeoEnabled = enrollmentKitSelection?.isMeoEnabled;

  return useObserver(() => {
    if (isMeoEnabled) {
      return <></>;
    } else {
      return (
        <Button block onClick={() => saveStepAndGoToCustomize(rootStore)} variant="outline-primary">
          {t('OptionalAddAdditionalProducts')}
        </Button>
      );
    }
  });
};

