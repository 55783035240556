import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const ReviewTopBanner = () => {
  const { t } = useTranslation();

  return useObserver(() => {

    return (
    <div>
      {t('ReviewPageTopBannerValue')}
    </div>
    );
  });
};
