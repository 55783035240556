import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import Button from 'react-bootstrap/Button';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

export const ActivateButton: FC = () => {
  const { t } = useTranslation();
  const completeEnrollmentProcessor = useModuleStore('completeEnrollmentProcessor');

  return useObserver(() => (
    <Button
      block
      disabled={completeEnrollmentProcessor.activationInProgress}
      onClick={() => completeEnrollmentProcessor.activateAndCheckout()}
      variant="primary"
    >
      {t('ActivateMembership')}
    </Button>
  ));
};
