import React, { FC, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Container from 'react-bootstrap/Container';
import { StandardStepNavigator } from '../step-layouts/standard/StandardStepNavigator';
import { TwoColumnStepLayout } from '../step-layouts/two-column/TwoColumnStepLayout';
import { EnrollmentStep } from '../../model/EnrollmentStep';
import { StepProgress } from '../step-layouts/standard/StepProgress';
import { StandardStepLayout } from '../step-layouts/standard/StandardStepLayout';
import { ReviewPageStepLayout } from '../step-layouts/review-page/ReviewPageStepLayout';
import { AccordionStepLayout } from '../step-layouts/accordion/AccordionStepLayout';
import { PaymentFailureLayout } from '../step-layouts/payment-failure/PaymentFailureLayout';
import { useRootStore } from '../../../../root/RootStoreHooks';
import { Welcome } from '../../../../layout/Welcome/Welcome';
import { StepsManagerContext } from '../StepsManager';
import { CookieWarningModal } from '../../../../cookie-warning/CookieWarningModal';
import { CookieWarningModalStore } from '../../../../cookie-warning/CookieWarningModalStore';
import { GlobalNfrBanner } from '../../../../global-nfr/GlobalNfrBanner';
import { StepLayout } from '../../../../../external/shared/api/EnrollmentClient.generated';
import { WarningBanner } from '../../../../../external/shared/banner/WarningBanner'; 

interface StepRendererProps {
  step: EnrollmentStep;
}

export const StepRenderer: FC<StepRendererProps> = observer(function StepRenderer({ step }) {
  switch (step.layout) {
    case StepLayout.Standard:
      return <StandardStepLayout step={step} />;
    case StepLayout.TwoColumn:
      return <TwoColumnStepLayout step={step} />;
    case StepLayout.ReviewPage:
      return <ReviewPageStepLayout step={step} />;
    case StepLayout.Accordion:
      return <AccordionStepLayout step={step} />;
    case StepLayout.PaymentFailure:
      return <PaymentFailureLayout step={step} />;

    default:
      throw new Error(`Unknown step layout: ${step.layout}`);
  }
}); 
export const StandardPageSteps = observer(function StandardPageSteps() {
  const { moduleStores } = useRootStore();
  const { stepsManager } = useContext(StepsManagerContext)!;
  const scrollToTop = () => window.scrollTo(0, 0);
  useEffect(() => scrollToTop, []); 
  return (
    <Container>
      {moduleStores.isGlobalNfr && <GlobalNfrBanner />}
      {moduleStores.showWarning && <WarningBanner label = "MongoliaDisclaimerMessage"/>}
      {moduleStores.showWelcome && stepsManager.isFirstStep && !moduleStores.socialLinkCreatorEntry && <Welcome />}

      {moduleStores.showCookieWarning && (
        <CookieWarningModal store={CookieWarningModalStore.create({ showModal: true })} /> 

      )} 

      <StepProgress />
      {stepsManager.stepLoaded && (
        <>
          <StepRenderer step={stepsManager.currentStep} />
          <StandardStepNavigator />
        </>
      )}
    </Container>
  );
});
