/* eslint-disable */
import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { FieldState } from 'formstate';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';

interface YlConfirmCheckBoxProps {
  field: FieldState<any>;
  label: string;
  id?: string;
  required?: boolean;
}
export const ConfirmCheckBox: FC<YlConfirmCheckBoxProps> = ({ field, label, id, required = true }) => {
  const { t } = useTranslation();

  return useObserver(() => (
    <Form.Group controlId={id}>
      <Form.Check
        custom
        type="checkbox"
        checked={field.value}
        onChange={(e: any) => field.onChange(!field.value)}
        isInvalid={field.hasError}
        onBlur={field.enableAutoValidationAndValidate}
        required={required}
        label={t(label)}
      />

      {field.hasError && (
        <div data-testid={`error-${id}`} className="invalid-feedback" style={{ display: 'block' }}>
          {t(field.error!)}
        </div>
      )}
    </Form.Group>
  ));
};
