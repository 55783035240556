import { useTranslation } from 'react-i18next';
import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { InformationSectionHeading } from '../../account-credentials/AccountCredentialEntry';
import { TaxIdContextSelector } from '../tax-context-types/TaxIdContextSelector';
import { useModuleStore } from '../../../enrollment-process/module-management/model/ModuleStoreHook';
import { TaxInformation } from '../TaxInformation';
import { useModuleDisplayMode } from '../../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';
import { RadioLessTaxIdEntryDisplay } from './RadioLessTaxIdEntryDisplay';
import { TaxInformationDescription } from '../TaxInformationDescription';

export const StandardTaxIdEntry = () => {
  const { t, i18n } = useTranslation();
  const taxIdEntry = useModuleStore('taxIdEntry');
  const displayMode = useModuleDisplayMode();
  const showTaxInformation = i18n.exists('taxInformationPopupDescription2a');

  return useObserver(() => {
    if (displayMode) {
      return <RadioLessTaxIdEntryDisplay store={taxIdEntry} />;
    }

    return (
      <div className="mt-4">
        <InformationSectionHeading>{t('taxInformation')}</InformationSectionHeading>
        <TaxInformationDescription />
        <TaxIdContextSelector store={taxIdEntry} />
        {taxIdEntry.showRequiredError && (
          <div data-testid="error-tax-id-radios" className="invalid-feedback" style={{ display: 'block' }}>
            {t('RequiredError')}
          </div>
        )}
        {showTaxInformation && <TaxInformation />}
      </div>
    );
  });
};
