import { observer } from 'mobx-react-lite';
import { Col } from 'react-bootstrap';
import React, { FC } from 'react';

export const KitGridItemDesktop: FC = observer(function KitGridItemDesktop({ children }) {
  const cardStyle = { maxWidth: '350px' };
  return (
    <Col className="mt-2 mb-2" style={cardStyle}>
      {children}
    </Col>
  );
});
