import React from 'react';
import { observer } from 'mobx-react-lite';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import { StarterKitTabContent } from '../tab-content/StarterKitTabContent';
import { useModuleStore } from '../../../enrollment-process/module-management/model/ModuleStoreHook';
import { useRootStore } from '../../../root/RootStoreHooks';

export const DesktopEnrollmentKitTabs = observer(function DesktopEnrollmentKitTabs() {
  const { t } = useTranslation();
  const store = useModuleStore('enrollmentKitSelection');
  const rootStore = useRootStore();

  let tabs;

  if (rootStore.moduleStores.socialLinkCreatorEntry) {
    tabs = store.tabs.filter(x => x.tabType === 'Premium');
  } else {
    tabs = store.tabs;
  }

  return (
    <Tabs
      id="enrollment-kit-tabs"
      activeKey={store.currentTab!.id}
      onSelect={tab => {
        if (tab) {
          store.switchTab(Number(tab));
        }
      }}
      mountOnEnter
      unmountOnExit
    >
      {tabs.map((tab: any) => (
        <Tab key={tab.id} title={t(tab.labelKey)} eventKey={tab.id}>
          <Container className="mt-3">
            <StarterKitTabContent />
          </Container>
        </Tab>
      ))}
    </Tabs>
  );
});
