import React from 'react';
import { FieldState } from 'formstate';
import { observer } from 'mobx-react-lite';
import { Form, InputGroup, Spinner } from 'react-bootstrap';

import { YlFormGroup } from './YlFormGroup';

export interface YlSelectProps {
  field: FieldState<any>;
  type?: string;
  label: string | (() => string);
  labelSize?: 'small' | 'normal';
  id?: string;
  required?: boolean;
  customAttributes?: { [key: string]: any };
  size?: number;
  disabled?: boolean;
  loading?: boolean;
  onChange?: (value: string | number) => void;
  additionalError?: string | null;
  onFocus?: () => void;
  children: React.ReactNode;
}

export const YlSelect = observer<YlSelectProps>(function YlSelect({
  field,
  label,
  labelSize,
  id,
  children,
  required,
  size,
  customAttributes = {},
  disabled = false,
  loading,
  onChange,
  additionalError,
  onFocus
}) {
  function triggerChange(value: any) {
    field.onChange(value);
    if (onChange) {
      onChange(value);
    }
  }

  function triggerFocus() {
    if (onFocus) {
      onFocus();
    }
  }

  return (
    <YlFormGroup {...{ field, label, labelSize, id, required, size, additionalError }}>
      <InputGroup>
        <Form.Control
          required={required}
          as="select"
          value={field.value}
          onFocus={() => triggerFocus()}
          onChange={(e: any) => triggerChange(e.target.value)}
          isInvalid={field.hasError}
          onBlur={field.enableAutoValidationAndValidate}
          disabled={disabled}
          {...customAttributes}
        >
          {children}
        </Form.Control>
        {loading && (
          <InputGroup.Append>
            <InputGroup.Text>
              <Spinner animation="border" variant="secondary" size="sm" />
            </InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
    </YlFormGroup>
  );
});
