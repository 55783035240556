import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styles from './member-agreement-entry.module.scss';

interface Props {
  text: string;
}

export const MemberAgreementScrollText: FC<Props> = ({ text }) => {
  const { t } = useTranslation();

  return useObserver(() => (
    <>
      <h5 className="text-primary">{t('MemberAgreement')}</h5>
      <p>{t('scrollThroughAgreement')}</p>
      <div className={styles.scrollTextContainer}>
        {/* eslint-disable-next-line react/no-danger */}
        <div className={styles.scrollText} dangerouslySetInnerHTML={{ __html: text.split('"\r\n\r\n"').toString() }} />
      </div>
    </>
  ));
};
