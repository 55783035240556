import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { TiInputChecked } from 'react-icons/ti';

import { OrderItem } from './model/OrderItem';
import { MoneyDisplay } from '../../order-selection/starter-kit-selection/util-starter-kit/MoneyDisplay';
import { HelpPopOver } from '../../../infrastructure/forms/help-tip-icon/HelpTipIcon';
import { ImageWithMissingProductFallback } from './ImageWithMissingProductFallback';
import { DetailedMoneyDisplay } from '../../social-link/DetailedMoneyDisplay';

interface Props {
  item: OrderItem;
}

export const OrderSummaryItem = observer<Props>(({ item }) => {
  const { t } = useTranslation();
  return (
    <Card body className="my-2">
      <Row>
        <Col xs="auto" className="mb-2 mb-sm-0">
          <ImageWithMissingProductFallback
            src={item.imageUrl}
            isPromotional={item.isPromotional}
            style={{ width: 100 }}
          />
        </Col>
        <Col xs="auto" className="ml-auto d-sm-none">
          <TiInputChecked style={{ fontSize: 'xx-large' }} className="text-primary" />
        </Col>
        <Col xs={12} sm>
          {item.isPromotional && (
            <Badge variant="primary" className="w-100 text-left rounded-0 p-1 mb-1">
              <small>
                <b>{t('PromoItemText')}</b>
              </small>
            </Badge>
          )}
          <Row className="flex-nowrap">
            <Col>
              <Card.Text>{item.name}</Card.Text>
              {item.isPromotional ? (
                <Card.Text>
                  {item.price > 0 && <MoneyDisplay currency={item.currency} price={item.price} />}
                  {item.price === 0 && (
                    <span>
                      {t('Free')} <HelpPopOver label="WhyFree" />
                    </span>
                  )}
                </Card.Text>
              ) : (
                <Card.Text>
                  <DetailedMoneyDisplay
                    price={item.price}
                    quantity={item.quantity}
                    totalPrice={item.totalPrice}
                    currency={item.currency}
                  />
                </Card.Text>
              )}
            </Col>
            <Col xs="auto" className="d-none d-sm-block">
              <TiInputChecked style={{ fontSize: 'xx-large' }} className="text-primary ml-auto" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
});
