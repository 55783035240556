import React, { useEffect } from 'react';
import { Button, Row, Col, Form, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import './LocaleChooser.scss';
import { useRootStore } from '../../root/RootStoreHooks';
import { MarketChangeModal } from '../warning-modal/MarketChangeModal';
import { YlDropdown } from '../../../infrastructure/forms/YlDropdown';
import { CountryLanguages } from '../../../reference/CountryLanguages';
import { Language } from '../../../reference/Language';

export const LocaleChooser = observer(function LocaleChooser() {
  const rootStore = useRootStore();
  const { t } = useTranslation();
  const { reference, localeManager } = rootStore;

  useEffect(() => {
    reference.getCountriesWithLanguages();
  }, [reference]);

  return (
    <>
      <Dropdown
        show={localeManager.showLocaleChooser || localeManager.showMarketChangeWarning}
        onToggle={(isOpen: boolean) => {
          if (isOpen) {
            localeManager.openLocaleChooser();
          } else {
            localeManager.hideLocaleChooser();
          }
        }}
      >
        <Dropdown.Toggle
          variant="link"
          id="dropdown-basic"
          className={`dropdown-item-flag flag-${localeManager.language.isoCode2}`}
        >
          {localeManager.language.languageIso.toUpperCase()}
        </Dropdown.Toggle>
        <Dropdown.Menu renderOnMount={false}>
          <div className="fixed-top border-primary rounded shadow locale-chooser-modal">
            <Form
              className="px-3 py-4"
              noValidate
              onSubmit={async (e: React.FormEvent) => {
                e.preventDefault();
                const validationResult = await localeManager.reactForm?.validate();
                if (!validationResult.hasError) {
                  localeManager.confirmLocaleChange();
                }
              }}
            >
              <Row>
                <YlDropdown
                  required={localeManager.reactForm.$.country.hasError}
                  size={12}
                  id="countries"
                  label="countryLabel"
                  labelSize="normal"
                  placeholder="selectCountry"
                  placeholderOption={false}
                  filterConfig={{}}
                  loading={!reference.countriesWithLanguages}
                  options={reference.countriesWithLanguages ?? []}
                  labelFn={(x: CountryLanguages) => x.name}
                  valueFn={(x: CountryLanguages) => x.isoCode2}
                  field={localeManager.reactForm.$.country}
                  disabled={!!rootStore.moduleStores.socialLinkCreatorEntry}
                />
              </Row>
              <Row>
                <YlDropdown
                  required={localeManager.reactForm.$.language.hasError}
                  size={12}
                  id="languages"
                  label="languageLabel"
                  labelSize="normal"
                  placeholder="selectLanguage"
                  placeholderOption={false}
                  filterConfig={{}}
                  loading={!localeManager.languageOptions}
                  options={localeManager.languageOptions ?? []}
                  labelFn={(x: Language) => x.localName}
                  valueFn={(x: Language) => x.localeCode}
                  field={localeManager.reactForm.$.language}
                />
              </Row>
              <Row className="justify-content-around px-4 py-2">
                <Col md={4} sm={4} xs={6}>
                  <Button variant="link" onClick={() => localeManager.hideLocaleChooser()}>
                    {t('Close')}
                  </Button>
                </Col>
                <Col md={4} sm={4} xs={6}>
                  <Button type="submit" disabled={localeManager.reactForm.hasError}>
                    {t('DropDownMenuContinue')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <MarketChangeModal
        showWarning={localeManager.showMarketChangeWarning}
        hide={localeManager.hideMarketChangeWarning}
        agreeAndContinue={rootStore.cancelEnrollment}
        country={localeManager.reactForm.$.country.$}
        language={localeManager.reactForm.$.language.$}
      />
    </>
  );
});
