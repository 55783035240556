import { observer } from 'mobx-react-lite';
import React from 'react';
import { KitGridItemCustom } from '../../../common-er/util-er-kit/kit-grid/KitGridItemCustom';
import { KitGridItemMobile } from '../../../common-er/util-er-kit/kit-grid/KitGridItemMobile';
import { KitGridItem } from '../../../common-er/util-er-kit/kit-grid/KitGridItem';
import '../../../common-er/er-carousel.scss';
import { KitGridItemCustomPsk } from '../../../common-er/util-er-kit/kit-grid/KitGridItemCustomPsk';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';

export const KitGridMobile = observer(function KitGridMobile() {
  const store = useModuleStore('classicErKitSelection');

  return (
    <div className="container mb-4">
      {store.canCustomizeErKit && (
        <KitGridItemMobile>
          <KitGridItemCustom
            customKitSelected={store.customKitSelected}
            selectCustomizeKit={store.selectCustomizeKit}
          />
        </KitGridItemMobile>
      )}
      {store.canCustomizeErWithPsk && (
        <KitGridItemMobile key="KitGridItemCustomPsk">
          <KitGridItemCustomPsk
            customKitWithPskSelected={store.customKitWithPskSelected}
            selectCustomizeKitWithPsk={store.selectCustomizeKitWithPsk}
          />
        </KitGridItemMobile>
      )}
      {store.erKits.map(kit => (
        <KitGridItemMobile>
          <KitGridItem
            key={kit.itemId}
            kit={kit}
            showProductInfo={store.showProductInfo}
            choseKit={store.choseKit}
            selectedKit={store.selectedKit}
          />
        </KitGridItemMobile>
      ))}
    </div>
  );
});
