import React from 'react';
import { observer } from 'mobx-react-lite';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import { StarterKitTab } from '../../model/StarterKitTab';
import { StarterKitPopularCardMobileHeader } from '../card/StarterKitPopularCardMobileHeader';
import { checkedBorderColor } from '../../tab-selection/util-tab/CheckBorderColor';
import '../tabbed-layout/PopularKitTab.scss';
import { MoneyDisplay } from '../../util-starter-kit/MoneyDisplay';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';

interface PopularKitTabProps {
  tab: StarterKitTab;
}

export const PopularKitMobileTab = observer(({ tab }: PopularKitTabProps) => {
  const store = useModuleStore('enrollmentKitSelection');

  return (
    <Row>
      {tab.productKits.map(product => (
        <Col sm={12} key={product.itemId} className="mt-2">
          <div onClick={() => store.choseKit(product)} className={checkedBorderColor(store.selectedKit, product)}>
            <Card.Body className="text-center">
              <span>
                <Button variant="link">
                  <Image fluid src={product.smallImageUrl} />
                </Button>
              </span>
            </Card.Body>
            <StarterKitPopularCardMobileHeader kit={product} />
          </div>
          <div className="text-center">
            <MoneyDisplay price={product.price} currency={product.currency} />
          </div>
        </Col>
      ))}
    </Row>
  );
});
