/* eslint-disable react/jsx-boolean-value */
import React, { createRef, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { AccordionItem } from './AccordionItem';
import { EnrollmentStep } from '../../../model/EnrollmentStep';
import { SectionTitleInParentProvider } from '../../../model/SectionTitles';
import { useRootStore } from '../../../../../root/RootStoreHooks';

interface Props {
  step: EnrollmentStep;
}

export const AccordionStepLayout = observer(function AccordionStepLayout({ step }: Props) {
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const moduleStore = rootStore.moduleStores;
  const refs = useRef([]);
  refs.current = step.layoutModuleGroups.map((element, i) => refs.current[i] ?? createRef());

  return (
    <SectionTitleInParentProvider value={true}>
      <Accordion
        activeKey={moduleStore.activeModuleGroup !== undefined ? moduleStore.activeModuleGroup!.toString() : ''}
      >
        {step.layoutModuleGroups.map((moduleGroup, index) => (
          <AccordionItem
            isOpen={index === moduleStore.activeModuleGroup}
            key={moduleGroup.label}
            moduleGroup={moduleGroup}
            moduleGroupNumber={index}
            step={step}
            refs={refs}
          />
        ))}
      </Accordion>
      {!moduleStore.earlyEnrollment && moduleStore.activeModuleGroup === undefined && (
        <Button className="mt-4 float-right" onClick={() => step.activateNextModuleGroup()}>
          {t('Continue')}
        </Button>
      )}
    </SectionTitleInParentProvider>
  );
});
