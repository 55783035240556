import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Form } from 'react-bootstrap';
import { YlDropdown } from '../../../infrastructure/forms/YlDropdown';
import { months } from '../../../reference/MonthOptions';

import { generateRangeYears } from './Helpers/RangeYearOptions';
import { generateDays } from './Helpers/DayOptions';
import { ConfirmCheckBox } from './Helpers/ConfirmCheckBox';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { translateMonth } from '../../../reference/MonthName';
import { useTranslation } from 'react-i18next';
import { useModuleDisplayMode } from '../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';

export const AgeEntryDisplay: FC = () => {
  const ageEntry = useModuleStore('ageEntry');
  const { t } = useTranslation();

  return useObserver(() => {
    if (!ageEntry.isRequired && (!ageEntry.birthYear || !ageEntry.birthMonth || !ageEntry.birthDay)) {
      return <></>;
    }

    return (
      <div>
        <span>{t('dateOfBirth')}: </span>
        <span className="text-primary">
          {ageEntry.birthYear}/{ageEntry.birthMonth}/{ageEntry.birthDay}
        </span>
      </div>
    );
  });
};

export const AgeEntry = () => {
  const ageEntry = useModuleStore('ageEntry');
  const moduleDisplayMode = useModuleDisplayMode();

  return useObserver(() => {
    const form = ageEntry.reactForm.$.date;

    if (moduleDisplayMode) {
      return <AgeEntryDisplay />;
    }

    const yearOptions = generateRangeYears(1929, 16);
    const dayOptions = generateDays(31, 1);

    return (
      <Form>
        <Form.Row id="birthdate" style={{ flexDirection: 'row' }}>
          <YlDropdown
            options={dayOptions}
            valueFn={day => day}
            labelFn={day => day}
            placeholder="dateOfBirthDayDropDownPlaceholder"
            placeholderOption
            required={ageEntry.isRequired}
            size={4}
            id="day"
            disabled={false}
            field={form.$.day}
            label="dateOfBirth"
            additionalError={form.hasFormError ? form.error : undefined}
            onChange={() => form.validate()}
            customAttributes={{ 'data-testid': 'age-day' }}
          />

          <YlDropdown
            options={months}
            valueFn={month => month}
            labelFn={month => `${month} - ${translateMonth(month - 1)}`}
            placeholder="dateOfBirthMonthDropDownPlaceholder"
            placeholderOption
            required={ageEntry.isRequired}
            size={4}
            id="month"
            disabled={false}
            field={form.$.month}
            label="&nbsp;"
            onChange={() => form.validate()}
            customAttributes={{ 'data-testid': 'age-month' }}
          />

          <YlDropdown
            options={yearOptions}
            valueFn={year => year}
            labelFn={year => year}
            placeholder="dateOfBirthYearDropDownPlaceholder"
            placeholderOption
            required={ageEntry.isRequired}
            size={4}
            id="year"
            disabled={false}
            field={form.$.year}
            label="&nbsp;"
            onChange={() => form.validate()}
            customAttributes={{ 'data-testid': 'age-year' }}
          />
        </Form.Row>

        {ageEntry.confirmUserAtLeast18 && (
          <ConfirmCheckBox
            id="confirm18"
            data-testid="confirm18"
            label="userMustBeAtLeastEighteen"
            field={ageEntry.reactForm.$.confirm}
            required
          />
        )}
      </Form>
    );
  });
};
