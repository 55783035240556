import React from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './PayPalButtonPlaceholder.module.scss';

export const PaypalButtonPlaceholder = function PaypalButtonPlaceholder() {
  return (
    <div className={styles.paypalButtonPlaceholder}>
      <img
        src="https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/optimize/26_Yellow_PayPal_Pill_Button.png"
        alt="PayPal"
      />
      <Spinner animation="border" variant="secondary" size="sm" className="m-2" />
    </div>
  );
};
