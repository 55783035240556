import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { MexicoCurpEntry } from './MexicoCurpEntry';
import { YlInput } from '../../../../../infrastructure/forms/YlInput';
import { MexicoTaxSelector } from './support/MexicoTaxSelector';
import { RfcCheckLink } from './support/RfcCheckLink';
import { TaxIdContext } from '../TaxIdContext';

interface Props {
  context: TaxIdContext;
}

export const IndividualMexicoTaxEntry: FC<Props> = ({ context }) => {
  const [t] = useTranslation();
  return useObserver(() => {
    const individual = (context.selectedEntry && context.selectedEntry.individual) || context.entries[0].individual!;
    const taxForm = individual.reactForm;
    return (
      <>
        <p>{t('taxHelpIndividualDescription')}</p>
        <Form>
          <YlInput
            field={taxForm.$.taxId}
            required={individual.isRequired}
            label="rfcLabel"
            helpLabel={RfcCheckLink}
            onChange={() => individual.fixDashes()}
          />
          <Form.Row className="pl-3">
            <MexicoTaxSelector context={context} />
            <MexicoCurpEntry context={context} />
          </Form.Row>
        </Form>
      </>
    );
  });
};
