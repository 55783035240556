import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FormState } from 'formstate';
import { FaMinus, FaPlus } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import { ComponentField } from '../../../infrastructure/dynamic-forms/ComponentField';
import { CoApplicantEntryStore } from './CoApplicantEntryStore';
import { Field } from '../shared/Field';
import { IconLink } from '../shared/icon-link/IconLink';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useModuleDisplayMode } from '../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';
import { CoApplicantDisplay } from './CoApplicantEntryDisplay';

export interface ComponentFieldProps {
  field: ComponentField;
  form: FormState<any>;
}

interface CoApplicantFormProps {
  store: CoApplicantEntryStore;
}

const CoApplicantForm: FC<CoApplicantFormProps> = ({ store }) => {
  return useObserver(() => (
    <Form>
      <Form.Row>
        {store.form.formFields.map(field => (
          <Field
            size={4}
            field={field}
            key={field.id}
            form={store.reactForm}
            onChange={() => store.applyFormat(field)}
          />
        ))}
      </Form.Row>
    </Form>
  ));
};

export const CoApplicantEntry = () => {
  const { t } = useTranslation();
  const coApplicant = useModuleStore('coApplicantEntry');
  const displayMode = useModuleDisplayMode();
  return useObserver(() => {
    if (displayMode) {
      return coApplicant.data.hasCoApplicant ? <CoApplicantDisplay data={coApplicant.data} /> : <></>;
    }

    if (coApplicant.data.hasCoApplicant) {
      return (
        <>
          <IconLink onClick={() => coApplicant.removeCoApplicant()} icon={FaMinus}>
            {t('removeCoApplicant')}
          </IconLink>
          <CoApplicantForm store={coApplicant} />
        </>
      );
    }
    return (
      <IconLink onClick={() => coApplicant.addCoApplicant()} icon={FaPlus}>
        {t('addCoApplicant')}
      </IconLink>
    );
  });
};
