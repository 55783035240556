import React, { ReactNode } from 'react';
import { FieldState } from 'formstate';
import { observer } from 'mobx-react-lite';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { HelpPopOver } from './help-tip-icon/HelpTipIcon';

interface YlFormGroupProps {
  field: FieldState<any>;
  label: string | (() => string);
  labelSize?: 'small' | 'normal';
  id?: string;
  required?: boolean;
  size?: number;
  helpLabel?: string | (() => string) | ReactNode;
  additionalError?: string | null;
  additionalLabel?: ReactNode;
}

export const YlFormGroup = observer<YlFormGroupProps>(function YlFormGroup({
  size = 4,
  id,
  label,
  labelSize = 'small',
  children,
  required,
  field,
  helpLabel,
  additionalLabel = <></>,
  additionalError
}) {
  const { t } = useTranslation();

  function getHelpLabel() {
    if (helpLabel === undefined) {
      return <span className="pb-1">&nbsp;</span>;
    }
    if (typeof helpLabel === 'object') {
      return helpLabel as ReactNode;
    }
    return (
      <span className="text-primary pb-1">
        <HelpPopOver label={helpLabel as any} />
      </span>
    );
  }

  const fieldLabel = typeof label === 'function' ? label() : label && t(label);

  return (
    <Form.Group as={Col} md={size} controlId={id}>
      <Form.Label className="d-flex align-items-center">
        <span style={{ fontSize: labelSize === 'small' ? '14px' : undefined }}>{fieldLabel && fieldLabel}</span>
        {required && fieldLabel && <span> *</span>}
        {required && !fieldLabel && <span>&nbsp;</span>}
        {getHelpLabel()}
        {additionalLabel}
      </Form.Label>
      {children}
      {(field.hasError || additionalError) && (
        <div data-testid={`error-${id}`} className="invalid-feedback" style={{ display: 'block' }}>
          {field.error && t(field.error)}
          {additionalError && t(additionalError)}
        </div>
      )}
    </Form.Group>
  );
});
