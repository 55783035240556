import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { YlInput } from '../../../infrastructure/forms/YlInput';
import styles from './account-credentials.module.scss';
import { useOptionalSectionTitles } from '../../enrollment-process/steps-management/model/SectionTitles';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useModuleDisplayMode } from '../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';

export const AccountCredentialEntryDisplay: FC = () => {
  const accountEntry = useModuleStore('accountCredentialsEntry');
  const { t } = useTranslation();
  return useObserver(() => (
    <>
      {accountEntry.isUsernameRequired &&
        <div>
          <span>{t('username')}: </span>
          <span className="text-primary">{accountEntry.userName}</span>
        </div>
      }
      <div>
        <span>{t('password')}: </span>
        <span className="text-primary">**********</span>
      </div>
    </>
  ));
};

export const InformationSectionHeading: FC = ({ children }) => {
  const { titleInParent } = useOptionalSectionTitles();
  return titleInParent ? <></> : <h3 className="text-primary">{children}</h3>;
};
export const AccountCredentialEntry = () => {
  const { t } = useTranslation();
  const accountEntry = useModuleStore('accountCredentialsEntry');
  const moduleDisplayMode = useModuleDisplayMode();

  return useObserver(() => {
    const form = accountEntry.reactForm;
    if (moduleDisplayMode) {
      return <AccountCredentialEntryDisplay />;
    }
    return (
      <Form noValidate className={styles.accountCredentials}>
        <Form.Row>
          <InformationSectionHeading>{t('loginInformation')}</InformationSectionHeading>
          <p>{t('loginInformationDescription')}</p>
        </Form.Row>
        {accountEntry.isUsernameRequired &&
          <Form.Row>
            <YlInput
              required
              field={form.$.userName}
              customAttributes={{ maxLength: 50, autoComplete: 'username' }}
              label="username"
              id="username"
            />
          </Form.Row>
        }
        <p>
          <small>{t('PasswordComplexity')}</small>
        </p>
        <Form.Row className="row">
          <YlInput
            type="password"
            required
            customAttributes={{ maxLength: 50, autoComplete: 'new-password' }}
            field={form.$.password}
            label="password"
            id="password"
          />
          <YlInput
            type="password"
            required
            customAttributes={{ maxLength: 50, autoComplete: 'new-password' }}
            field={form.$.confirmPassword}
            label="passwordVerification"
            id="confirm-password"
          />
        </Form.Row>
      </Form>
    );
  });
};
