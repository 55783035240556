import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface WarningBannerProps {
  label: string;
}

export const WarningBanner: FC<WarningBannerProps> = observer(function WarningBanner({ label })  {
  const { t } = useTranslation();
  return (
    <div className="p-4"  style={{ backgroundColor: '#ffa5a5', letterSpacing: '1px', color: '#b30202' }}>
      <p className='align-baseline'>{t(label)}</p>
    </div>
  );
});
