import { observer } from 'mobx-react-lite';
import React from 'react';
import { ProductKit } from '../../../starter-kit-selection/model/ProductKit';
import { KitPriceDisplay } from '../KitPriceDisplay';

interface CardHeaderProps {
  kit: ProductKit;
  selectedKit?: ProductKit;
  choseKit: (kit?: ProductKit) => void;
}

export const CardHeader = observer(function CardHeader({ kit, selectedKit, choseKit }: CardHeaderProps) {
  const checked = selectedKit === kit;
  const handleOnClick = (productKit: ProductKit) => {
    const whichKit = productKit === selectedKit ? undefined : productKit;
    choseKit(whichKit);
  };

  return (
    <div onClick={() => handleOnClick(kit)} className={`text-right ${checked ? 'bg-primary' : 'bg-secondary'}`}>
      <div className="container-checkbox-popular">
        <input type="checkbox" readOnly checked={checked} />
        <span className="checkmark-basic" />
        <KitPriceDisplay kit={kit} />
      </div>
    </div>
  );
});
