import React, { useContext } from 'react';
import { EnrollmentRootStore } from './EnrollmentRootStore';

export const EnrollmentRootStoreContext = React.createContext<EnrollmentRootStore | undefined>(undefined);

export function useRootStore(): EnrollmentRootStore {
  const root = useContext(EnrollmentRootStoreContext);
  if (root === undefined) {
    throw Error('You need to set the enrollment root context with the provider before you can use it');
  }
  return root;
}
