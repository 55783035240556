import React from 'react';

export function generateRangeYears(start: number, acceptableAge: number) {
  const currentYear = new Date().getFullYear();
  const lastYear = currentYear - acceptableAge;
  const years = [];
  for (let i = start; i <= lastYear; i++) {
    years.unshift(i);
  }
  return years;
}

interface RangeYearOptionsProps {
  starting?: number;
  acceptableAge?: number;
}

export function RangeYearOptions({ starting = 1900, acceptableAge = 16 }: RangeYearOptionsProps) {
  return (
    <>
      {generateRangeYears(starting, acceptableAge).map(year => {
        return (
          <option key={year} value={year}>
            {year}
          </option>
        );
      })}
    </>
  );
}
