import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { ComponentField } from '../../../infrastructure/dynamic-forms/ComponentField';
import { YlPhoneInput } from '../../../infrastructure/forms/YlPhoneInput';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useModuleDisplayMode } from '../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';

export const PhoneEntryDisplay: FC = () => {
  const phoneEntry = useModuleStore('phoneEntry');
  const { t } = useTranslation();
  return useObserver(() => (
    <div>
      <span>{t('primaryPhone')}: </span>
      <span className="text-primary">{phoneEntry.data.primaryPhone}</span>
    </div>
  ));
};

export const PhoneEntry: FC = () => {
  const phoneEntry = useModuleStore('phoneEntry');
  const moduleDisplayMode = useModuleDisplayMode();
  return useObserver(() => {
    const form = phoneEntry.reactForm;
    const { config } = phoneEntry;
    if (moduleDisplayMode) {
      return <PhoneEntryDisplay />;
    }
    return (
      <Form noValidate>
        <Form.Row>
          {phoneEntry.form.formFields.map((field: ComponentField) => (
            <YlPhoneInput
              key={field.id}
              required={field.required}
              size={4}
              id={field.id}
              field={form.$[field.name]}
              customAttributes={{ maxLength: config.maxLength, minLength: config.minLength }}
              label={field.labelKey}
              inputPrefix={config.phonePrefix}
              internationalMode={phoneEntry.internationalMode}
              defaultCountryIsoCode={phoneEntry.defaultCountryIsoCode}
            />
          ))}
        </Form.Row>
      </Form>
    );
  });
};
