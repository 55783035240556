import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldState } from 'formstate';
import Form from 'react-bootstrap/Form';
import { InputGroup } from 'react-bootstrap';
import { YlFormGroup } from './YlFormGroup';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import 'libphonenumber-js-utils';
import './YlPhoneInput.scss';

export interface YlPhoneInputProps {
  field: FieldState<any>;
  type?: string;
  label: string;
  id?: string;
  required?: boolean;
  customAttributes?: { [key: string]: any };
  size?: number;
  disabled?: boolean;
  helpLabel?: string;
  onChange?: (value: string | number) => void;
  inputPrefix?: string;
  internationalMode?: boolean;
  defaultCountryIsoCode?: string;
}

export const YlPhoneInput: FC<YlPhoneInputProps> = observer(function YlPhoneInput({
  field,
  label,
  id,
  type,
  required,
  size,
  helpLabel,
  customAttributes = {},
  disabled = false,
  onChange,
  inputPrefix,
  internationalMode,
  defaultCountryIsoCode
}) {
  function triggerChange(value: any) {
    field.onChange(value);
    if (onChange) {
      onChange(value);
    }
  }
  return (
    <YlFormGroup {...{ field, label, id, required, size, helpLabel }}>
      {internationalMode ? (
        <IntlTelInput
          fieldId={id}
          telInputProps={{ required }}
          containerClassName="intl-tel-input"
          inputClassName={'form-control' + (field.hasError ? ' is-invalid' : '')}
          preferredCountries={defaultCountryIsoCode ? [defaultCountryIsoCode.toLowerCase()] : undefined}
          defaultCountry={defaultCountryIsoCode}
          onPhoneNumberChange={(isValid: boolean, value: any, countryInfo: any, valueWithCode: any) => {
            const valueTrimmed = valueWithCode.replace(/\s/g, '');
            triggerChange(isValid || !valueTrimmed ? valueTrimmed : '_');
          }}
          onPhoneNumberBlur={field.enableAutoValidationAndValidate}
          disabled={disabled}
          defaultValue={field.value === '_' ? undefined : field.value}
          style={{ width: '100%' }}
        />
      ) : (
        <InputGroup>
          {inputPrefix && (
            <InputGroup.Prepend>
              <InputGroup.Text>{inputPrefix}</InputGroup.Text>
            </InputGroup.Prepend>
          )}
          <Form.Control
            as="input"
            required={required}
            type={type || 'text'}
            value={field.value}
            onChange={(e: any) => triggerChange(e.target.value)}
            isInvalid={field.hasError}
            onBlur={field.enableAutoValidationAndValidate}
            disabled={disabled}
            {...customAttributes}
          />
        </InputGroup>
      )}
    </YlFormGroup>
  );
});
