import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';

interface Props {
  address: { lines: string[] };
}

export const AddressLines: FC<Props> = ({ address }) => {
  return useObserver(() => (
    <>
      {address.lines.map(line => (
        <React.Fragment key={line}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </>
  ));
};
