import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { EnrollmentPaymentType, getPaymentTypeLabelName } from '../../model/EnrollmentPaymentTypes';
import { PaymentMethodEditor } from './PaymentMethodEditor';
import { useRootStore } from '../../../root/RootStoreHooks';

interface PaymentMethodRadioButtonsProps {
  methods: EnrollmentPaymentType[];
  selected: EnrollmentPaymentType;
  onChange: (method: EnrollmentPaymentType) => void;
}

export const PaymentMethodRadioButtons = observer<PaymentMethodRadioButtonsProps>(({ methods, selected, onChange }) => {
  const { t } = useTranslation();
  const root=useRootStore();
  return (
    <>
      {methods.map(method => (
        <React.Fragment key={method}>
          {root.enrollmentStatus.countryIso=='HK' &&(<h6>{t('BillingInformationHelp')}</h6>)}
          <Form.Check
            id={`selected-payment-${method}`}
            custom
            label={t(getPaymentTypeLabelName(method))}
            type="radio"
            checked={method === selected}
            onChange={() => onChange(method)}
          />
          {method === selected && (
            <Card.Body>
              <PaymentMethodEditor method={method} />
            </Card.Body>
          )}
        </React.Fragment>
      ))}
    </>
  );
});
