import React, { useRef } from 'react';
import { useObserver } from 'mobx-react-lite';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Broadcast from '../../../img/yl-w-broadcast.png';
import Calendar from '../../../img/yl-w-calendar.png';
import Discount from '../../../img/yl-w-discount.png';
import Essentials from '../../../img/yl-w-essentials.png';
import Protect from '../../../img/yl-w-protect.png';
import Shipping from '../../../img/yl-w-shipping.png';
import styles from './Welcome.module.scss';
import { WelcomeCard } from './WelcomeCard';
import { useRootStore } from '../../root/RootStoreHooks';
import { getRootStore } from '../../root/RootStoreUtils';

export const Welcome = () => {
  const cardRef = useRef(null);
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const compensationPlan = <WelcomeCard image={Protect} label="compensation_plan" />;

  const scrollToMain = (): void => {
    const top = cardRef.current == null ? 0 : (cardRef as any).current.scrollHeight;
    window.scrollTo({
      top,
      left: 0,
      behavior: 'smooth'
    });
  };

  return useObserver(() => {
    return (
      <Container className={styles.welcomeContainer} ref={cardRef}>
        <Row className="p-4">
          <Col>
            <h1 className={styles.welcomeTitle}>{t('header')}</h1>
            <h5 className="text-center">
              <strong>{t('sub_header')}</strong>
            </h5>

            <Row className="p-4">
              <Col xl={3}>
                <h6 className="text-primary">
                  <strong>{t('members_will')}</strong>
                </h6>
              </Col>
              <Col xl={9}>
                <Row>
                  <Col xl={6}>
                    <WelcomeCard image={Discount} label="discount" />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="p-4">
              <Col xl={3}>
                <h6 className="text-primary">
                  <strong>{t('members_can')}</strong>
                </h6>
              </Col>
              <Col xl={9}>
                <Row>
                  <Col xl={6}>
                    <WelcomeCard image={Essentials} label="essential_rewards" />
                    <WelcomeCard image={Broadcast} label="promotions" />
                    {rootStore.moduleStores.showShippingMessage && compensationPlan}
                  </Col>
                  <Col xl={6}>
                    {!rootStore.moduleStores.showShippingMessage && compensationPlan}
                    {rootStore.moduleStores.showShippingMessage && (
                      <WelcomeCard image={Shipping} label="shipping_discount" />
                    )}
                    <WelcomeCard image={Calendar} label="member_events" />
                  </Col>
                </Row>
              </Col>
            </Row>

            {
              rootStore.enrollmentStatus.countryIso != 'JP' &&
              <div className={styles.welcomeButtonSet}>
                <Button className="mb-1" variant="outline-primary" size="lg" onClick={() => scrollToMain()}>
                  {t('get_started')}
                </Button>
                <Button
                  variant="link"
                  className="text-secondary"
                  size="lg"
                  style={{ textDecoration: 'none' }}
                  onClick={() => rootStore.redirectToMain()}
                >
                  {t('no_thanks')}
                </Button>
              </div>
            }

          </Col>
        </Row>
      </Container>
    );
  });
};
