import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { ListPrice } from './ListPrice';
import { useRootStore } from '../../root/RootStoreHooks';

interface Props {
  store: any;
  styles: any;
}

export const OrderTotalTaxCard: FC<Props> = ({ store, styles }) => {
  const rootStore = useRootStore();

  return useObserver(() => (
    <>
      <hr className="mt-0 mb-1" />
      <ListPrice priceList={store.taxableAmount} currency={rootStore.reference.currencies[0]} label="TaxableAmount" />

      <ListPrice priceList={store.salesTax} currency={rootStore.reference.currencies[0]} label="SalesTax" />

      <ListPrice priceList={store.shippingTax} currency={rootStore.reference.currencies[0]} label="ShippingTax" />
    </>
  ));
};
