import React, { FC } from 'react';
import { ModuleRenderer } from '../../../module-management/ModuleRenderer';
import { EnrollmentModule } from '../../model/LayoutModuleGroup';

export interface Props {
  modules: EnrollmentModule[];
}

export const LayoutModuleGroup: FC<Props> = ({ modules }) => {
  return (
    <>
      {modules.map(module => (
        <ModuleRenderer key={module} module={module} />
      ))}
    </>
  );
};
