import { observer } from 'mobx-react-lite';
import React from 'react';
import { EnrollmentLayout } from '../layout/EnrollmentLayout';
import { EnrollmentRoutePicker } from '../routing/EnrollmentRoutePicker';
import { EnrollmentRootStore } from './EnrollmentRootStore';
import { EnrollmentRootStoreContext } from './RootStoreHooks';
import { ErrorBoundary } from '../../external/shared/error-handling/ErrorBoundary';

interface Props {
  store: EnrollmentRootStore;
}

export const Enrollment = observer<Props>(function Enrollment({ store }) {
  return (
    <EnrollmentRootStoreContext.Provider value={store}>
      <EnrollmentLayout store={store}>
        <ErrorBoundary>
          <EnrollmentRoutePicker router={store.router} />
        </ErrorBoundary>
      </EnrollmentLayout>
    </EnrollmentRootStoreContext.Provider>
  );
});

export default Enrollment;
