import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const ShippingMethodWillCallDisplay: FC = () => {
  const { t } = useTranslation();
  return useObserver(() => (
    <>
      <p>{t('WillCallHours')}</p>
      <p className="text-danger">{t('WillCallWarning')}</p>
    </>
  ));
};
