import React from 'react';
import { observer } from 'mobx-react-lite';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EnrollmentStep } from '../../../model/EnrollmentStep';
import { LayoutModuleGroup } from '../LayoutModuleGroup';

interface StandardStepLayoutProps {
  step: EnrollmentStep;
}
export const TwoColumnStepLayout = observer(function TwoColumnStepLayout({ step }: StandardStepLayoutProps) {
  return (
    <Row>
      <Col md={6}>
        <LayoutModuleGroup modules={step.layoutModuleGroups[0].modules} />
      </Col>
      <Col md={6}>
        <LayoutModuleGroup modules={step.layoutModuleGroups[1].modules} />
      </Col>
    </Row>
  );
});
