import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { YesNoQuestion } from '../../order-selection/er-kit-selection/yes-no-question/YesNoQuestion';
import { useRootStore } from '../../root/RootStoreHooks';

export const FirstErAsTemplateQuestion = observer(() => {
  const { t } = useTranslation();
  const root = useRootStore();
  const store = root.moduleStores.erKitSelection ?? root.moduleStores.socialLinkErKitSelection!;

  return (
    <>
      <YesNoQuestion
        value={store.useFirsErOrderAsTemplate}
        onChange={value => store.updateUseFirsErOrderAsTemplate(value)}
        whenYes={t('FirstErAsTemplateQuestion_Yes')}
        whenNo={t('FirstErAsTemplateQuestion_No')}
        question={t('FirstErAsTemplateQuestion_Question')}
      />
    </>
  );
});
