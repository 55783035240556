import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { HelpPopOver } from '../../../infrastructure/forms/help-tip-icon/HelpTipIcon';
import { ErUpsellModalStore } from './model/ErUpsellModalStore';

interface Props {
  store: ErUpsellModalStore;
}

export const ErPurchasingReminder: FC<Props> = ({ store }) => {
  const { t } = useTranslation();

  return useObserver(() => {
    return (
      <Modal show={store.showErUpsellModal}>
        <Modal.Body>
          <h3 className="text-primary">{t('ProceedWithoutAutoship')}</h3>
          <p>{t('SelectToParticipate')}</p>

          <h4>{t('PopularShoppingProgram')}</h4>

          <div className="ml-5">
            <ul>
              <li>
                {t('CancelOrChangeYourOrderAnytime')} <HelpPopOver label="CancelOrChangeYourOrderAnytimeTooltip" />
              </li>
              <li>
                {t('DiscountedShipping')} <HelpPopOver label="DiscountedShippingTooltip" />
              </li>
              <li>
                {t('BackInPointsTowardFreeProducts')} <HelpPopOver label="BackInPointsTowardFreeProductsTooltip" />
              </li>
              <li>
                {t('EssentialRewardsPromotions')} <HelpPopOver label="EssentialRewardsPromotionsTooltip" />
              </li>
              <li>
                {t('DiscountedProductPackages')} <HelpPopOver label="DiscountedProductPackagesTooltip" />
              </li>
              <li>
                {t('ScheduledMonthlyDelivery')} <HelpPopOver label="ScheduledMonthlyDeliveryTooltip" />
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => store.close(false)}>
            {t('ChooseAKit')}
          </Button>
          <Button variant="primary" onClick={() => store.close(true)}>
            {t('ContinueWithoutKit')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  });
};
