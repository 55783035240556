import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../root/RootStoreHooks';
import { CardHeader } from '../../order-selection/starter-kit-selection/tab-content/card/StarterKitPriceCardHeader';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import './CustomerType.scss';
import { useTranslation } from 'react-i18next';
import { CustomerTypeBenefits } from './CustomerTypeBenefits';

export const CustomerTypeEntry = observer(function CustomerTypeEntry() {
  const { t } = useTranslation();
  const { moduleStores } = useRootStore();
  const store = moduleStores.customerTypeEntry!;

  return (
    <>
      <p>{t('selectCustomerTypeQuestion')}</p>
      <CardGroup>
        {store.availableChoices.map(type => {
          return CustomerTypeDisplay(type, store.getCurrentType, () => {
            store.changeType(type);
          });
        })}
      </CardGroup>
    </>

    /*
      <div className="or">
        <span>Or</span>
      </div>
    */
  );
});

function CustomerTypeDisplay(typeName: string, currentType: () => string, onClick: () => void) {
  const { t } = useTranslation();
  const header = `${typeName}Header`;
  return (
    <>
      <Card className="m-4" key={typeName}>
        <CardHeader
          onClick={() => {
            onClick();
          }}
          isSelected={currentType() === typeName}
        >
          <span className="text-right text-white pr-2">{t(header)}</span>
        </CardHeader>
        <Card.Body>
          <CustomerTypeBenefits type={typeName} />
        </Card.Body>
      </Card>
    </>
  );
}
