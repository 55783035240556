import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import { FaPlus } from 'react-icons/fa';
import { MoneyDisplay } from '../../../util-starter-kit/MoneyDisplay';
import { EnrollmentKitSelectionStore } from '../../../model/EnrollmentKitStore';
import { StarterKitTab } from '../../../model/StarterKitTab';

interface BaseKitCardProps {
  store: EnrollmentKitSelectionStore;
  tab: StarterKitTab;
}

export const BaseKitCard: FC<BaseKitCardProps> = observer(function BaseKitCard({ store, tab }) {
  const { t } = useTranslation();

  return (
    <>
      <Col md={4} className="h-100">
        <p className="mb-2 pr-0  text-center">{t('PremiumStarterKit')}</p>
        <Card>
          <div onClick={() => store.choseDefaultKitInTab(tab)} className="text-left bg-primary">
            <div className="container-checkbox kit-grid-item mt-2 ml-2 mb-3">
              <input type="checkbox" className="mt-2 mr-2" readOnly checked={store.selectedKitTab === tab} />
              <span className="checkmark" />
              <p className="text-white header-card-text m-1">
                {t('KitTotal')}
                <MoneyDisplay price={tab.dynamicKitPrice.price} currency={tab.dynamicKitPrice.currency} />
              </p>
            </div>
          </div>
          <Card.Body className="h-100">
            <Image fluid src={store.baseKit!.imageLarge} />
          </Card.Body>
        </Card>
      </Col>
      <Col
        md="auto"
        className="text-primary text-center align-middle my-auto p-0 pt-4"
        style={{ fontSize: '2.5rem', fontWeight: 300 }}
      >
        <FaPlus />
      </Col>
    </>
  );
});
