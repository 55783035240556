import React from 'react';
import { observer } from 'mobx-react-lite';
import { EnrollmentKitSelectionStore } from '../model/EnrollmentKitStore';
import { DesktopEnrollmentKitTabs } from '../tab-selection/TabSelectorDesktop';

interface StarterKitTabLayoutProps {
  store: EnrollmentKitSelectionStore;
}

export const StarterKitTabLayout = observer(function StarterKitTabLayout({ store }: StarterKitTabLayoutProps) {
  return (
    <>
      <DesktopEnrollmentKitTabs />
    </>
  );
});
