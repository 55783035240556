import { observer } from 'mobx-react-lite';
import React, { ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import Slider from 'react-slick';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ProductKit } from '../../model/ProductKit';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';
import { StarterKitPriceCardHeader } from './StarterKitPriceCardHeader';
import './KitSlider.scss';
import { EssentialRewardKit } from '../../../er-kit-selection/model/EssentialRewardKit';

interface KitSliderItemProps {
  kit: ProductKit;
  showDetailLink?: boolean;
  customHeader?: ReactNode;
  customImage?: string;
  store: any;
}

export interface KitSliderProps {
  kits: ProductKit[];
  slidesToShow?: number;
  store: any;
}

export const KitSliderItem = observer(function KitGridItem({
  kit,
  showDetailLink = true,
  customHeader,
  customImage, 
  store
}: KitSliderItemProps) {
  const { t } = useTranslation();
  const header = customHeader || <StarterKitPriceCardHeader kit={kit} store={store}/>;
  const image = customImage || kit.smallImageUrl;
  return (
    <>
      <Card>
        {header}
        <Card.Body className="text-center">
          <span>
              <Image src={image} fluid/>
          </span>
          {showDetailLink && (
            <Button variant="link" onClick={() => store.showProductInfo(kit)}>
              <u>{t('Details')}</u>
            </Button>
          )}
        </Card.Body>
      </Card>
    </>
  );
});

export const KitSlider = observer(function KitSlider({ kits, slidesToShow, store}: KitSliderProps) {
    const slider = useRef<Slider>(null);
    const slidesNumber = slidesToShow || 4;
    const responsiveSettings = [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      },
    ];
    return (
        <div>
            <Slider
                ref={slider}
                dots={true}
                slidesToShow={slidesNumber}
                slidesToScroll={1}
                infinite={true}
                speed={300}
                responsive={responsiveSettings}
                className="row"
                swipeToSlide={true}>
                  
                {kits.map(kit => (
                    <Col key={kit.itemId}>
                      <p className="pb-2 m-0 text-center">
                        {kit.title}
                      </p>
                      <KitSliderItem kit={kit} store={store}/>
                    </Col>
                ))}
            </Slider>
        </div>
    );
});