import React, { FC, ReactNode } from 'react';
import { ErYesNoQuestion } from '../EssentialRewardsButton';

interface Props {
  onChange: (value: boolean) => void;
  value?: boolean;
  whenYes: ReactNode | string;
  whenNo: ReactNode | string;
  question: string;
  err?: boolean;
}

export const YesNoQuestion: FC<Props> = ({ question, onChange, value, whenYes, whenNo, err }) => {
  let message: ReactNode | string = '';

  if (value === true) {
    message = whenYes;
  }
  if (value === false) {
    message = whenNo;
  }
  if (err === true) {
    message = '';
  }
  return (
    <>
      <p>{question} </p>
      <div className="d-flex align-items-start mb-3">
        <ErYesNoQuestion value={value} onChange={(x: boolean) => onChange(x)} />
        <div>
          <p className="ml-3">{message}</p>
        </div>
      </div>
    </>
  );
};
