import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import { IconType } from 'react-icons';

interface IconLinkProps {
  icon: IconType;
  onClick: () => void;
}

export const IconLink: FC<IconLinkProps> = function IconLink({ children, icon, onClick }) {
  const Icon = icon;
  const divStyle = {
    display: 'inline-flex',
    alignSelf: 'center'
  };
  const style = {
    height: '1em',
    width: '1em',
    position: 'relative' as const,
    top: '.125em',
    marginRight: '.25em'
  };

  return (
    <span>
      <Button variant="link" onClick={onClick}>
        <div style={divStyle}>
          <Icon style={style} />
        </div>
        {children}
      </Button>
    </span>
  );
};
