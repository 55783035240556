import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ProductKit } from '../model/ProductKit';
import { MoneyDisplay } from './MoneyDisplay';

interface KitPriceDisplayProps {
  kit: ProductKit;
}

export const KitPriceDisplay = observer(({ kit }: KitPriceDisplayProps) => {
  const { t } = useTranslation();
  return (
    <p>
      <strong>
        {t('starterKitPrice')}: <MoneyDisplay currency={kit.currency} price={kit.price} />
      </strong>
    </p>
  );
});
