import React from 'react';
import { observer } from 'mobx-react-lite';
import { PopularKitStandardTab } from './standard-layout/PopularKitStandardTab';
import { BasicKitStandardTab } from './standard-layout/BasicKitStandardTab';
import { PremiumKitStandardTab } from './standard-layout/PremiumKitStandardTab';
import { SpecialtyKitStandardTab } from './standard-layout/SpecialtyKitStandardTab';
import { useModuleStore } from '../../../enrollment-process/module-management/model/ModuleStoreHook';

export const StarterKitStandardContent = observer(function StarterKitTabContent() {
  const store = useModuleStore('enrollmentKitSelection');
  switch (store.currentTab.tabType) {
    case 'Popular':
      return <PopularKitStandardTab tab={store.currentTab} />;
    case 'Premium':
      return <PremiumKitStandardTab tab={store.currentTab} />;
    case 'Specialty':
      return <SpecialtyKitStandardTab tab={store.currentTab} />;
    case 'Basic':
      return <BasicKitStandardTab tab={store.currentTab} />;
    default:
      throw new Error('no tab for type');
  }
});
