import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Alert, Col, Form } from 'react-bootstrap';

import { useModuleStore } from '../../../enrollment-process/module-management/model/ModuleStoreHook';
import { PaymentInstallmentDropdown } from '../installment/PaymentInstallmentDropdown';

export const PayPalEditor = observer(() => {
  const { t } = useTranslation();
  const {
    installmentEntry: { isInstallmentsLoading, haveInstallments, selectedInstallment }
  } = useModuleStore('billingEntry');

  return isInstallmentsLoading || haveInstallments ? (
    <Form.Row>
      <Col lg={6}>
        <PaymentInstallmentDropdown />
      </Col>
      {selectedInstallment && selectedInstallment.installmentNumber > 1 && (
        <Col xs={12}>
          <Alert variant="secondary">{t('PaymentInstallmentDisclaimer')}</Alert>
        </Col>
      )}
    </Form.Row>
  ) : null;
});
