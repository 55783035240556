import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { YlSelect } from '../../../../../../infrastructure/forms/YlSelect';
import { TaxIdContext } from '../../TaxIdContext';

interface Props {
  context: TaxIdContext;
}

export const MultipleIndividualTaxSelector: FC<Props> = ({ context }) => {
  return useObserver(() => (
    <YlSelect
      size={5}
      field={context.contextField}
      label="taxIdType"
      onChange={taxId => context.selectEntry(Number(taxId))}
    >
      {context.entries.map(entry => (
        <option key={entry.taxIdTypeId} value={entry.taxIdTypeId}>
          {entry.individual!.name}
        </option>
      ))}
    </YlSelect>
  ));
};
