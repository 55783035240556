import React from 'react';
import { PopOverLink } from '../../../../../../infrastructure/forms/help-tip-icon/HelpTipIcon';

export const CurpCheckLink = (
  <PopOverLink
    helpLabel="curpIdFormatHelpText"
    linkLabel="validateCurpLabel"
    className="ml-3"
    href="https://www.gob.mx/curp/"
    rel="noopener noreferrer"
    target="_blank"
  />
);
