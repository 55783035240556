import React, { FC, useRef } from 'react';
import Slider from 'react-slick';
import { useObserver } from 'mobx-react-lite';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { ProductKit } from '../../../model/ProductKit';
import { useRootStore } from '../../../../../root/RootStoreHooks';
import { KitCard } from '../../card/KitGrid';
import { StarterKitPopularCardHeader } from '../../card/StarterKitPopularCardHeader';

interface DynamicKitChoicesProps {
  kits: ProductKit[];
}

const CAROUSEL_BREAKPOINT = 1024;
export const DynamicKitChoices: FC<DynamicKitChoicesProps> = ({ kits }) => {
  const slider = useRef<Slider>(null);
  const root = useRootStore();
  const responsiveSettings = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1
      }
    }
  ];
  const oneCardStyle = kits && kits.length === 1 ? { maxWidth: '290px' } : {};

  return useObserver(() => {
    const slidesShowing = root.windowWidth >= CAROUSEL_BREAKPOINT ? 3 : 2;
    return (
      <Col md={7} data-component="DynamicKitChoices">
        {kits.length <= slidesShowing ? (
          <div className="row">
            {kits.map(product => (
              <Col key={product.itemId} style={oneCardStyle}>
                <p className="pb-2 m-0 text-center">
                  {product.upgradeComponent ? product.upgradeComponent.title : product.title}
                </p>
                <KitCard
                  kit={product}
                  customImage={
                    product.upgradeComponent ? product.upgradeComponent.smallImageUrl : product.smallImageUrl
                  }
                  customHeader={<StarterKitPopularCardHeader kit={product} />}
                />
              </Col>
            ))}
          </div>
        ) : (
          <div>
            <Slider
              ref={slider}
              slidesToShow={3}
              slidesToScroll={1}
              responsive={responsiveSettings}
              className="row"
              arrows={false}
            >
              {kits.map(product => (
                <Col key={product.itemId}>
                  <p className="pb-2 m-0 text-center">
                    {product.upgradeComponent ? product.upgradeComponent.title : product.title}
                  </p>
                  <KitCard
                    kit={product}
                    customImage={
                      product.upgradeComponent ? product.upgradeComponent.smallImageUrl : product.smallImageUrl
                    }
                    customHeader={<StarterKitPopularCardHeader kit={product} />}
                  />
                </Col>
              ))}
            </Slider>
            <Row className="justify-content-between mt-2 text-primary">
              <Col md="auto">
                <FaChevronCircleLeft onClick={() => slider.current!.slickPrev()} style={{ cursor: 'pointer' }} />
              </Col>
              <Col md="auto">
                <FaChevronCircleRight onClick={() => slider.current!.slickNext()} style={{ cursor: 'pointer' }} />
              </Col>
            </Row>
          </div>
        )}
      </Col>
    );
  });
};
