import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { OrderItem } from './model/OrderItem';
import { OrderSummaryItem } from './OrderSummaryItem';

interface Props {
  label: string;
  items: OrderItem[];
}

export const OrderSummaryItemList = observer<Props>(({ items, label }) => {
  const { t } = useTranslation();
  return items.length === 0 ? (
    <></>
  ) : (
    <>
      <h6 className="mt-5">{t(label)}</h6>
      {items.map(item => (
        <OrderSummaryItem key={item.name} item={item} />
      ))}
    </>
  );
});
