/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './Navbar.scss';
import logo from './yl-logo-color.svg';
import { LocaleChooser } from '../../locale-management/locale-manager/LocaleChooser';
import { WarningIfExitFromEnrollmentModal } from '../../customer-enrollment-status/WarningIfExitFromEnrollmentModal';

export const Navbar = () => {
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  return (
    <div className="sticky-top-navbar bg-white">
      <nav className="topbar-main">
        <div className="container">
          <div className="row justify-content-between">
            <LocaleChooser />
          </div>
        </div>
      </nav>
      <nav className="navbar-main navbar">
        <div className="container">
          <div className="row">
            <div className="col">
              <nav className="navbar navbar-light bg-white p-0">
                <a style={{ cursor: 'pointer' }} onClick={() => setIsOpenWarningModal(true)}>
                  <img className="mt-2 mb-2" src={logo} width="194" height="60" alt="logo" />
                </a>
              </nav>
            </div>
          </div>
        </div>
      </nav>
      <WarningIfExitFromEnrollmentModal isOpen={isOpenWarningModal} onClose={() => setIsOpenWarningModal(false)} />
    </div>
  );
};
