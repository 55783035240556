import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const TaxInformationDescription: FC = () => {
  const { t } = useTranslation();
  return useObserver(() => (
    <div>
      <p>{t('taxInformationDescription')}</p>
      <p>{t('taxInformationDescription2')}</p>
      <p>{t('taxInformationDescription3')}</p>
    </div>
  ));
};
