import React, { FC } from 'react';
import { getSnapshot } from 'mobx-state-tree';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { TaxIdContext } from '../TaxIdContext';
import { AddressRow } from '../../../../address/main-address/module-form/AddressRow';
import { ComponentField } from '../../../../../infrastructure/dynamic-forms/ComponentField';
import { YlInput } from '../../../../../infrastructure/forms/YlInput';
import { States } from '../../../../../reference/components/States';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';
import { useTranslation } from 'react-i18next';

interface Props {
  context: TaxIdContext;
}

export const BusinessAddressEntry: FC<Props> = ({ context }) => {
  const [t] = useTranslation();
  const businessEUTax = context.selectedEntry!.business!.businessEUTax;

  const addressEntry = useModuleStore('addressEntry');
  businessEUTax.initBusinessAddress(getSnapshot(addressEntry.data));

  function getCustomerAttributes(field: ComponentField) {
    const attr = {} as any;
    if (field.maxLength) {
      attr.maxLength = field.maxLength;
    }

    if (field.minLength) {
      attr.minLength = field.minLength;
    }

    return attr;
  }

  return useObserver(() => {
    return (
      <Form>
        {
          <>
            <p style={{ color: 'blue' }}>
              {t('taxBusinessTaxAddressInfo')}
              <span>
                {' ('}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary"
                  href="https://ec.europa.eu/taxation_customs/vies/#/vat-validation"
                >
                  {t('taxBusinessTaxAddressInfoViesLink')}
                </a>
              </span>
              {')'}
            </p>
            {addressEntry.form.rows.map((row: AddressRow, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <Form.Row key={index}>
                {row.formFields.map((field: ComponentField) => (
                  <React.Fragment key={field.name}>
                    {field.fieldType === 'Input' && (
                      <YlInput
                        required={field.required}
                        size={field.size}
                        field={addressEntry.reactForm.$[field.name]}
                        customAttributes={getCustomerAttributes(field)}
                        label={field.labelKey}
                        id={field.name}
                        onChange={value => {
                          businessEUTax.businessAdressChange(field.name, `${value}`);
                        }}
                      />
                    )}

                    {field.fieldType === 'Select' && field.name === 'stateProv' && (
                      <States
                        required={field.required}
                        countryIso={addressEntry.reactForm.$.country.value}
                        field={addressEntry.reactForm.$[field.name]}
                        size={field.size}
                        label={field.labelKey}
                        onChange={value => {
                          businessEUTax.businessAdressChange(field.name, `${value}`);
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Form.Row>
            ))}
          </>
        }
      </Form>
    );
  });
};
