import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Benefits } from '../../common-er/Benefits';
import { ProductKits } from '../ProductKits';

export const ClassicKitSelection = observer(function KitSelection() {
  const { t } = useTranslation();
  return (
    <div className="mt-3">
      <h2 className="mb-3 text-primary">
        {t('Step2')}: {t('AutomaticMonthlyDelivery')} <span> ({t('Optional')})</span>
      </h2>

      <Benefits />
      <ProductKits />
    </div>
  );
});
