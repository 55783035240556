import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const PromoVoucherModal: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return useObserver(() => {
    if (!isOpen) {
      return <></>;
    }

    return (
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">{t('ErrorDialog_DefaultTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('VoucherNotFound')}</Modal.Body>
        <Modal.Footer className="border-0">
          <Button onClick={() => onClose()}>{t('ErrorDialog_AcceptButtonLabel')}</Button>
        </Modal.Footer>
      </Modal>
    );
  });
};
