/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */
import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import './ProductInfoModal.scss';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import { FaSearchPlus } from 'react-icons/fa';
import { TabsInformationSelector } from './TabsInformationSelector';
import { ProductInfoStore } from '../model/ProductInfoStore';
import { getDecimalPrice } from '../../../reference/Currency';
import { ProductInfoErKitStore } from '../model/ProductInfoErKitStore';
import { useRootStore } from '../../root/RootStoreHooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onKitSelected: () => void;
  productInfo: ProductInfoStore | ProductInfoErKitStore;
  priceDisplay: ReactNode;
  hideSelectButton?: boolean;
}

export const ProductInfoModal: FC<Props> = observer(function YlModal({
  isOpen,
  onClose,
  onKitSelected,
  productInfo,
  priceDisplay,
  hideSelectButton
}) {
  const { t } = useTranslation();
  const { weightValueForPSKEnabled } = useRootStore().moduleStores;
  if (!isOpen) {
    return <></>;
  }

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className="d-flex ml-2 text-primary font-weight-light justify-content-start">{productInfo.name}</h2>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={isOpen ? 'd-block' : 'd-none'}>
        <div
          className={productInfo.imageExpanded ? 'd-block d-flex justify-content-center text-left' : 'd-none text-left'}
        >
          <Container>
            <Row className="justify-content-center">
              <Col>
                <Image fluid src={productInfo.imageUrl} />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col>
                <Button variant="link" onClick={() => productInfo.closeImage()} className="text-primary ml-5 pl-1">
                  {t('ClickToCloseImage')}
                </Button>
              </Col>
            </Row>
          </Container>
        </div>

        <div className={!productInfo.imageExpanded ? 'd-block text-left' : 'd-none text-left'}>
          <div className="d-flex justify-content-start">
            <Col onClick={() => productInfo.expandImage()}>
              <Image className="modal-tab-image" src={productInfo.imageUrl} />
              <FaSearchPlus className="text-white icon-zoom-modal" />
              <Button className="ml-2 modal-enlarge-link" variant="link">
                {t('ClickImageToEnlarge')}
              </Button>
            </Col>
            <Col className="modal-price-info">
              {priceDisplay}
              <p>
                {t('PV')}: {getDecimalPrice(productInfo.kit.pointValue)}
              </p>
              {weightValueForPSKEnabled && (<p>
                {t('Weight')} {productInfo.weight.toFixed(2)} {t('weightUnit')}
              </p>) }
              <p>
                {t('Item_Number')} {productInfo.partNumber}
              </p>
              {!hideSelectButton && (
                <Button variant="link" onClick={onKitSelected} className="btn btn-primary text-white">
                  {t('SelectThisKit')}
                </Button>
              )}
            </Col>
          </div>
          <div className="ml-3 mr-3 mt-4">
            <TabsInformationSelector product={productInfo} isMobile={productInfo.isMobile} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});
