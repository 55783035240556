import React from 'react';

export function generateYears(yearsInFuture: number) {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < yearsInFuture; i += 1) {
    years.push(currentYear + i);
  }
  return years;
}

interface YearOptionsProps {
  yearsInFuture?: number;
}

export function YearOptions({ yearsInFuture = 20 }: YearOptionsProps) {
  return (
    <>
      {generateYears(yearsInFuture).map(year => {
        return (
          <option key={year} value={year}>
            {year}
          </option>
        );
      })}
    </>
  );
}
