import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Image from 'react-bootstrap/Image';
import styles from './Welcome.module.scss';

interface Props {
  image: string;
  label: string;
}

export const WelcomeCard: FC<Props> = ({ image, label }) => {
  const { t } = useTranslation();

  return useObserver(() => (
    <div className={styles.welcomeSetRow}>
      <Image src={image} alt={image} />
      <p className="ml-3">{t(label)}</p>
    </div>
  ));
};
