import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '../../root/RootStoreHooks';

export const OrderReviewPolicyModal = observer(() => {
  const store = useRootStore().moduleStores.orderReturnPolicyReview!;
  const { t } = useTranslation();

  return (
    <Modal show={store.isModalOpen} onHide={() => store.showModal(false)} backdrop="static" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t('ReviewLinkHeader')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="ml-5" dangerouslySetInnerHTML={{ __html: t('ReviewLinkText') }} />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={() => store.showModal(false)}>
          {t('OK')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
