import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

export const ExceliaAgreementEntry = () => {
  const store = useModuleStore('exceliaAgreementEntry');

  return useObserver(() => {
    return (
      <>
       <object height="600" width="100%" data={store.getFileURL(store.document)}></object>
      </>
    );
  });
};
