import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { AgreementDocumentsDisplay } from '../agreement/AgreementDocumentsDisplay';
import styles from './PrivacyPolicyAgreementEntry.module.scss';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

export const PrivacyPolicyAgreementEntry = () => {
  const privacyPolicyAgreementEntry = useModuleStore('privacyPolicyAgreementEntry');

  return useObserver(() => {
    return (
      <>
        {privacyPolicyAgreementEntry.text && (
          <div
            className={styles.infoPrivacyPolicy}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: privacyPolicyAgreementEntry.text }}
          />
        )}

        {privacyPolicyAgreementEntry.documents && (
          <AgreementDocumentsDisplay documents={privacyPolicyAgreementEntry.documents} />
        )}
      </>
    );
  });
};
