import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { StarterKitTab } from '../../model/StarterKitTab';
import './PremiumKitTab.scss';
import { KitGrid } from '../card/KitGrid';
import { KitSlider } from '../card/KitSlider';
import './../card/KitSlider.scss';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';

interface PremiumKitTabProps {
  tab: StarterKitTab;
}

export const PremiumKitTab = observer(({ tab }: PremiumKitTabProps) => {
  const { t } = useTranslation();
  const store = useModuleStore('enrollmentKitSelection');
  return (
    <div className='premium-kit-tab'>
      <div>
        <p>{t('SelectStarterKitHelpPart1')}</p>
        <p>{t('SelectStarterKitHelpPart2')}</p>
      </div>

      {tab.productKits.length < 4 ? 
      (
        <KitGrid kits={tab.productKits}/>
      ) : 
      (
        <KitSlider kits={tab.productKits} store={store}/>
      )}
    </div>
  );
});
