import React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { StarterKitTabLayout } from './view-styles/StarterKitTabbedLayout';
import { ProductInfoModal } from '../../product-info/util/ProductInfoModal';
import { StarterKitStandardLayout } from './view-styles/StarterKitStandardLayout';
import { KitPriceDisplay } from './util-starter-kit/KitPriceDisplay';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useRootStore } from '../../root/RootStoreHooks';
import { AddProducts } from '../../order-selection/add-products/AddProducts';

export const EnrollmentKitSelection = observer(function StarterKitSelection() {
  const { t } = useTranslation();
  const enrollmentKitStore = useModuleStore('enrollmentKitSelection');
  const rootStore = useRootStore();
  const requiredLabel = !enrollmentKitStore.requireStarterKit ? t('Optional') : t('RequiredForMembership');

  return (
    <>
      <h2 className="text-primary">
        {t('starterKitStep1')}: {t('SelectStarterKit')}{' '}
        {<span> {requiredLabel !== '' ? (requiredLabel) : ''} </span>}
      </h2>
      {t('ChooseYourPskInfo') !== '' && <p>{t('ChooseYourPskInfo')}</p>}
      {t('ChooseYourPskInfo2') !== '' && <p>{t('ChooseYourPskInfo2')}</p>}
      <div>
        {enrollmentKitStore.displayStyle === 'Tabbed' && <StarterKitTabLayout store={enrollmentKitStore} />}
        {enrollmentKitStore.displayStyle === 'Standard' && <StarterKitStandardLayout store={enrollmentKitStore} />}
        {rootStore.moduleStores.socialLinkCreatorEntry && (
          <>
            <p className="card-text">{t('AddMoreProductsQuestion')}</p>
            <Row>
              <Col sm={3}>
                <AddProducts />
              </Col>
            </Row>
          </>
        )}
      </div>
      {enrollmentKitStore.productInfo && (
        <ProductInfoModal
          isOpen={enrollmentKitStore.showProductModal}
          onClose={() => enrollmentKitStore.hideProductInfo()}
          onKitSelected={() => enrollmentKitStore!.choseKit(enrollmentKitStore.productInfo!.kit)}
          productInfo={enrollmentKitStore.productInfo}
          priceDisplay={<KitPriceDisplay kit={enrollmentKitStore.productInfo.kit} />}
        />
      )}
    </>
  );
});
