import React from 'react';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { YlCheckBox } from '../../../infrastructure/forms/YlCheckBox';

export const ConfirmMemberInfoEntry = () => {
  const confirmMemberInfoEntry = useModuleStore('confirmMemberInfoEntry');

  return useObserver(() => {
    const form = confirmMemberInfoEntry.reactForm;
    return (
      <Form noValidate>
        <Form.Row>
          <YlCheckBox field={form.$.confirmed} label="confirmMemberInfo" id="confirmMemberInfo" required={true} />
        </Form.Row>
      </Form>
    );
  });
};