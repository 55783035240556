import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import './SocialLinkCreatorBanner.scss';
import { useModuleStore } from '../../../enrollment-process/module-management/model/ModuleStoreHook';

export const SocialLinkCreatorBanner = () => {
  const enrollmentKitStore = useModuleStore('enrollmentKitSelection');
  enrollmentKitStore.updateRequireStarterKit(false);
  const { t } = useTranslation();

  return useObserver(() => {
    return (
      <div className="navbar social-link-creator-banner">
        <div>{t('SocialLinkCreatorBanner_SocialLinkCreatorModeEnabled')}</div>
      </div>
    );
  });
};
