import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { AddressValidator } from './AddressValidator';
import { AddressEntryChoice } from './models/AddressToVerifyResponse';
import { AddressLines } from '../../order-review/util/AddressLines';

interface AddressChoiceProps {
  addressChoice: AddressEntryChoice;
  store: AddressValidator;
}

export const AddressChoice: FC<AddressChoiceProps> = ({ addressChoice, store }) => {
  return useObserver(() => {
    const address = addressChoice.address;
    const currentSelected = store.selectedAddress === addressChoice;
    return (
      <>
        <Card bg={currentSelected ? 'light' : undefined} onClick={() => store.chooseAddress(addressChoice)}>
          <Card.Body>
            <Row className="align-items-center">
              <Col xs={1}>
                <Form.Check
                  custom
                  type="radio"
                  label=""
                  checked={store.selectedAddress === addressChoice}
                  onChange={() => undefined}
                />
              </Col>
              <Col>
                <p>
                  <AddressLines address={address} />
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  });
};
