import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';

import { YlInput } from '../../../../../infrastructure/forms/YlInput';
import { TaxIdContext } from '../TaxIdContext';
import { MultipleIndividualTaxSelector } from './support/MultipleIndividualTaxSelector';

interface Props {
  context: TaxIdContext;
}

export const IndividualMultipleTaxEntry: FC<Props> = ({ context }) => {
  const [t] = useTranslation();
  return useObserver(() => {
    const individual = (context.selectedEntry && context.selectedEntry.individual) || context.entries[0].individual!;
    const taxForm = individual.reactForm;
    const showTaxSelector = context.entries && context.entries.length > 1;
    return (
      <>
        <p>{t('taxHelpIndividualDescription')}</p>
        <Form>
          {showTaxSelector && (
            <Form.Row className="pl-3">
              <MultipleIndividualTaxSelector context={context} />
            </Form.Row>
          )}

          <YlInput
            field={taxForm.$.taxId}
            required={individual.isRequired}
            label="taxPersonalTaxId"
            helpLabel="taxIdHelpText"
            onChange={() => individual.fixDashes()}
            size={5}
          />
        </Form>
      </>
    );
  });
};
