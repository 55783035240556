import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { KitSelection } from './util-er-kit/KitSelection';
import { EnrollEssentialRewardsQuestion } from './EnrollEssentialRewardsQuestion';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { ErPurchasingReminder } from './ErPurchasingReminder';
import { useRootStore } from '../../root/RootStoreHooks';
import { ERAgreementEntry } from '../../customer-information/er-agreement/ERAgreementEntry';
import { ERAgreementStore } from '../../customer-information/er-agreement/ERAgreementStore';

export const ErKitSelection: FC = observer(() => {
  const { t } = useTranslation();
  const erKitStore = useModuleStore('erKitSelection');
  const root = useRootStore();
  const erKitStores = root.moduleStores.erKitSelection ?? root.moduleStores.socialLinkErKitSelection!;
  return (
    <>
      <div className="mt-3">
        <h2 className="card-title text-primary">
          {t('starterKitsStep2')}: {t('EssentialRewardsEnrollment')}
        </h2>
        <p className="card-text">
          {t('EssentialRewardsSubHeading')}
          <br />
          <a
            href={
              t('EssentialRewards_LearnMoreHereUrl') ||
              'https://www.youngliving.com/en_US/opportunity/essential-rewards'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('EssentialRewardsSubHeadingLearnMoreHere')}
          </a>
        </p>
        <EnrollEssentialRewardsQuestion />
      </div>
      {erKitStore.enrollEssentialRewards && <KitSelection />}
      {erKitStore.erUpsellEnabled && <ErPurchasingReminder store={erKitStore.erUpsell!} />}
      {erKitStores.showErAgreementModel && (
        <ERAgreementEntry
          store={ERAgreementStore.create({
            showmodel: erKitStores.showErAgreementModel,
            isErAgreementDismissible: erKitStores.isErAgreementDismissible || false
          })}
        />
      )}
    </>
  );
});
