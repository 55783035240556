import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { YesNoQuestion } from './yes-no-question/YesNoQuestion';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useRootStore } from '../../root/RootStoreHooks';

export const UsePremiumStarterKitQuestion = observer(() => {
  const { t } = useTranslation();
  const root = useRootStore();
  const store = root.moduleStores.erKitSelection ?? root.moduleStores.socialLinkErKitSelection!;

  return (
    <>
      <YesNoQuestion
        value={store.usePremiumKitAsErOrder}
        onChange={value => store.updateUsePremiumStarterKit(value)}
        whenYes={t('PskAsFirstOrderYes')}
        whenNo={t('PskAsFirstOrderNo')}
        question={t('UsePskAsFirstErOrder')}
      />
    </>
  );
});
