import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SocialLinkProductCard } from './SocialLinkProductCard';
import { KitPriceDisplay } from '../order-selection/common-er/util-er-kit/KitPriceDisplay';
import { ProductInfoModal } from '../product-info/util/ProductInfoModal';
import { AdditionalItemCard } from './AdditionalItemCard';
import { PromoItemCard } from './PromoItemCard';
import { UnavailableItemCard } from './UnavailableItemCard';
import { EnrollmentOrderInfoStore } from './EnrollmentOrderInfoStore';
import { AddProducts } from '../order-selection/add-products/AddProducts';

export interface EnrollmentOrderInfoProps {
  orderInfo: EnrollmentOrderInfoStore;
}

export const EnrollmentOrderInfo: FC<EnrollmentOrderInfoProps> = ({ orderInfo }) => {
  const { t } = useTranslation();

  return useObserver(() => {
    const starterKit = orderInfo.starterKit;
    const additionalItems = orderInfo.additionalItems;
    const unavailableItems = orderInfo.unavailableAdditionalItems.filter((elem, index) => 
      orderInfo.unavailableAdditionalItems.findIndex(obj => obj.legacyId === elem.legacyId) === index);
    const erKit = orderInfo.erKit;
    const erAdditionalItems = orderInfo.erAdditionalItems;
    const displayFirstOrder = starterKit || additionalItems.length > 0 || unavailableItems.length > 0;
    const displaySubscription = erKit || erAdditionalItems.length > 0;
    const isErIncludedInFirstOrder = !(
      orderInfo.usePremiumStarterKitAsErOrder || orderInfo.usePremiumStarterKitAsMeoOrder
    );

    return (
      <>
        {displayFirstOrder && (
          <>
            <h5 className="text-primary my-3 text-center">
              <strong>
                {orderInfo.isEnrollingInEr
                  ? t('EnrollmentOrderInfo_YourFirstErOrder')
                  : t('EnrollmentOrderInfo_YourFirstOrder')}
              </strong>
            </h5>
            <div className="my-3">
              <Row className="justify-content-md-center">
                {starterKit && <SocialLinkProductCard kit={starterKit} />}
                {additionalItems
                  .filter(item => !unavailableItems.some(i => i.legacyId === item.legacyId))
                  .map(item =>
                    item.isPromotional ? <PromoItemCard item={item} /> : <AdditionalItemCard item={item} />
                  )}
              </Row>
              <Row className="justify-content-md-center">
                {unavailableItems.length > 0 && (
                  <>
                    <h6 className="text-secondary my-3 text-center">{t('UnavailableItems')}</h6>
                    <p>{t('UnavailableItemsText')}</p>
                  </>
                )}
                {unavailableItems.map(item => (
                  <UnavailableItemCard item={item} />
                ))}
              </Row>
              {unavailableItems.length > 0 && (
                <Row className="justify-content-md-center">
                  <Col md="auto" className="my-3">
                    <AddProducts />
                  </Col>
                </Row>
              )}
            </div>
          </>
        )}
        {displaySubscription && (
          <>
            <div
              className="my-3"
              dangerouslySetInnerHTML={{ __html: t('EnrollmentOrderInfo_YourMonthlySubscriptionDescription') }}
            />
            <h5 className="text-primary my-3 text-center">
              <div className="mt-3">
                <strong>{t('EnrollmentOrderInfo_YourMonthlySubscription')}</strong>
              </div>
              {isErIncludedInFirstOrder && (
                <div>
                  <strong> ({t('EnrollmentOrderInfo_IncludedInFirstOrder')}) </strong>
                </div>
              )}
            </h5>
            <div className="my-3">
              <Row className="justify-content-md-center">
                {erKit && <SocialLinkProductCard kit={erKit} />}
                {erAdditionalItems.map(item => (
                  <AdditionalItemCard item={item} />
                ))}
              </Row>
            </div>
          </>
        )}
        {orderInfo.productInfo && (
          <ProductInfoModal
            isOpen={orderInfo.showProductModal}
            onClose={() => orderInfo.hideProductInfo()}
            onKitSelected={() => null}
            productInfo={orderInfo.productInfo}
            priceDisplay={<KitPriceDisplay kit={orderInfo.productInfo.kit} />}
            hideSelectButton
          />
        )}
      </>
    );
  });
};
