import { useEffect, FC } from 'react';

import { useRootStore } from '../../../../../root/RootStoreHooks';

export const Cybersource3ds: FC = () => {
  const store = useRootStore().moduleStores.threeDSecureEntry?.cybersource3ds;

  useEffect(() => {
    if (store?.isEnabled) {
      store.initializeAuthentication();
    }
  }, [store, store?.isEnabled]);

  return null;
};
