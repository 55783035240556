import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { YlInput } from '../../../infrastructure/forms/YlInput';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useModuleDisplayMode } from '../../enrollment-process/steps-management/components/step-layouts/accordion/ModuleDisplayMode';

export const EmailEntryDisplay: FC = () => {
  const emailEntry = useModuleStore('emailEntry');
  const { t } = useTranslation();
  return useObserver(() => (
    <div>
      <span>{t('email')}: </span>
      <span className="text-primary">{emailEntry.email}</span>
    </div>
  ));
};

export const EmailEntry = () => {
  const emailEntry = useModuleStore('emailEntry');
  const moduleDisplayMode = useModuleDisplayMode();

  return useObserver(() => {
    const form = emailEntry.reactForm;
    if (moduleDisplayMode) {
      return <EmailEntryDisplay />;
    }
    return (
      <Form noValidate>
        <Form.Row>
          <YlInput
            required
            size={4}
            field={form.$.email}
            customAttributes={{ maxLength: 100 }}
            label="email"
            id="email"
          />

          <YlInput
            required
            size={4}
            customAttributes={{ autoComplete: 'confirm-email' }}
            field={form.$.confirmEmail}
            label="confirmEmail"
            id="confirmEmail"
          />
        </Form.Row>
      </Form>
    );
  });
};
