import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CookieWarningModalStore } from './CookieWarningModalStore';
import { useRootStore } from '../root/RootStoreHooks';

interface Props {
  store: CookieWarningModalStore;
}

export const CookieWarningModal: FC<Props> = ({ store }) => {
  const { t } = useTranslation();
  const rootStore = useRootStore();

  return useObserver(() => (
    <Modal show={store.show} backdrop="static">
      <Modal.Header>
        <Modal.Title className="text-primary">
          <strong>{t('cookieAlertTitle')}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('cookieStatement')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={() => rootStore.redirectToMain()} style={{ textDecoration: 'none' }}>
          {t('no')}
        </Button>
        <Button variant="primary" onClick={store.close}>
          {t('yes')}
        </Button>
      </Modal.Footer>
    </Modal>
  ));
};
