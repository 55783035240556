import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

import { useRootStore } from '../../root/RootStoreHooks';

export const VisitMainsite = () => {
  const rootStore = useRootStore();
  const { t } = useTranslation();
  return useObserver(() => (
    <Button
      block
      onClick={() => rootStore.moduleStores.socialLinkCreatorEntry!.redirectToVO()}
      variant="outline-primary"
    >
      {t('VisitMainsite_BackToVirtualOffice')}
    </Button>
  ));
};
