import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { AddressValidator } from './AddressValidator';
import styles from './AddressValidatorModal.module.scss';
import { AddressChoice } from './AddressChoice';

interface Props {
  store: AddressValidator;
}

export const AddressRecommendationModal: FC<Props> = ({ store }) => {
  const { t } = useTranslation();
  return useObserver(() => {
    const recommendation = store.recommendations;
    if (!store.showModal || recommendation === undefined) {
      return <></>;
    }
    return (
      <Modal className={styles.modal} show={store.showModal} onHide={() => store.hideModal()} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="text-primary">{t('AddressDialog_VerificationHeading')}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>{t('EnteredAddress')}</h6>
          <AddressChoice addressChoice={recommendation.originalAddress} store={store} />

          <h6 className="mt-4">{t('SuggestedValidAddresses')}</h6>
          <div className="my-n1">
            {recommendation.recommendations.map(address => (
              <div className="py-1">
                <AddressChoice key={address.id} addressChoice={address} store={store} />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => store.hideModal()}>
            {t('AddressDialog_Cancel')}
          </Button>
          <Button
            variant="primary"
            disabled={store.selectedAddress === undefined}
            onClick={() => store.selectedAddress && store.hideModal(store.selectedAddress)}
          >
            {t('AddressDialog_Select')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  });
};
