import React, { FC } from 'react';
import { FaRegCheckCircle, FaInfoCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { YlInput } from '../../../../../infrastructure/forms/YlInput';
import { TaxIdContext } from '../TaxIdContext';
import { BusinessAddressEntry } from './BusinessAddressEntry';

interface Props {
  context: TaxIdContext;
}

export const BusinessEUTaxEntry: FC<Props> = ({ context }) => {
  const [t] = useTranslation();

  return useObserver(() => {
    const business = context.selectedEntry!.business!;
    const businessEUTax = business.businessEUTax;
    const form = business.reactForm;

    businessEUTax.setAdditionalValidationRules(business);
    businessEUTax.runTaxIdVerification(business.taxId!);

    function getWarningText() {
      if (!businessEUTax.postalCodeVerified || !businessEUTax.cityVerified) {
        return t('taxBusinessTaxAddressNotMatch');
      }
      const viesAddress = businessEUTax.taxIdVerificationResponse
        ? businessEUTax.taxIdVerificationResponse.address
        : '';
      return businessEUTax.streetVerified
        ? t('taxBusinessTaxInformationVerified')
        : `${t('taxBusinessTaxAddressShouldMatch')} "${viesAddress}"`;
    }

    function isTaxIdAndNameVerified() {
      return !form.$.taxId.hasError && businessEUTax.taxIdVerified && !form.$.businessName.hasError;
    }

    return (
      <>
        <p>{t('taxHelpIndividualDescription')}</p>
        <div className="d-flex justify-content-start align-items-center">
          <YlInput
            field={form.$.taxId}
            required={business.isRequired}
            label="taxBusinessTaxId"
            helpLabel="taxIdHelpText"
            id="taxId"
          />
          {!form.$.taxId.hasError && businessEUTax.taxIdVerified && (
            <div className="mt-3">
              <FaRegCheckCircle size="24" style={{ color: 'green' }} />
            </div>
          )}
        </div>
        {business.showBusinessName && (
          <div className="d-flex justify-content-start align-items-center">
            <YlInput field={form.$.businessName} required={business.requireBusinessName} label="taxBusinessNameOnly" />
            {isTaxIdAndNameVerified() && (
              <div className="mt-3">
                <FaRegCheckCircle size="24" style={{ color: 'green' }} />
              </div>
            )}
          </div>
        )}
        {isTaxIdAndNameVerified() && (
          <div className="mx-3 my-3">
            <BusinessAddressEntry context={context} />
          </div>
        )}
        <div className="d-flex my-2 justify-content-start align-items-center">
          {isTaxIdAndNameVerified() && businessEUTax.fullAddressVerified && (
            <>
              <FaRegCheckCircle size="24" style={{ color: 'green' }} />
              <span className="ml-3" style={{ color: 'green' }}>
                {getWarningText()}
              </span>
            </>
          )}
          {isTaxIdAndNameVerified() && !businessEUTax.fullAddressVerified && (
            <>
              <FaInfoCircle size="24" style={{ color: 'red' }} />
              <span className="ml-3" style={{ color: 'red' }}>
                {getWarningText()}
              </span>
            </>
          )}
        </div>
      </>
    );
  });
};
