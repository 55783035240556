/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';
import { ProductInfoStore } from '../model/ProductInfoStore';
import { ProductInfoErKitStore } from '../model/ProductInfoErKitStore';

interface Props {
  product: ProductInfoStore | ProductInfoErKitStore;
}

export const TabsInformationMobile: FC<Props> = observer(function TabsInformationMobile({ product }) {
  const { t } = useTranslation();

  const getHtml = (text: any) => (text ? text.split('"\r\n\r\n"').toString() : undefined);

  const { shortDescription, howToUse, ingredients } = product;

  return (
    <Tab.Container id="mobile-tabs" defaultActiveKey="description">
      <Row>
        <Col>
          <Nav variant="tabs" className="flex-column">
            {shortDescription && (
              <Nav.Item>
                <Nav.Link eventKey="description">{t('Description')}</Nav.Link>
              </Nav.Item>
            )}
            {howToUse && (
              <Nav.Item>
                <Nav.Link eventKey="usage">{t('Usage')}</Nav.Link>
              </Nav.Item>
            )}
            {ingredients && (
              <Nav.Item>
                <Nav.Link eventKey="ingredients">{t('Ingredients')}</Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tab.Content>
            {shortDescription && (
              <Tab.Pane eventKey="description">
                <div dangerouslySetInnerHTML={{ __html: getHtml(shortDescription) }} />
              </Tab.Pane>
            )}
            {howToUse && (
              <Tab.Pane eventKey="usage">
                <div dangerouslySetInnerHTML={{ __html: getHtml(howToUse) }} />
              </Tab.Pane>
            )}
            {ingredients && (
              <Tab.Pane eventKey="ingredients">
                <div dangerouslySetInnerHTML={{ __html: getHtml(ingredients) }} />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
});
