import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { TaxIdEntryStore } from '../TaxIdEntryStore';

interface Props {
  store: TaxIdEntryStore;
}

export const RadioLessTaxIdEntryDisplay: FC<Props> = ({ store }) => {
  const { t } = useTranslation();

  function getOptionLabel(context: string|undefined) {
    let heading = t('Individual');
    let message = t('Individual');
    switch (context) {
      case 'IndividualOptOut':
        heading = t('IndividualOut');
        message = t('taxHelpIndividualNoTaxIdEntered');
        break;
      case 'IndividualMultiple':
      case 'IndividualMexico':
        break;
      case 'IndividualStandard':
        message = t('taxHelpIndividual');
        break;
    }
    return {
      heading: heading, 
      message: message}
  }

  return useObserver(() => {
    const data = store.getDataToShow();
    const additionalOption = getOptionLabel(data.taxRenderer);

    return (
      <>
        {data.taxId ? 
        (<div>
          <span>{t('yourTaxId')}:</span>
          <span className="text-primary"> {data.taxId}</span>
        </div>):
        (<div>
          <span>{additionalOption.heading}:</span>
          <span className="text-primary"> {additionalOption.message}</span>
        </div>)
        }
        {data.businessName && (
          <div>
            <span>{t('yourBusinessName')}:</span>
            <span className="text-primary"> {data.businessName}</span>
          </div>
        )}
      </>
    );
  });
};
