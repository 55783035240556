import React from 'react';
import { useObserver } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useModuleStore } from '../../../enrollment-process/module-management/model/ModuleStoreHook';
import { EmailPopOver } from '../supports/EmailPopOver';
import { YlCheckBox } from '../../../../infrastructure/forms/YlCheckBox';

export const AllPreferencesEntry = () => {
  const { t } = useTranslation();
  const preferencesEntry = useModuleStore('preferencesEntry');

  return useObserver(() => {
    const form = preferencesEntry.reactForm;
    return (
      <Form noValidate>
        <Form.Row>
          <p>{t('PreferencesDescription')}</p>
        </Form.Row>

        <Form.Row>
          <YlCheckBox field={form.$.receiveEmail} label="emails" id="emails" help={<EmailPopOver />} />
        </Form.Row>
      </Form>
    );
  });
};
