/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import styles from './market.change.warning.module.scss';

interface Props {
  showWarning: boolean;
  hide: () => void;
  agreeAndContinue: (toRoot: boolean, country: string | undefined, language: string | undefined) => void;
  country: string | undefined;
  language: string | undefined;
}

export const MarketChangeModal: FC<Props> = ({ showWarning, hide, agreeAndContinue, country, language }) => {
  const { t } = useTranslation();
  return (
    <>
      {showWarning && (
        <Modal backdrop="static" className={styles.modal} show={showWarning} onHide={() => hide()} size="lg">
          <Modal.Body>
            <p>{t('changeCountryConfirmation')}</p>
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <Button variant="light" onClick={() => hide()} className="mr-auto">
              {t('changeCountryCancel')}
            </Button>

            <Button variant="primary" onClick={() => agreeAndContinue(country === undefined, country, language)}>
              {t('changeCountryContinue')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
