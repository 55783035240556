import { observer } from 'mobx-react-lite';
import Carousel from 'react-bootstrap/Carousel';
import React, { useContext } from 'react';

import '../../../common-er/er-carousel.scss';
import { KitGridItem } from '../../../common-er/util-er-kit/kit-grid/KitGridItem';
import { KitGridItemMobile } from '../../../common-er/util-er-kit/kit-grid/KitGridItemMobile';
import { KitGridItemCustom } from '../../../common-er/util-er-kit/kit-grid/KitGridItemCustom';
import { KitGridItemCustomPsk } from '../../../common-er/util-er-kit/kit-grid/KitGridItemCustomPsk';
import { useRootStore } from '../../../../root/RootStoreHooks';

export const KitGridMobile = observer(function KitGridMobile() {
  const root = useRootStore();
  const store = root.moduleStores.erKitSelection ?? root.moduleStores.socialLinkErKitSelection!;

  return (
    <div className="container mb-4">
      <Carousel interval={2000}>
        {store.canCustomizeErKit && (
          <Carousel.Item key="KitGridItemCustom">
            <KitGridItemMobile>
              <KitGridItemCustom
                customKitSelected={store.customKitSelected}
                selectCustomizeKit={store.selectCustomizeKit}
              />
            </KitGridItemMobile>
          </Carousel.Item>
        )}
        {store.canCustomizeErWithPsk && (
          <Carousel.Item key="KitGridItemCustomPsk">
            <KitGridItemMobile>
              <KitGridItemCustomPsk
                customKitWithPskSelected={store.customKitWithPskSelected}
                selectCustomizeKitWithPsk={store.selectCustomizeKitWithPsk}
              />
            </KitGridItemMobile>
          </Carousel.Item>
        )}
        {store.erKits.map(kit => (
          <Carousel.Item key={kit.itemId}>
            <KitGridItemMobile>
              <KitGridItem
                key={kit.itemId}
                kit={kit}
                showProductInfo={store.showProductInfo}
                choseKit={store.choseKit}
                selectedKit={store.selectedKit}
              />
            </KitGridItemMobile>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
});
