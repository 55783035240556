import { observer } from 'mobx-react-lite';
import React, { useContext, useRef } from 'react';
import { Col } from 'react-bootstrap';
import Slider from 'react-slick';
import { ProductInfoModal } from '../../../../product-info/util/ProductInfoModal';
import { KitGridItemDesktop } from '../../../common-er/util-er-kit/kit-grid/KitGridItemDesktop';
import { KitGridItemCustom } from '../../../common-er/util-er-kit/kit-grid/KitGridItemCustom';
import { KitGridItem } from '../../../common-er/util-er-kit/kit-grid/KitGridItem';
import { KitPriceDisplay } from '../../../common-er/util-er-kit/KitPriceDisplay';
import { KitGridItemCustomPsk } from '../../../common-er/util-er-kit/kit-grid/KitGridItemCustomPsk';
import { useRootStore } from '../../../../root/RootStoreHooks';
import { KitSlider } from '../../../starter-kit-selection/tab-content/card/KitSlider';
import './KitGrid.scss';

export const KitGridDesktop = observer(function KitGrid() {
  const root = useRootStore();
  const store = root.moduleStores.erKitSelection ?? root.moduleStores.socialLinkErKitSelection!;
  const slidesToShow = 4 -(store.canCustomizeErKit ? 1 : 0) - (store.canCustomizeErWithPsk ? 1 : 0) ;
  const length = store.erKits.length + (store.canCustomizeErKit ? 1 : 0) + (store.canCustomizeErWithPsk ? 1 : 0);
  return (
    <div className="container mb-4">
      <Col md={12}>
        {length <= slidesToShow ? (
          <div className="row">
            <Col md={3}>
                <div className='erkit-custom-container'>
                {store.canCustomizeErKit && (
                  <KitGridItemDesktop key="KitGridItemCustom">
                    <KitGridItemCustom
                      customKitSelected={store.customKitSelected}
                      selectCustomizeKit={store.selectCustomizeKit}
                    />
                  </KitGridItemDesktop>
                )}
                {store.canCustomizeErWithPsk && (
                  <KitGridItemDesktop key="KitGridItemCustomPsk">
                    <KitGridItemCustomPsk
                      customKitWithPskSelected={store.customKitWithPskSelected}
                      selectCustomizeKitWithPsk={store.selectCustomizeKitWithPsk}
                    />
                  </KitGridItemDesktop>
                )}
              </div>
            </Col>
            <Col md={8}>
              <div className='erkit-grid-container'>
                {store.erKits.map(kit => (
                  <KitGridItemDesktop key={kit.itemId}>
                    <KitGridItem
                      key={kit.itemId}
                      kit={kit}
                      showProductInfo={store.showProductInfo}
                      choseKit={store.choseKit}
                      selectedKit={store.selectedKit}
                    />
                  </KitGridItemDesktop>
                ))}
              </div>
            </Col>
          </div>
          ) : (
          <div className="row ">
            <Col md={3}>
              <div className='erkit-custom-container-slider'>
                {store.canCustomizeErKit && (
                  <KitGridItemDesktop key="KitGridItemCustom">
                    <KitGridItemCustom
                      customKitSelected={store.customKitSelected}
                      selectCustomizeKit={store.selectCustomizeKit}
                    />
                  </KitGridItemDesktop>
                )}
                {store.canCustomizeErWithPsk && (
                  <KitGridItemDesktop key="KitGridItemCustomPsk">
                    <KitGridItemCustomPsk
                      customKitWithPskSelected={store.customKitWithPskSelected}
                      selectCustomizeKitWithPsk={store.selectCustomizeKitWithPsk}
                    />
                  </KitGridItemDesktop>
                )}
              </div>
              </Col>
              <Col md={8}>
                <div className='erkit-grid-container-slider'>
                  <KitSlider kits={store.erKits} slidesToShow={3} store={store}/>
                </div>
              </Col>
          </div>
          )}
        {store.productInfo && (
          <ProductInfoModal
            isOpen={store.showProductModal}
            onClose={() => store.hideProductInfo()}
            onKitSelected={() => store.choseKit(store.productInfo!.kit)}
            productInfo={store.productInfo}
            priceDisplay={<KitPriceDisplay kit={store.productInfo.kit} />}
          />
        )}
      </Col>
    </div>
  );
});
