import React from 'react';
import { getDecimalPrice } from '../../../../reference/Currency';

interface MoneyDisplayProps {
  price: number;
  code?: string;
}

export const MoneyDisplay = function MoneyDisplay({ price, code }: MoneyDisplayProps) {
  return <>{getDecimalPrice(price, code)}</>;
};
