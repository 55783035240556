import React from 'react';
import { observer } from 'mobx-react-lite';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FaPencilAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import styles from '../order-review.module.scss';
import { AddressLines } from '../util/AddressLines';
import { useRootStore } from '../../root/RootStoreHooks';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

export const PersonalInfoReview = observer(function PersonalInfo() {
  const { t } = useTranslation();

  const rootStore = useRootStore();
  const personalInfo = useModuleStore('personalInfoReview');

  return (
    <Card className={styles.card}>
      <Card.Header className={styles.cardHeader}>{t('PersonalInformation')}</Card.Header>
      <Card.Body>
        {rootStore.enrollmentStatus.storeId == 3 && 
        <Card.Title>
          {personalInfo.lastName} {personalInfo.firstName} 
        </Card.Title>}
        {rootStore.enrollmentStatus.storeId != 3 && 
        <Card.Title>
          {personalInfo.firstName} {personalInfo.lastName}
        </Card.Title>}
        <Card.Text>{personalInfo.email}</Card.Text>
        <Card.Title>{t('primaryPhone')}</Card.Title>
        <Card.Text>{personalInfo.primaryPhone} </Card.Text>
        <Card.Title>{t('PrimaryAddress')}</Card.Title>
        <Card.Text>
          <AddressLines address={personalInfo.primaryAddress} />
        </Card.Text>
        {personalInfo.taxId !== undefined && (
          <>
            <Card.Title>{t('TaxId')}</Card.Title>
            <Card.Text>{personalInfo.taxId} </Card.Text>
          </>
        )}
        <Button variant="link" onClick={() => rootStore.stepsManager.goToStep(personalInfo.editStepNumber)}>
          <FaPencilAlt size={12} />
          {t('EditInformation')}
        </Button>
      </Card.Body>
    </Card>
  );
});
