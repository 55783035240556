import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../../root/RootStoreHooks';
import { AgreementKind } from '../../customer-information/agreement/model/AgreementKind';
import { PrivacyPolicyAgreementEntry } from '../../customer-information/privacy-policy-agreement/PrivacyPolicyAgreementEntry';
import { ExceliaAgreementEntry } from '../../customer-information/excelia-agreement/ExceliaAgreementEntry';

interface AgreementModalProps {
  agreementKind: AgreementKind;
}

export const AgreementModalDisplay: FC<AgreementModalProps> = ({ agreementKind }) => {
  return useObserver(() => {
    switch (agreementKind) {
      case 'PrivacyPolicy':
        return <PrivacyPolicyAgreementEntry />;
      case 'Excelia':
        return <ExceliaAgreementEntry />;
     
      default:
        return <></>;
    }
  });
};

export const AgreementModal: FC<AgreementModalProps> = ({ agreementKind }) => {
  const rootStore = useRootStore();
  const completeEnrollmentStore = rootStore.moduleStores.completeEnrollmentProcessor!;
  const [t] = useTranslation();

  return useObserver(() => (
    <Modal show={completeEnrollmentStore.agreementModal} backdrop="static" size="xl">
      <Modal.Header>
        <Modal.Title>{t('AgreementModalTitle')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <AgreementModalDisplay agreementKind={agreementKind} />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => completeEnrollmentStore.dismissAgreement()}>
          {'Dismiss'}
        </Button>
        <Button variant="primary" onClick={() => completeEnrollmentStore.acceptAgreement(agreementKind)}>
          {t('AgreeAndContinue')}
        </Button>
      </Modal.Footer>
    </Modal>
  ));
};
