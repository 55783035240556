import { useEffect } from 'react';

import { useModuleStore } from '../../../enrollment-process/module-management/model/ModuleStoreHook';

export const VirtualWallet = () => {
  const store = useModuleStore('virtualWallet');

  useEffect(() => {
    if (store.isEnabled) {
      store.warmUp();
    }
  }, [store, store.isEnabled]);

  return null;
};
