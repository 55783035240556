import React, { FC } from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { FaAngleRight, FaAngleDown, FaCheck } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { LayoutModuleGroup } from '../../../model/LayoutModuleGroup';
import { ModuleRenderer } from '../../../../module-management/ModuleRenderer';
import { EnrollmentStep } from '../../../model/EnrollmentStep';
import { useRootStore } from '../../../../../root/RootStoreHooks';
import styles from './Accordion.module.scss';
import { ModuleDisplayModeContext } from './ModuleDisplayMode';

interface Props {
  isOpen: boolean;
  moduleGroupNumber: number;
  moduleGroup: LayoutModuleGroup;
  step: EnrollmentStep;
  refs: any;
}

export const AccordionArrow: FC<{ isOpen: boolean }> = ({ isOpen }) => (isOpen ? <FaAngleDown /> : <FaAngleRight />);

export const AccordionCompletionCheck: FC<{ isCompleted: boolean; className: string }> = ({
  isCompleted,
  className
}) => {
  return isCompleted ? <FaCheck className={className} style={{ color: 'green' }} /> : <></>;
};

export const AccordionItem: FC<Props> = ({ isOpen, moduleGroupNumber, moduleGroup, step, refs }) => {
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const moduleStore = rootStore.moduleStores!;

  return useObserver(() => (
    <Card className={styles.accordionCard} ref={refs?.current[moduleGroupNumber]}>
      <Accordion.Toggle
        onClick={() => step.changeActiveModule(moduleGroupNumber)}
        as={Card.Header}
        eventKey={moduleGroupNumber.toString()}
        className="border-bottom-0 mb-0"
        style={{ cursor: 'pointer' }}
      >
        <h5 className="text-primary d-flex align-items-center mr-3">
          <AccordionArrow isOpen={isOpen} />
          <span className="ml-3">{t(moduleGroup.label!)}</span>
          <AccordionCompletionCheck className="ml-auto" isCompleted={moduleGroup.isCompleted} />
        </h5>
      </Accordion.Toggle>

      {!isOpen && moduleGroup.isCompleted && (
        <ModuleDisplayModeContext.Provider value={moduleGroup.isCompleted}>
          <Card.Body className={`${styles.accordionBody} pt-0 pb-3`}>
            {moduleGroup.modules.map(module =>
              moduleStore.supportsDisplay(module) ? (
                <div className="ml-4 pl-3" key={module}>
                  <ModuleRenderer module={module} />
                </div>
              ) : (
                <React.Fragment key={module} />
              )
            )}
          </Card.Body>
        </ModuleDisplayModeContext.Provider>
      )}

      {isOpen && (
        <Accordion.Collapse eventKey={moduleGroupNumber.toString()}>
          <Card.Body className={styles.accordionBody}>
            {moduleGroup.modules.map(module => (
              <ModuleRenderer key={module} module={module} />
            ))}
            <Button className="mt-4" onClick={() => step.activateNextModuleGroup(refs?.current[moduleGroupNumber + 1])}>
              {t('Continue')}
            </Button>
          </Card.Body>
        </Accordion.Collapse>
      )}
    </Card>
  ));
};
