import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import type { PickupPointModal as ExternalPickupPointModal } from 'yl-pickup-point';

import { PickupPointModalStore } from './PickupPointModalStore';
import { errorModalStore } from '../../external/shared/error-handling/ErrorModalStore';

interface Props {
  store: PickupPointModalStore;
}

export const PickupPointModal = observer<Props>(
  ({ store: { availableShippingMethods, locale, modalOptions, closePickupPointModal } }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const pickupPointModalPromiseRef = useRef<Promise<ExternalPickupPointModal> | undefined>(undefined);

    async function createPickupPointModal() {
      // eslint-disable-next-line import/no-unresolved
      const { PickupPointModal: ExternalPickupPointModal } = await import('yl-pickup-point');
      return new ExternalPickupPointModal({ container: containerRef.current! });
    }

    useEffect(() => {
      async function warmUp() {
        if (availableShippingMethods?.some(x => x.isPickupPoint)) {
          pickupPointModalPromiseRef.current ??= createPickupPointModal();
          const modal = await pickupPointModalPromiseRef.current;
          await modal.warmUp({ availableShippingMethods, locale });
        }
      }
      warmUp();
    }, [availableShippingMethods, locale]);

    useEffect(() => {
      async function open() {
        if (modalOptions) {
          try {
            pickupPointModalPromiseRef.current ??= createPickupPointModal();
            const modal = await pickupPointModalPromiseRef.current;
            const result = await modal.open(modalOptions);
            closePickupPointModal(result);
          } catch (error) {
            closePickupPointModal(undefined);
            errorModalStore.showError();
            throw error;
          }
        }
      }
      open();
    }, [modalOptions, closePickupPointModal]);

    useEffect(() => {
      async function dispose() {
        if (pickupPointModalPromiseRef.current) {
          const modal = await pickupPointModalPromiseRef.current;
          modal.dispose();
        }
      }
      return () => {
        dispose();
      };
    }, []);

    return <div ref={containerRef} />;
  }
);
