import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaPencilAlt } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';

import styles from '../order-review.module.scss';
import { AddressLines } from '../util/AddressLines';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useRootStore } from '../../root/RootStoreHooks';
import { getPaymentTypeLabelName, isEnrollmentPaymentType } from '../../billing/model/EnrollmentPaymentTypes';

export const ShippingAndBillingReview = observer(() => {
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const store = useModuleStore('shippingAndBillingReview');
  const paymentReview = useModuleStore('appliedPaymentsReview');

  return (
    <Card className={styles.card}>
      <Card.Header className={styles.cardHeader}>{t('ShippingAndBilling')}</Card.Header>
      <Card.Body>
        <Card.Title>{t('ShippingMethod')}</Card.Title>
        <p>{store.shippingMethod}</p>
        <Card.Title>{t('PaymentMethod')}</Card.Title>
        <p>
          {isEnrollmentPaymentType(paymentReview.paymentDescription)
            ? t(getPaymentTypeLabelName(paymentReview.paymentDescription))
            : paymentReview.paymentDescription}
        </p>
        <Card.Title>{t('ShippingAddress')}</Card.Title>
        <Card.Text>
          <AddressLines address={store.shippingAddress} />
          <span>{rootStore.moduleStores.shippingAndBillingReview?.userShippingAddress?.phoneNumber}</span>
        </Card.Text>
        <Button variant="link" onClick={() => rootStore.stepsManager.goToStep(store.editStepNumber)}>
          <FaPencilAlt size={12} />
          {t('EditShippingAndBilling')}
        </Button>
      </Card.Body>
    </Card>
  );
});
