import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { ProductKit } from '../../model/ProductKit';
import { StarterKitTab } from '../../model/StarterKitTab';
import { KitPriceDisplay } from '../../util-starter-kit/KitPriceDisplay';
import { StarterKitCardHeader } from '../card/StarterKitCardHeader';
import { checkedBorderColor } from '../../tab-selection/util-tab/CheckBorderColor';

import '../tabbed-layout/PremiumKitTab.scss';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';

interface PremiumKitTabProps {
  tab: StarterKitTab;
}

interface KitGridItemProps {
  kit: ProductKit;
}

export const KitGridItem = observer(function KitGridItem({ kit }: KitGridItemProps) {
  const store = useModuleStore('enrollmentKitSelection');
  const { t } = useTranslation();
  return (
    <div className="mt-2" onClick={() => store.choseKit(kit)}>
      <div className={checkedBorderColor(store.selectedKit, kit)}>
        <div className="card-body">
          <span>
            <Button variant="link">
              <img alt="kit" className="url-kit-premium-image" src={kit.smallImageUrl} />
            </Button>
          </span>
        </div>
        <StarterKitCardHeader kit={kit} />
      </div>
      <div className="d-flex justify-content-between mt-1" style={{ fontSize: '14px' }}>
        <KitPriceDisplay kit={kit} />
        <Button variant="link" className="card-link starter-kit- product-link">
          <u>{t('Details')}</u>
        </Button>
      </div>
    </div>
  );
});

export interface KitGridProps {
  kits: ProductKit[];
}

export const KitGrid = observer(function KitGrid({ kits }: KitGridProps) {
  return (
    <>
      {kits.map(kit => (
        <KitGridItem key={kit.itemId} kit={kit} />
      ))}
    </>
  );
});

export const PremiumKitStandardTab = observer(({ tab }: PremiumKitTabProps) => {
  return (
    <div className="mt-4 mb-4">
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <KitGrid kits={tab.productKits} />
          </div>
        </div>
      </div>
    </div>
  );
});
