import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { YlInput } from '../../../../../infrastructure/forms/YlInput';
import { TaxIdContext } from '../TaxIdContext';
import { GstAgreementEntry } from '../../../gst-agreement/GstAgreementEntry';
import { useRootStore } from '../../../../root/RootStoreHooks';
import { BusinessTaxRules } from './BusinessTaxRules';

interface Props {
  context: TaxIdContext;
}

export const BusinessStandardTaxEntry: FC<Props> = ({ context }) => {
  const [t] = useTranslation();
  const rootStore = useRootStore();
  const gstAgreement = rootStore.moduleStores.gstAgreementEntry!;
  return useObserver(() => {
    const business = context.entries[0].business!;
    const form = business.reactForm;
    return (
      <>
        <p>{t('taxHelpIndividualDescription')}</p>
        <BusinessTaxRules />
        {business.canCollect && (
          <>
            <YlInput
              field={form.$.taxId}
              required={business.isRequired}
              label="taxBusinessTaxId"
              helpLabel="taxIdHelpText"
              onChange={() => business.fixDashes()}
              id="taxId"
            />
            {business.showBusinessLicenseId && (
              <YlInput
                field={form.$.businessLicenseId}
                id="businessLicense"
                required={business.requireBusinessLicense}
                label="businessLicenseId"
              />
            )}
            {business.showBusinessName && (
              <YlInput
                field={form.$.businessName}
                id="businessName"
                required={business.requireBusinessName}
                label="taxBusinessName"
              />
            )}

            {gstAgreement && <GstAgreementEntry /> }
          </>
        )}
      </>
    );
  });
};
