import React, { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../root/RootStoreHooks';

interface Props {
  isOpen: boolean | undefined;
  onClose: () => void;
}

export const WarningIfExitFromEnrollmentModal: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const rootStore = useRootStore();

  return (
    <Modal show={isOpen} onHide={() => onClose()}>
      <Modal.Header closeButton onClick={() => onClose()}>
        <Modal.Title>{t('EnrollmentExitWarning_Title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('EnrollmentExitWarning_Body')}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={() => rootStore.redirectToMain()}>
          {t('EnrollmentExitWarning_LeaveAnyway')}
        </Button>
        <Button variant="primary" onClick={() => onClose()}>
          {t('EnrollmentExitWarning_Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
