import React, { FC, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styles from './Footer.module.scss';
import { EnrollmentRootStore } from '../../root/EnrollmentRootStore';
import { enrollmentResource } from '../../../infrastructure/http/EnrollmentResource';

interface Props {
  store?: EnrollmentRootStore;
  locale: string;
}

interface FooterLinkProps {
  links: {
    url: string;
    text: string;
  }[];
}

const FooterLinks: FC<FooterLinkProps> = ({ links }) => {
  return (
    <Col md={3} className="d-flex flex-column">
      {links.map(link => (
        <a key={link.url} className={styles.footerLink} href={link.url}>
          {link.text}
        </a>
      ))}
    </Col>
  );
};

interface FooterLink {
  text: string;
  url: string;
  imageUrl?: string;
  title: string;
  icon: string;
}

interface FooterContactInfo {
  title: string;
  address: string;
  contactOptions: FooterLink[];
}

interface FooterViewModel {
  topLinks: FooterLink[];
  bottomLinks: FooterLink[];
  mainLinks: FooterLink[];
  socialLinks: FooterLink[];
  partnerLinks: FooterLink[];
  contactInfo: FooterContactInfo;
  copyright: string;
}

function splitLinksInHalf(links: FooterLink[]): FooterLink[][] {
  const halfWayThough = Math.ceil(links.length / 2);
  return [links.slice(0, halfWayThough), links.slice(halfWayThough, links.length)];
}

export const Footer: FC<Props> = ({ locale }) => {
  const [footer, setFooter] = useState<FooterViewModel | undefined>(undefined);
  useEffect(() => {
    const getFooterData = async () => {
      const response = await enrollmentResource.get<FooterViewModel>(`/cmsfooter/${locale}`);
      if (Object.keys(response.data).length !== 0) {
        setFooter(response.data);
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    getFooterData();
  }, [locale]);

  if (!footer) {
    return <></>;
  }
  const [firstMainLinks, secondMainLinks] = splitLinksInHalf(footer.mainLinks);
  const contactInfoStyles = `mb-3 ${styles.contactInfoFooter}`;
  return (
    <>
      <Container fluid className={styles.footerMain}>
        <Container>
          <Row as="section">
            {footer.topLinks.map(link => (
              <Col key={link.url}>
                <a href={link.url}>{link.text}</a>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
      <Container>
        <Row as="section" className={styles.footerInfo}>
          <FooterLinks links={firstMainLinks} />
          <FooterLinks links={secondMainLinks} />
          <Col>
            <Row className="justify-content-end">
              <Col md={6}>
                <p className="mb-3">{footer.contactInfo.title}</p>
                {/* eslint-disable react/no-danger */}
                <div className={contactInfoStyles} dangerouslySetInnerHTML={{ __html: footer.contactInfo.address }} />
                {footer.contactInfo.contactOptions.map(link => (
                  <div className={contactInfoStyles} key={link.url}>
                    <div>
                      {link.title} <a href={link.url}>{link.text}</a>
                    </div>
                  </div>
                ))}
                {footer.partnerLinks.map(link => (
                  <a href={link.url} key={link.url}>
                    {link.imageUrl && <img src={link.imageUrl} width="94" height="84" alt="" />}
                    {link.text}
                  </a>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container as="section" fluid className={styles.footerBottom}>
        <Container className="container">
          <Row className="text-black my-2 justify-content-between">
            <Col>
              {footer.copyright}
              {footer.bottomLinks.map(link => (
                <span key={link.url}>
                  {' '}
                  | <a href={link.url}>{link.text}</a>
                </span>
              ))}
            </Col>

            <Col md="auto">
              {footer.socialLinks.map(link => (
                <a
                  className={`social-icon ${link.icon}`}
                  href={link.url}
                  title={link.title}
                  target="_blank"
                  rel="noreferrer noopener"
                  key={link.url}
                >
                  <i className={`fab fa-${link.icon} ml-2 text-dark icon-footer`} />
                </a>
              ))}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
