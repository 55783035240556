import React, { FC, useState } from 'react';
import { Image, ImageProps } from 'react-bootstrap';

import missingProductImage from './images/default-product-image.jpg';
import promoImage from './images/gift-corner.png';

interface Props extends ImageProps, React.RefAttributes<HTMLImageElement> {
  src: string;
  isPromotional?: boolean;
}

export const ImageWithMissingProductFallback: FC<Props> = ({ src, isPromotional, ...otherProps }) => {
  const [image, setImage] = useState(src);

  function useFallback() {
    setImage(missingProductImage);
  }

  return (
    <div>
      {isPromotional && <img alt="promo" className="position-absolute" style={{ width: '40%' }} src={promoImage} />}
      <Image thumbnail fluid {...otherProps} src={image} onError={useFallback} />
    </div>
  );
};
