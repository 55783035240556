import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { KitGridSelector } from './util-er-kit/kit-grid/KitGridSelector';
import { useRootStore } from '../../root/RootStoreHooks';

export const ProductKits = observer(function ProductKits() {
  const { t } = useTranslation();
  const { isMobileSized } = useRootStore();

  return (
    <>
      <h4>{t('SelectEssentialRewardsStarterKit')}</h4>
      <KitGridSelector isMobile={isMobileSized} />
    </>
  );
});
