import { useRootStore } from '../../../root/RootStoreHooks';
import { ModuleKey, ModulesStore } from './ModuleStore';


export function useModuleStore<T extends ModuleKey>(storeKey: T) {
  const root = useRootStore();
  const moduleStore = root.moduleStores!;
  const store = moduleStore[storeKey];
  if (store === undefined) {
    throw Error(`Cannot find ${storeKey as string} in the current module store'`);
  }
  return store as Exclude<ModulesStore[T], undefined>;
}
