import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Col, Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { YlInput } from '../../../../infrastructure/forms/YlInput';
import { YlCheckBox } from '../../../../infrastructure/forms/YlCheckBox';
import { BillingAddressEntry } from '../credit-card/billing-address/BillingAddressEntry';
import { useRootStore } from '../../../root/RootStoreHooks';

export const SepaEditor = observer(() => {
  const rootStore = useRootStore();
  const { t } = useTranslation();
  const billing = rootStore.moduleStores.billingEntry!;
  const sepa = billing.sepaData;
  const sepaForm = sepa.reactForm;
  return (
    <Form className="mb-n3">
      <Form.Row>
        <YlInput
          id="firstNameOnAccount"
          required
          field={sepaForm.$.firstNameOnAccount}
          label="SepaFirstName"
          disabled={sepa.displayOnly}
        />
      </Form.Row>
      <Form.Row>
        <YlInput
          required
          field={sepaForm.$.lastNameOnAccount}
          label="SepaLastName"
          id="lastNameOnAccount"
          disabled={sepa.displayOnly}
        />
      </Form.Row>
      <Form.Row>
        <YlInput required field={sepaForm.$.bic} label="SepaBic" id="bic" disabled={sepa.displayOnly} />
      </Form.Row>
      <Form.Row>
        <YlInput required field={sepaForm.$.iban} label="SepaIban" id="Iban" disabled={sepa.displayOnly} />
      </Form.Row>
      {billing.shouldCollectBillingAddress && (
        <Form.Row>
          <Col xs>
            <BillingAddressEntry disabled={sepa.displayOnly} />
          </Col>
        </Form.Row>
      )}
      {sepa.displayOnly && (
        <Form.Row>
          <FormGroup>
            <Button onClick={() => billing.editSepa()} variant="outline-primary">
              {t('Edit')}
            </Button>
          </FormGroup>
        </Form.Row>
      )}
      <Form.Row>
        <YlCheckBox
          size={12}
          field={sepaForm.$.agreementAccepted}
          label="SepaAgreementText"
          id="SepaAgreementCheckboxLabel"
          disabled={sepa.displayOnly}
        />
      </Form.Row>
    </Form>
  );
});
