import React from 'react';
import { observer } from 'mobx-react-lite';
import { EnrollmentStep } from '../../../model/EnrollmentStep';
import { LayoutModuleGroup } from '../LayoutModuleGroup';

interface PaymentFailureLayoutProps {
  step: EnrollmentStep;
}

export const PaymentFailureLayout = observer(function PaymentFailureLayout({ step }: PaymentFailureLayoutProps) {
  return (
    <>
      <LayoutModuleGroup modules={step.layoutModuleGroups[0].modules} />
    </>
  );
});
