import React from 'react';
import { observer } from 'mobx-react-lite';
import { useModuleStore } from '../../../enrollment-process/module-management/model/ModuleStoreHook';
import ReCAPTCHA from 'react-google-recaptcha';

export const CaptchaEntry = observer(() => {
  const captchaEntry = useModuleStore('captchaEntry');
  return (
    <ReCAPTCHA
      sitekey={captchaEntry.publicKey}
      className="recaptcha"
      onChange={token => captchaEntry.setToken(token)}
    />
  );
});
