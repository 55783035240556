import React from 'react';
import { observer } from 'mobx-react-lite';
import { EnrollmentStep } from '../../../model/EnrollmentStep';
import { LayoutModuleGroup } from '../LayoutModuleGroup';

interface StandardStepLayoutProps {
  step: EnrollmentStep;
}
export const StandardStepLayout = observer(function StandardStepLayout({ step }: StandardStepLayoutProps) {
  return <LayoutModuleGroup modules={step.layoutModuleGroups[0].modules} />;
});
