import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

export const ShippingMethodSelectionErrorModal: FC = () => {
  const shippingSelection = useModuleStore('shippingMethodSelection');
  const [t] = useTranslation();

  return useObserver(() => (
    <Modal show={shippingSelection.showNoItemsModal()} onHide={() => shippingSelection.goToStart()} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t('ErrorDialog_DefaultTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('NoShippingItems')}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => shippingSelection.goToStart()}>{t('OK')}</Button>
      </Modal.Footer>
    </Modal>
  ));
};
