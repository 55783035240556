import React from 'react';
import { useObserver } from 'mobx-react-lite';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import styles from '../order-review.module.scss';
import { MoneyDisplay } from '../../order-selection/starter-kit-selection/util-starter-kit/MoneyDisplay';
import { useRootStore } from '../../root/RootStoreHooks';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { getPaymentTypeLabelName, isEnrollmentPaymentType } from '../../billing/model/EnrollmentPaymentTypes';

export const AppliedPaymentsReview = () => {
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const paymentReview = useModuleStore('appliedPaymentsReview');

  return useObserver(() => {
    return (
      <>
        <div className="mt-3 p-3 bg-light">
          <Card.Title className={styles.titleBorder}>{t('PaymentsApplied')}</Card.Title>
          <Card.Text>
            <div className="d-flex justify-content-between">
              <span>
                {isEnrollmentPaymentType(paymentReview.paymentDescription)
                  ? t(getPaymentTypeLabelName(paymentReview.paymentDescription))
                  : paymentReview.paymentDescription}
              </span>
              <span>
                <MoneyDisplay price={paymentReview.amount} currency={rootStore.reference.currencies[0]} />
              </span>
            </div>
            {paymentReview.installment && paymentReview.installment.installmentNumber > 1 && (
              <span>
                {t('Installments')} {paymentReview.installment.installmentNumber}
              </span>
            )}
          </Card.Text>
          {paymentReview.voucherAmount && (
            <Card.Text className="d-flex justify-content-between">
              <span>{t('Voucher')}</span>
              <span>
                <MoneyDisplay price={paymentReview.voucherAmount} currency={rootStore.reference.currencies[0]} />
              </span>
            </Card.Text>
          )}
        </div>

        {rootStore.moduleStores.threeDSecureEntry?.config.cybersource3dsEnabled && (
          <div className="mt-3 p-3 bg-light">
            <img alt={t('CCAltImage')} className="cc" src={t('CCVisaLogo')} style={{ marginTop: '5' }} />
          </div>
        )}
      </>
    );
  });
};
