import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { ProductKit } from '../../model/ProductKit';
import { useModuleStore } from '../../../../enrollment-process/module-management/model/ModuleStoreHook';
import './KitSlider.scss';
import { getDecimalPrice } from '../../../../../reference/Currency';
import { EssentialRewardKit } from '../../../er-kit-selection/model/EssentialRewardKit';

interface CardHeaderProps {
  isSelected: boolean;
  onClick: () => void;
  alwaysPrimary?: boolean;
  children: ReactNode;
  showCheckbox?: boolean;
}

export const CardHeader: FC<CardHeaderProps> = observer(function CardHeader({
  isSelected,
  onClick,
  alwaysPrimary = false,
  children,
  showCheckbox = true
}) {
  return (
    <div onClick={onClick} className={`text-left ${isSelected || alwaysPrimary ? 'bg-primary' : 'bg-secondary'} slider-card-header`}>
      <div className="container-checkbox kit-grid-item mt-2 ml-2 mb-2">
        {showCheckbox && <input type="checkbox" className="mt-2 mr-2" readOnly checked={isSelected} />}
        <span className="checkmark" />
        {children}
      </div>
    </div>
  );
});

interface StarterKitCardHeaderProps {
  kit: ProductKit;
  showCheckbox?: boolean;
  store: any;
}

export const StarterKitPriceCardHeader: FC<StarterKitCardHeaderProps> = observer(function StarterKitPriceCardHeader({ kit, store }) {
  const checked = store.selectedKit === kit;
  const formattedPrice = getDecimalPrice(kit.price, kit.currency.code);
  const priceToUse = `${kit.currency.symbol}${formattedPrice} ${kit.currency.code}`;

  return (
    <CardHeader onClick={() => store.choseKit(kit)} isSelected={checked}>
      <span className="text-right text-white pr-2">{priceToUse}</span>
    </CardHeader>
  );
});
